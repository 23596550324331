import { RunnerTrait } from '@chain-runners/types'
import { Box, BoxProps } from '@chakra-ui/react'
import React, { Dispatch, SetStateAction } from 'react'
import { HiddenTraits } from './hub/types'
import { TraitListItem } from './TraitListItem'

export type TraitListProps = {
  title: string
  traits: Array<RunnerTrait>
  containerProps?: Partial<BoxProps>
  hiddenTraits?: HiddenTraits
  setHiddenTraits?: Dispatch<SetStateAction<HiddenTraits>>
}

export const TraitList: React.FC<TraitListProps> = ({
  title,
  traits,
  containerProps,
  hiddenTraits = {},
  setHiddenTraits,
}) => {
  return (
    <Box {...containerProps}>
      <Box fontSize="14px" fontWeight="semibold" color="white">
        {title}
      </Box>
      {traits.map((trait) => {
        return (
          <TraitListItem
            key={trait.id}
            trait={trait}
            containerProps={{
              mt: 3,
            }}
            isHidden={hiddenTraits[trait.type]}
            onToggleVisibility={
              setHiddenTraits
                ? () => {
                    setHiddenTraits((hiddenTraits) => {
                      return {
                        ...hiddenTraits,
                        [trait.type]: !hiddenTraits[trait.type],
                      }
                    })
                  }
                : undefined
            }
          />
        )
      })}
    </Box>
  )
}
