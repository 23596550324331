import { RunnerTrait } from '@chain-runners/types'
import { alphaColor, HackerTooltip, usePrimaryColor } from '@chain-runners/ui'
import { formatTraitTypeLong } from '@chain-runners/utils'
import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import { groupBy, orderBy, sumBy } from 'lodash-es'
import React, { useCallback, useMemo, useRef } from 'react'
import { BiCheckbox, BiCheckboxSquare } from 'react-icons/bi'
import { TraitType } from 'src/clients/api/generated'

export type TraitFilterGroupProps = {
  traitType: TraitType
  traits: Array<RunnerTrait>
  defaultOpen?: boolean
  selectedTraits?: Array<string>
  setSelectedTraits: (selectedTraits?: Array<string>) => void
}

export const TraitFilterGroup: React.FC<TraitFilterGroupProps> = ({
  traitType,
  traits,
  selectedTraits,
  setSelectedTraits,
  defaultOpen,
}) => {
  const { primaryColor } = usePrimaryColor()

  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: defaultOpen })
  const {
    isOpen: isExpanded,
    onOpen: onExpand,
    onClose: onCollapse,
  } = useDisclosure({ defaultIsOpen: isOpen })

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const handleOpen = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current)
    onOpen()
    onExpand()
  }, [onOpen, onExpand])

  const handleClose = useCallback(() => {
    onClose()
    timeoutRef.current = setTimeout(onCollapse, 300)
  }, [onClose, onCollapse])

  const sortedGroups: Array<{ displayName: string; tokenCount: number }> = useMemo(() => {
    return orderBy(
      Object.entries(groupBy(traits, (t) => t.displayName)).map(
        ([displayName, traits]) => {
          return {
            displayName,
            tokenCount: sumBy(traits, (t) => t.tokenCount),
          }
        },
      ),
      (t) => t.tokenCount,
      'desc',
    )
  }, [traits])

  const onToggleItem = useCallback(
    (isSelected: boolean, displayName: string) => {
      if (!setSelectedTraits) return

      if (isSelected) {
        setSelectedTraits(selectedTraits?.filter((n) => n !== displayName))
      } else {
        setSelectedTraits([...(selectedTraits ?? []), displayName])
      }
    },
    [setSelectedTraits, selectedTraits],
  )

  return (
    <Box
      key={traitType}
      w="full"
      borderColor={primaryColor}
      borderTopWidth="1px"
      _last={{ borderBottomWidth: '1px' }}
    >
      <Flex
        w="full"
        py={2}
        px={2}
        borderBottomColor={primaryColor}
        borderBottomWidth={isExpanded ? '1px' : 0}
        color={isOpen ? 'black' : primaryColor}
        bgColor={isOpen ? primaryColor : 'black'}
        fontSize={14}
        fontWeight="semibold"
        onClick={isOpen ? handleClose : handleOpen}
        cursor="pointer"
        _active={{
          bgColor: isOpen ? alphaColor(primaryColor, 0.9) : alphaColor(primaryColor, 0.2),
        }}
        userSelect="none"
      >
        <Flex>{formatTraitTypeLong(traitType)}</Flex>
      </Flex>
      <Box h={isOpen ? '230px' : 0} transition="height 0.3s" overflowY="auto">
        {sortedGroups.map((trait) => {
          const isChecked = selectedTraits?.includes(trait.displayName) ?? false
          return (
            <HackerTooltip
              key={trait.displayName}
              label={trait.displayName}
              openDelay={500}
              bgColor="black"
            >
              <Flex
                alignItems="center"
                py={1}
                px={2}
                fontSize="12px"
                cursor="pointer"
                userSelect="none"
                _hover={{
                  bgColor: alphaColor(primaryColor, 0.1),
                }}
                _active={{
                  bgColor: alphaColor(primaryColor, 0.15),
                }}
                onClick={() => onToggleItem(isChecked, trait.displayName)}
              >
                <Flex fontSize="16px" mr={2}>
                  {isChecked ? <BiCheckboxSquare /> : <BiCheckbox />}
                </Flex>
                <Flex
                  flex={1}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  mr={2}
                >
                  {trait.displayName}
                </Flex>
                <Flex>{trait.tokenCount}</Flex>
              </Flex>
            </HackerTooltip>
          )
        })}
      </Box>
    </Box>
  )
}
