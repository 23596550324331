import { RunnerTrait } from '@chain-runners/types'
import { HackerModalSection } from '@chain-runners/ui'
import {
  ACCESSORY_TRAIT_ORDERING,
  BASE_TRAIT_ORDERING,
  isNotNullOrUndefined,
} from '@chain-runners/utils'
import { FlexProps } from '@chakra-ui/react'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { RunnerWithTraitIds } from '../../../../utils/runners'

import { TraitList } from '../../../TraitList'
import { HiddenTraits } from '../../types'

export type RunnerDetailsAttributesSectionProps = {
  token: RunnerWithTraitIds
  hiddenTraits?: HiddenTraits
  setHiddenTraits: Dispatch<SetStateAction<HiddenTraits>>
  containerProps?: Partial<FlexProps>
}

export const RunnerDetailsAttributesSection: React.FC<
  RunnerDetailsAttributesSectionProps
> = ({ token, hiddenTraits, setHiddenTraits, containerProps }) => {
  const { traits } = token

  const baseTraits: Array<RunnerTrait> = useMemo(() => {
    return BASE_TRAIT_ORDERING.map((trait): RunnerTrait | undefined => {
      return traits.find((a) => a.type === trait)
    }).filter(isNotNullOrUndefined)
  }, [traits])

  const accessoryTraits: Array<RunnerTrait> = useMemo(() => {
    return ACCESSORY_TRAIT_ORDERING.map((trait): RunnerTrait | undefined => {
      return traits.find((a) => a.type === trait)
    }).filter(isNotNullOrUndefined)
  }, [traits])

  return (
    <HackerModalSection title="ATTRIBUTES" containerProps={containerProps}>
      <TraitList title="Base" traits={baseTraits} />
      <TraitList
        hiddenTraits={hiddenTraits}
        setHiddenTraits={setHiddenTraits}
        title="Accessories"
        traits={accessoryTraits}
        containerProps={{
          mt: 8,
          pb: 3,
        }}
      />
    </HackerModalSection>
  )
}
