import { Box, Flex, FlexProps, Image, MenuItem, Stack, StackItem } from '@chakra-ui/react'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useWallet } from '../../hooks/useWallet'
import { ConnectWalletButton } from '../ConnectWalletButton'
import { LANDING_PAGE_SECTIONS } from './constants'
import { LandingPageSection } from './types'
import { getSectionURL } from './utils'

export type LandingPageHeaderProps = {
  activeSection: LandingPageSection
  mountPath: string
  containerProps?: Partial<FlexProps>
}

export const LandingPageHeader: React.FC<LandingPageHeaderProps> = ({
  mountPath,
  activeSection,
  containerProps,
}) => {
  const { address } = useWallet()
  const navigate = useNavigate()

  return (
    <Flex
      px={10}
      py={5}
      alignItems="center"
      justifyContent="space-between"
      zIndex={20}
      {...containerProps}
    >
      <Link to="/landing">
        <Box _hover={{ opacity: 0.8 }}>
          <Image w="130px" src="/static/chain-runners-vector-logo.svg" />
        </Box>
      </Link>

      <Stack direction="row" spacing={8}>
        {LANDING_PAGE_SECTIONS.map((landingPageSection) => {
          const sectionURL = getSectionURL(mountPath, landingPageSection)
          const isActive = activeSection === landingPageSection

          return (
            <Link key={landingPageSection.name} to={sectionURL}>
              <StackItem
                textTransform="uppercase"
                fontWeight="700"
                backgroundColor={
                  isActive
                    ? activeSection.isLight
                      ? 'blackAlpha.700'
                      : 'whiteAlpha.400'
                    : undefined
                }
                px={3}
                py={1}
                borderRadius="4px"
                fontSize="12px"
              >
                {landingPageSection.name}
              </StackItem>
            </Link>
          )
        })}
      </Stack>

      <Box>
        <Box p="3px" bgColor="blackAlpha.700" borderRadius="6px">
          <ConnectWalletButton
            buttonProps={{
              borderRadius: '4px',
              borderColor: 'whiteAlpha.500',
            }}
          >
            <MenuItem onClick={() => navigate('/hub')}>Hub</MenuItem>
            {address && (
              <MenuItem
                onClick={() =>
                  navigate(`/genesis/runners?ownedBy=${address?.toLowerCase()}`)
                }
              >
                My Runners
              </MenuItem>
            )}
            <MenuItem onClick={() => navigate('/account')}>Account</MenuItem>
          </ConnectWalletButton>
        </Box>
      </Box>
    </Flex>
  )
}
