import { Box, BoxProps, Flex } from '@chakra-ui/react'
import React from 'react'
import { useIsMobile } from '@chain-runners/ui'
import { LANDING_PAGE_HEADER_HEIGHT } from './constants'

export type LandingPageDetailsPaneProps = {
  titleContent?: React.ReactNode
  pageTitle: React.ReactNode
  sectionTitle: React.ReactNode
  children: React.ReactNode
  secondaryContent?: React.ReactNode
  titleContainerProps?: Partial<BoxProps>
  containerProps?: Partial<BoxProps>
  secondaryContentContainerProps?: Partial<BoxProps>
  contentContainerProps?: Partial<BoxProps>
}

export const LandingPageDetailsPane = React.forwardRef<
  HTMLDivElement | null,
  LandingPageDetailsPaneProps
>(
  (
    {
      titleContent,
      pageTitle,
      sectionTitle,
      children,
      titleContainerProps,
      contentContainerProps,
      secondaryContent,
      secondaryContentContainerProps,
      containerProps,
    },
    ref,
  ) => {
    const isMobile = useIsMobile()
    return (
      <Flex
        ref={ref}
        flexDir="column"
        flex={1}
        paddingX={4}
        pb={4}
        pt={`calc(16px + ${LANDING_PAGE_HEADER_HEIGHT}px)`}
        minH="100vh"
        justifyContent="center"
        {...containerProps}
      >
        <Flex flexDir={isMobile ? 'column' : 'row'} alignItems="center" minH="70vh">
          <Box zIndex={6}>
            <Box px={3}>
              {titleContent}
              <Box
                className="booty"
                fontSize={['16px']}
                color="hackerGreen"
                letterSpacing="8px"
                textTransform="uppercase"
                mb={['-4px', null, '-4px']}
                pl={1}
              >
                {pageTitle}
              </Box>
              <Box
                className="booty"
                textTransform="uppercase"
                fontSize="calc(min(14vw, 100px))"
                lineHeight="calc(min(11.25vw, 84px))"
                mb={2}
                {...titleContainerProps}
              >
                {sectionTitle}
              </Box>
            </Box>

            <Box
              fontSize="16px"
              maxWidth="670px"
              display="inline-block"
              padding={4}
              // bgColor="blackAlpha.600"
              // borderRadius="8px"
              // backdropFilter="blur(4px)"
              {...contentContainerProps}
            >
              {children}
            </Box>
          </Box>
          {secondaryContent && (
            <Flex
              width="full"
              flex={1}
              justifyContent="center"
              h="full"
              minW="300px"
              minH={isMobile ? 0 : '70vh'}
              direction="column"
              alignItems="center"
              maxW="80vw"
              marginX="auto"
              mt={isMobile ? 12 : 0}
              {...secondaryContentContainerProps}
            >
              {secondaryContent}
            </Flex>
          )}
        </Flex>
      </Flex>
    )
  },
)
