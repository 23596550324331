import { ALL_TRAITS } from '@chain-runners/data'
import { RunnerTrait } from '@chain-runners/types'
import { useMemo } from 'react'
import { TraitType } from '../clients/api/generated'

export type TraitsData = {
  traits: Array<RunnerTrait>
  traitsByType: Record<TraitType, Array<RunnerTrait>>
  traitsById: Record<number, RunnerTrait>
}

export type UseTraitsValue = {
  data: TraitsData
}

function getTraitsByType(
  traits: Array<RunnerTrait>,
): Record<TraitType, Array<RunnerTrait>> {
  const traitsByType: Record<TraitType, Array<RunnerTrait>> = {
    Background: [],
    EarAccessory: [],
    EyeAccessory: [],
    Eyes: [],
    Face: [],
    FaceAccessory: [],
    HeadAbove: [],
    HeadBelow: [],
    Mask: [],
    Mouth: [],
    MouthAccessory: [],
    Nose: [],
    Race: [],
  }
  for (const traitType of Object.values(TraitType)) {
    traitsByType[traitType] = traits.filter((t) => t.type === traitType)
  }
  return traitsByType
}

function getTraitsById(traits: Array<RunnerTrait>): Record<number, RunnerTrait> {
  const traitsById: Record<number, RunnerTrait> = {}
  for (const trait of traits) {
    traitsById[trait.id] = trait
  }
  return traitsById
}

export function useTraits(): UseTraitsValue {
  const data: TraitsData = useMemo((): TraitsData => {
    const traits = ALL_TRAITS.filter((t) => t.tokenCount)
    return {
      traits,
      traitsByType: getTraitsByType(traits),
      traitsById: getTraitsById(traits),
    }
  }, [])

  return {
    data,
  }
}
