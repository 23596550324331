import {
  HackerButton,
  HackerModalSection,
  LoadingContainer,
  TextEditor,
} from '@chain-runners/ui'
import { Box, FlexProps, useDisclosure } from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RichTextBlockInput } from '../../../../clients/api/generated'
import { useAuthentication } from '../../../../hooks/useAuthentication'
import { RunnerWithTraitIds } from '../../../../utils/runners'

export type RunnerDetailsBioSectionProps = {
  token: RunnerWithTraitIds
  containerProps?: Partial<FlexProps>
}

export const RunnerDetailsBioSection: React.FC<RunnerDetailsBioSectionProps> = ({
  token,
  containerProps,
}) => {
  const { currentUser, apiClient } = useAuthentication()
  const [isLoading, setIsLoading] = useState(false)

  const [bioData, setBioData] = useState<Array<RichTextBlockInput> | null>(null)
  const currentUserIsOwner = useMemo(() => {
    return Boolean(
      token && token.ownerAddress.toLowerCase() === currentUser?.address.toLowerCase(),
    )
  }, [token, currentUser])
  const {
    isOpen: isEditingLore,
    onClose: onStopEditing,
    onToggle: onEditLoreToggle,
  } = useDisclosure()

  const fetchBio = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await apiClient.getBioForRunner({ runnerId: token.id })
      setBioData(response.bioForRunner?.textContent ?? [])
    } finally {
      setIsLoading(false)
    }
  }, [apiClient, token])

  const handleSaveBioData = useCallback(
    async (newBioData: Array<RichTextBlockInput> | null, didChange: boolean) => {
      if (!didChange) {
        onStopEditing()
        return
      }

      setIsLoading(true)
      setBioData(newBioData)
      try {
        await apiClient.setBioForRunner({
          runnerId: token.id,
          content: newBioData ?? [],
        })
        onStopEditing()
      } finally {
        setIsLoading(false)
      }
    },
    [apiClient, onStopEditing, token.id],
  )

  useEffect(() => {
    if (!isLoading && bioData === null) {
      fetchBio()
    }
  }, [isLoading, fetchBio, bioData])

  return (
    <HackerModalSection
      title="BIO"
      extraTitleContent={
        (currentUserIsOwner && !isEditingLore && (
          <HackerButton
            variant="unstyled"
            size="sm"
            h="21px"
            fontWeight="semibold"
            filled={isEditingLore}
            onClick={onEditLoreToggle}
          >
            <Box px={1}>EDIT</Box>
          </HackerButton>
        )) ||
        undefined
      }
      containerProps={{
        overflowX: 'auto',
        ...containerProps,
      }}
    >
      {bioData === null ? (
        <LoadingContainer loading />
      ) : (
        <TextEditor
          placeholder={
            currentUserIsOwner
              ? 'Add a bio...'
              : `This Runner's story has not yet been written.`
          }
          textBlocks={bioData}
          onSave={handleSaveBioData}
          onCancel={onStopEditing}
          isReadOnly={!isEditingLore}
          isSaving={isLoading}
        />
      )}
    </HackerModalSection>
  )
}
