import { AboutLandingPageHomeSection } from './sections/AboutLandingPageSection'
import { AccountLandingPageHomeSection } from './sections/AccountLandingPageHomeSection'
import { GalleryLandingPageHomeSection } from './sections/GalleryLandingPageSection'
import { HomeLandingPageSection } from './sections/HomeLandingPageSection'
import { HubLandingPageSection } from './sections/HubLandingPageSection'
import { UniverseLandingPageHomeSection } from './sections/UniverseLandingPageSection'
import { XRLandingPageHomeSection } from './sections/XRLandingPageSection'
import { LandingPageSection } from './types'

export const LANDING_PAGE_SECTIONS: Array<LandingPageSection> = [
  {
    name: 'Home',
    path: '',
    component: HomeLandingPageSection,
    isLight: true,
    exactPath: true,
  },
  { name: 'About', path: '/about', component: AboutLandingPageHomeSection },
  {
    name: 'The Universe',
    path: '/the-universe',
    component: UniverseLandingPageHomeSection,
  },
  {
    name: 'Genesis',
    path: '/genesis',
    component: GalleryLandingPageHomeSection,
    hideExternalLinks: true,
  },
  { name: 'XR', path: '/xr', component: XRLandingPageHomeSection },
]

export const LANDING_PAGE_OVERLAYS: Array<LandingPageSection> = [
  {
    name: 'Account',
    path: '/account',
    component: AccountLandingPageHomeSection,
  },
  {
    name: 'Hub',
    path: '/hub',
    component: HubLandingPageSection,
  },
]

export const LANDING_PAGE_HEADER_HEIGHT = 92
