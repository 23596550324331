import { RefObject, useLayoutEffect, useState } from 'react'

export enum ScrollDirection {
  Down = 1,
  Up = -1,
}

export type UseScrollValue = {
  scrollPosition: number
  scrollDirection: ScrollDirection
}

export type UseScrollOptions = {
  containerRef?: RefObject<HTMLElement | null>
}

function isWindow<T>(elem: Window | T): elem is Window {
  return elem === window
}

export function useScroll({ containerRef }: UseScrollOptions = {}): UseScrollValue {
  const [state, setState] = useState<UseScrollValue>({
    scrollDirection: ScrollDirection.Down,
    scrollPosition: 0,
  })

  useLayoutEffect(() => {
    const trackedElement = containerRef?.current ?? window

    const handleScroll = (): void => {
      setState((prevState) => {
        const scrollPosition = isWindow(trackedElement)
          ? trackedElement.scrollY
          : trackedElement.scrollTop

        return {
          scrollPosition: scrollPosition,
          scrollDirection:
            scrollPosition > prevState.scrollPosition
              ? ScrollDirection.Down
              : ScrollDirection.Up,
        }
      })
    }

    trackedElement.addEventListener('scroll', handleScroll)

    return () => {
      trackedElement.removeEventListener('scroll', handleScroll)
    }
  }, [containerRef])

  return state
}
