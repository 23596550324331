import { HackerButton, usePrimaryColor } from '@chain-runners/ui'
import { Box, Flex, Image, Link } from '@chakra-ui/react'
import { isError } from 'lodash-es'
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { RECAPTCHA_SITE_KEY } from '../../../config/base'
import { useAuthentication } from '../../../hooks/useAuthentication'
import { BaseHubApplicationProps } from '../types'

export type EventPOAPClaimProps = BaseHubApplicationProps & {}

const RECAPTCHA_CONTAINER_ID = 'recaptcha-container'

export const EventPOAPClaim: React.FC<EventPOAPClaimProps> = () => {
  const { primaryColor } = usePrimaryColor()
  const [isLoading, setIsLoading] = useState(false)
  const [poapCode, setPOAPCode] = useState<string | null>(null)
  const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const { apiClient } = useAuthentication()

  const initializeCaptcha = useCallback(() => {
    window.grecaptcha.render(RECAPTCHA_CONTAINER_ID, {
      sitekey: RECAPTCHA_SITE_KEY,
      theme: 'dark',
      callback: setRecaptchaResponse,
    })
  }, [])

  const handleClaimPOAP = useCallback(async (): Promise<void> => {
    if (!recaptchaResponse) return
    setIsLoading(true)
    setError(null)
    try {
      const response = await apiClient.claimPOAP({ response: recaptchaResponse })
      setPOAPCode(response?.claimPOAP?.poap?.code ?? null)
      if (response?.claimPOAP?.success === false) {
        const errorMessage = response.claimPOAP.message
        setError(errorMessage)
        if (errorMessage?.startsWith('ReCAPTCHA response could not')) {
          setRecaptchaResponse(null)
          window.grecaptcha.reset()
        }
      }
    } catch (e) {
      if (isError(e)) {
        console.error(e)
      }
      setError('Error Occurred While Claiming POAP')
    } finally {
      setIsLoading(false)
    }
  }, [apiClient, recaptchaResponse])

  const initialized = useRef(false)
  useLayoutEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      initializeCaptcha()
    }
  }, [initializeCaptcha])

  return (
    <Flex p={4} w="full" height="full" overflowY="auto">
      <Box textAlign="center" mx="auto">
        <Box mt={8} fontSize="1.5rem">
          Hackers Trivia Night with Midnite Movie Club
        </Box>
        <Flex my={8} mx="auto" maxW="50%" justifyContent="center" alignItems="center">
          <Image
            borderColor={primaryColor}
            borderWidth="2px"
            borderStyle="solid"
            borderRadius="500px"
            src="/static/poap_no-names.png"
          />
        </Flex>

        {poapCode ? (
          <>
            <Box
              my={5}
              mx="auto"
              maxWidth={['250px', '100%', null]}
              color="whiteAlpha.700"
            >
              Your POAP Claim code is:
            </Box>
            <Box mx="auto" fontSize="2rem" letterSpacing="8px" color="white">
              {poapCode}
            </Box>
            <Link
              href={`https://app.poap.xyz/claim/${poapCode}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box
                my={5}
                mx="auto"
                maxWidth={['250px', '100%', null]}
                color="whiteAlpha.700"
              >
                Click{' '}
                <Box as="span" color={primaryColor}>
                  here
                </Box>{' '}
                to consume your code and claim your POAP
              </Box>
            </Link>
          </>
        ) : (
          <>
            <Box
              my={5}
              mx="auto"
              maxWidth={['250px', '100%', null]}
              color="whiteAlpha.700"
            >
              Click below to get your POAP claim code
            </Box>

            <Flex
              width="full"
              justifyContent="center"
              mb={5}
              hidden={Boolean(recaptchaResponse)}
            >
              <div id={RECAPTCHA_CONTAINER_ID} />
            </Flex>

            {error && (
              <Box my={5} color="hackerRed">
                {error}
              </Box>
            )}
            <HackerButton
              isLoading={isLoading}
              onClick={handleClaimPOAP}
              isDisabled={!recaptchaResponse}
            >
              Claim POAP Code
            </HackerButton>
          </>
        )}

        {/*<Box my={5}>*/}
        {/*  <Link*/}
        {/*    href="https://www.twitch.tv/chain_runners"*/}
        {/*    target="_blank"*/}
        {/*    rel="noopener noreferrer"*/}
        {/*  >*/}
        {/*    <HackerButton mx="auto" variant="ghost">*/}
        {/*      View Twitch Stream*/}
        {/*    </HackerButton>*/}
        {/*  </Link>*/}
        {/*</Box>*/}
        <Box pb={5} />
      </Box>
    </Flex>
  )
}
