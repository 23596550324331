import { ScanLines, usePrimaryColor } from '@chain-runners/ui'
import { Button, ButtonProps, Flex } from '@chakra-ui/react'
import React from 'react'

export type LandingPageButtonProps = ButtonProps

// eslint-disable-next-line react/display-name
export const LandingPageButton: React.FC<LandingPageButtonProps> = React.forwardRef(
  ({ children, ...props }, ref) => {
    const { primaryColor } = usePrimaryColor()
    return (
      <Button
        ref={ref as any}
        variant="outline"
        borderColor={primaryColor}
        color={primaryColor}
        borderRadius="8px"
        borderWidth="2px"
        fontWeight="thin"
        _focus={{ outline: 0 }}
        _hover={{ bgColor: 'rgba(255, 0, 156, 0.1)' }}
        _active={{ bgColor: 'rgba(255, 0, 156, 0.2)' }}
        width="163px"
        padding="4px"
        minH="52px"
        {...props}
      >
        <Flex
          borderColor={primaryColor}
          borderStyle="solid"
          borderWidth="2px"
          width="full"
          height="full"
          borderRadius="4px"
          justifyContent="center"
          alignItems="center"
          position="relative"
          bgColor="black"
        >
          <ScanLines />
          {children}
        </Flex>
      </Button>
    )
  },
)
