import { LoadingContainer, ModalErrorDisplay } from '@chain-runners/ui'
import { getOrThrow } from '@chain-runners/utils'
import { Box } from '@chakra-ui/react'
import '@fontsource/source-serif-pro/400.css'
import { isError } from 'lodash-es'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useAuthentication } from 'src/hooks/useAuthentication'
import { parseRawTokenResponse, RunnerWithTraitIds } from 'src/utils/runners'
import { TokenFilters } from '../../../../clients/api/generated'
import { BaseHubApplicationProps, HiddenTraits } from '../../types'
import { RunnerDetailsAttributesSection } from './RunnerDetailsAttributesSection'
import { RunnerDetailsBioSection } from './RunnerDetailsBioSection'
import { RunnerDetailsOverviewSection } from './RunnerDetailsOverviewSection'

export type RunnerDetailsDisplayProps = BaseHubApplicationProps & {
  onClose?: () => void
  runnerId: number
  setFilters: Dispatch<SetStateAction<TokenFilters>>
}

export const RunnerDetailsDisplay: React.FC<RunnerDetailsDisplayProps> = ({
  onClose,
  setApplicationBreadcrumbs,
  runnerId,
  traitsById,
  setFilters,
}) => {
  const { apiClient } = useAuthentication()
  const [token, setToken] = useState<RunnerWithTraitIds | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const traits = token?.traits ?? null

  const [hiddenTraits, setHiddenTraits] = useState<HiddenTraits>({})

  const fetchRunner = useCallback(
    async (runnerId: number): Promise<void> => {
      setToken(null)
      setIsLoading(true)
      try {
        const response = await apiClient.getRunnerById({
          id: runnerId.toString(),
        })
        const responseToken = getOrThrow(response.token, 'Token not found')
        setToken(parseRawTokenResponse(responseToken, traitsById))
      } catch (e) {
        console.error(e)
        if (isError(e)) {
          setError(e.message)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [apiClient, traitsById],
  )

  useEffect(() => {
    fetchRunner(runnerId)
  }, [runnerId, fetchRunner])

  useEffect(() => {
    setApplicationBreadcrumbs([
      {
        name: `Runner #${runnerId}`,
      },
    ])

    return () => {
      setApplicationBreadcrumbs([])
    }
  }, [runnerId, setApplicationBreadcrumbs])

  return (
    <>
      <LoadingContainer loading={isLoading && !token} minH="300px">
        <ModalErrorDisplay error={error} onClose={onClose} />
        {!error && token && traits && (
          <Box w="full" display={['block', null, null, 'flex']}>
            <Box display={['block', null, 'flex']} flex={5}>
              <RunnerDetailsOverviewSection
                token={token}
                setFilters={setFilters}
                hiddenTraits={hiddenTraits}
                containerProps={{
                  w: ['full', null, '50%'],
                }}
              />
              <RunnerDetailsAttributesSection
                token={token}
                hiddenTraits={hiddenTraits}
                setHiddenTraits={setHiddenTraits}
                containerProps={{
                  w: ['full', null, '50%'],
                }}
              />
            </Box>
            <RunnerDetailsBioSection token={token} containerProps={{ flex: 3 }} />
          </Box>
        )}
      </LoadingContainer>
    </>
  )
}
