import { Flex } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { AccountPage } from '../components/AccountPage'
import { Hub } from '../components/hub/Hub'
import { LandingPageBackgroundImage } from '../components/legacy-landing/LandingPageBackgroundImage'
import { LandingPageContentSection } from '../components/legacy-landing/LandingPageContentSection'
import { LandingPageFullscreenSection } from '../components/legacy-landing/LandingPageFullscreenSection'
import { LandingPageHomeSection } from '../components/legacy-landing/LandingPageHomeSection'
import { FAQ } from '../components/legacy-landing/sections/FAQ'
import { Home } from '../components/legacy-landing/sections/Home'
import { LearnMore } from '../components/legacy-landing/sections/LearnMore'
import { LandingPageSection } from '../components/legacy-landing/types'

const LandingPageRouteMapping: Record<LandingPageSection, string> = {
  Home: '/',
  Left: '/learn',
  Right: '/faq',
  Up: '/account',
  Hub: '/hub',
}

export type LegacyLandingPageProps = {
  mountPoint?: string
}

function joinWithRoot(mountPoint: string, path: string): string {
  return mountPoint.length === 0 || mountPoint === '/' ? path : `${mountPoint}${path}`
}

export const LegacyLandingPage: React.FC<LegacyLandingPageProps> = ({
  mountPoint = '/',
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const backgroundImageRef = useRef<HTMLImageElement | null>(null)
  const backgroundImageInitialized = useRef<boolean>(false)

  const activeSection = useMemo((): LandingPageSection => {
    return (
      Object.values(LandingPageSection)
        .filter((s) => s !== LandingPageSection.Home)
        .find((section) => {
          return location.pathname.startsWith(
            joinWithRoot(mountPoint, LandingPageRouteMapping[section]),
          )
        }) ?? LandingPageSection.Home
    )
  }, [mountPoint, location])

  const [initialSection, setInitialSection] = useState<LandingPageSection | undefined>(
    activeSection,
  )

  const handlePageTransition = useCallback(
    (section: LandingPageSection) => {
      navigate(joinWithRoot(mountPoint, LandingPageRouteMapping[section]))
      setInitialSection(undefined)
    },
    [navigate, mountPoint],
  )

  const handleExitHub = useCallback(() => {
    handlePageTransition(LandingPageSection.Home)
  }, [handlePageTransition])

  return (
    <Flex
      minH="100vh"
      width="full"
      position="relative"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
    >
      <LandingPageBackgroundImage
        initialSection={initialSection}
        ref={backgroundImageRef}
        onLoad={() => {
          setTimeout(() => {
            backgroundImageInitialized.current = true
          }, 1100)
        }}
        activeSection={activeSection}
      />
      <LandingPageHomeSection activeSection={activeSection}>
        <Home handlePageTransition={handlePageTransition} />
      </LandingPageHomeSection>

      <AnimatePresence>
        {activeSection === LandingPageSection.Hub && (
          <LandingPageFullscreenSection
            initializeAsShowing={initialSection === LandingPageSection.Hub}
            isShowing={activeSection === LandingPageSection.Hub}
            content={() => (
              <Hub
                rootMountPath={joinWithRoot(mountPoint, LandingPageRouteMapping.Hub)}
                onExit={handleExitHub}
              />
            )}
          />
        )}

        {activeSection === LandingPageSection.Up && (
          <LandingPageContentSection
            initialSection={initialSection}
            section={LandingPageSection.Up}
            activeSection={activeSection}
          >
            <AccountPage onDone={() => handlePageTransition(LandingPageSection.Home)} />
          </LandingPageContentSection>
        )}
        {activeSection === LandingPageSection.Left && (
          <LandingPageContentSection
            initialSection={initialSection}
            section={LandingPageSection.Left}
            activeSection={activeSection}
          >
            <LearnMore handlePageTransition={handlePageTransition} />
          </LandingPageContentSection>
        )}
        {activeSection === LandingPageSection.Right && (
          <LandingPageContentSection
            initialSection={initialSection}
            section={LandingPageSection.Right}
            activeSection={activeSection}
          >
            <FAQ handlePageTransition={handlePageTransition} />
          </LandingPageContentSection>
        )}
      </AnimatePresence>
    </Flex>
  )
}
