const FONT_CONTENT = `flf2a$ 7 7 26 32 4
Alligator2 by Daniel Wiz. AKA Merlin Greywolf <merlin@brahms.udel.edu>
27th July, 1994
This is the STRAIGHT version of the revised Alligator font I edited.
It's EXACTLY like my other posted font except the tilt was taken out..
$@
$@
$@
$@
$@
$@
$@@
:::$@
:+:$@
+:+$@
+#+$@
+#+$@
$ $ @
###$@@
::: :::$@
:+: :+:$@
$     $ @
$     $ @
$     $ @
$     $ @
$     $ @@
   :::   :::$   @
   :+:   :+:$   @
+:+:+:+:+:+:+:+$@
   +#+   +:+$   @
+#+#+#+#+#+#+#+$@
   #+#   #+#$   @
   ###   ###$   @@
     :::$    @
  :+:+:+:+:$ @
+:+  +:+$    @
  +#++:++#+$ @
     +#+ +#+$@
  #+#+#+#+#$ @
     ###$    @@
:::   :::$      @
:+:   :+:$      @
      +:+$      @
      +#+$      @
      +#+$      @
      #+#   #+#$@
      ###   ###$@@
 :::::::$    @
:+:   :+:$   @
 +:+ +:+$    @
  +#++:  ++#$@
 +#+ +#+#+#$ @
#+#   #+#+$  @
 ##########$ @@
:::$@
:+$ @
$$  @
$$  @
$$  @
$$  @
$$  @@
  :::$@
 :+:$ @
+:+$  @
+#+$  @
+#+$  @
 #+#$ @
  ###$@@
:::$  @
 :+:$ @
  +:+$@
  +#+$@
  +#+$@
 #+#$ @
###$  @@
 $ $     $ $  @
 :+:     :+:$ @
   +:+ +:+$   @
+#++:++#++:++$@
   +#+ +#+$   @
 #+#     #+#$ @
 $ $     $ $  @@
     $ $      @
     :+:$     @
     +:+$     @
+#++:++#++:++$@
     +#+$     @
     #+#$     @
     $ $      @@
$ $ @
$ $ @
$ $ @
$ $ @
$ $ @
#+#$@
##$ @@
$           $ @
$           $ @
$           $ @
+#++:++#++:++$@
$           $ @
$           $ @
$           $ @@
$ $ @
$ $ @
$ $ @
$ $ @
$ $ @
#+#$@
###$@@
      :::$@
     :+:$ @
    +:+$  @
   +#+$   @
  +#+$    @
 #+#$     @
###$      @@
 :::::::$ @
:+:   :+:$@
+:+  :+:+$@
+#+ + +:+$@
+#+#  +#+$@
#+#   #+#$@
 #######$ @@
  :::$  @
:+:+:$  @
  +:+$  @
  +#+$  @
  +#+$  @
  #+#$  @
#######$@@
 ::::::::$ @
:+:    :+:$@
$     +:+$ @
$   +#+$   @
$ +#+$     @
 #+#$      @
##########$@@
 ::::::::$ @
:+:    :+:$@
$      +:+$@
$   +#++:$ @
$      +#+$@
#+#    #+#$@
 ########$ @@
    :::$    @
   :+:$     @
  +:+ +:+$  @
 +#+  +:+$  @
+#+#+#+#+#+$@
      #+#$  @
      ###$  @@
::::::::::$@
:+:    :+:$@
+:+      $ @
+#++:++#+$ @
$      +#+$@
#+#    #+#$@
 ########$ @@
 ::::::::$ @
:+:    :+:$@
+:+      $ @
+#++:++#+$ @
+#+    +#+$@
#+#    #+#$@
 ########$ @@
:::::::::::$@
:+:     :+:$@
       +:+$ @
      +#+$  @
     +#+$   @
    #+#$    @
    ###$    @@
 ::::::::$ @
:+:    :+:$@
+:+    +:+$@
 +#++:++#$ @
+#+    +#+$@
#+#    #+#$@
 ########$ @@
 ::::::::$ @
:+:    :+:$@
+:+    +:+$@
 +#++:++#+$@
$      +#+$@
#+#    #+#$@
 ########$ @@
$ $ @
:+:$@
$ $ @
$ $ @
$ $ @
#+#$@
$ $ @@
$ $ @
:+:$@
$ $ @
$ $ @
$ $ @
#+#$@
##$ @@
   :::$@
  :+:$ @
 +:+$  @
+#+$   @
 +#+$  @
  #+#$ @
   ###$@@
$           $ @
$           $ @
+:+:+:+:+:+:+$@
$           $ @
+#+#+#+#+#+#+$@
$           $ @
$           $ @@
   :::$   @
    :+:$  @
     +:+$ @
      +#+$@
     +#+$ @
    #+#$  @
  ###$    @@
 :::::::::$ @
:+:     :+:$@
       +:+$ @
      +#+$  @
    +#+$    @
    $ $     @
    ###$    @@
   :::::::::::$   @
 :+: :+:+:+:+:+:$ @
+:+ +:+   +:+ +:+$@
+#+ +:+   +#+ +:+$@
+#+ +#+   +#+ +#+$@
 #+# #+#+#+#+#+$  @
   #####$         @@
    :::$    @
  :+: :+:$  @
 +:+   +:+$ @
+#++:++#++:$@
+#+     +#+$@
#+#     #+#$@
###     ###$@@
:::::::::$ @
:+:    :+:$@
+:+    +:+$@
+#++:++#+$ @
+#+    +#+$@
#+#    #+#$@
#########$ @@
 ::::::::$ @
:+:    :+:$@
+:+      $ @
+#+      $ @
+#+      $ @
#+#    #+#$@
 ########$ @@
:::::::::$ @
:+:    :+:$@
+:+    +:+$@
+#+    +:+$@
+#+    +#+$@
#+#    #+#$@
#########$ @@
::::::::::$@
:+:$       @
+:+$       @
+#++:++#$  @
+#+$       @
#+#$       @
##########$@@
::::::::::$@
:+:$       @
+:+$       @
:#::+::#$  @
+#+$       @
#+#$       @
###$       @@
 ::::::::$ @
:+:    :+:$@
+:+      $ @
:#:      $ @
+#+   +#+#$@
#+#    #+#$@
 ########$ @@
:::    :::$@
:+:    :+:$@
+:+    +:+$@
+#++:++#++$@
+#+    +#+$@
#+#    #+#$@
###    ###$@@
:::::::::::$@
    :+:$    @
    +:+$    @
    +#+$    @
    +#+$    @
    #+#$    @
###########$@@
:::::::::::$@
    :+:$    @
    +:+$    @
    +#+$    @
    +#+$    @
#+# #+#$    @
 #####$     @@
:::    :::$@
:+:   :+:$ @
+:+  +:+$  @
+#++:++$   @
+#+  +#+$  @
#+#   #+#$ @
###    ###$@@
:::$       @
:+:$       @
+:+$       @
+#+$       @
+#+$       @
#+#$       @
##########$@@
::::    ::::$ @
+:+:+: :+:+:+$@
+:+ +:+:+ +:+$@
+#+  +:+  +#+$@
+#+       +#+$@
#+#       #+#$@
###       ###$@@
::::    :::$@
:+:+:   :+:$@
:+:+:+  +:+$@
+#+ +:+ +#+$@
+#+  +#+#+#$@
#+#   #+#+#$@
###    ####$@@
 ::::::::$ @
:+:    :+:$@
+:+    +:+$@
+#+    +:+$@
+#+    +#+$@
#+#    #+#$@
 ########$ @@
:::::::::$ @
:+:    :+:$@
+:+    +:+$@
+#++:++#+$ @
+#+$       @
#+#$       @
###$       @@
 ::::::::$  @
:+:    :+:$ @
+:+    +:+$ @
+#+    +:+$ @
+#+  # +#+$ @
#+#   +#+ $ @
 ###### ###$@@
:::::::::$ @
:+:    :+:$@
+:+    +:+$@
+#++:++#:$ @
+#+    +#+$@
#+#    #+#$@
###    ###$@@
 ::::::::$ @
:+:    :+:$@
+:+      $ @
+#++:++#++$@
$      +#+$@
#+#    #+#$@
 ########$ @@
:::::::::::$@
    :+:$    @
    +:+$    @
    +#+$    @
    +#+$    @
    #+#$    @
    ###$    @@
:::    :::$@
:+:    :+:$@
+:+    +:+$@
+#+    +:+$@
+#+    +#+$@
#+#    #+#$@
 ########$ @@
:::     :::$@
:+:     :+:$@
+:+     +:+$@
+#+     +:+$@
 +#+   +#+$ @
  #+#+#+#$  @
    ###$    @@
:::       :::$@
:+:       :+:$@
+:+       +:+$@
+#+  +:+  +#+$@
+#+ +#+#+ +#+$@
 #+#+# #+#+#$ @
  ###   ###$  @@
:::    :::$@
:+:    :+:$@
 +:+  +:+$ @
  +#++:+$  @
 +#+  +#+$ @
#+#    #+#$@
###    ###$@@
:::   :::$@
:+:   :+:$@
 +:+ +:+$ @
  +#++:$  @
   +#+$   @
   #+#$   @
   ###$   @@
:::::::::$@
     :+:$ @
    +:+$  @
   +#+$   @
  +#+$    @
 #+#$     @
#########$@@
::::::$@
:+:$   @
+:+$   @
+#+$   @
+#+$   @
#+#$   @
######$@@
:::$      @
 :+:$     @
  +:+$    @
   +#+$   @
    +#+$  @
     #+#$ @
      ###$@@
::::::$@
   :+:$@
   +:+$@
   +#+$@
   +#+$@
   #+#$@
######$@@
    :::$    @
  :+: :+:$  @
+:+     +:+$@
$         $ @
$         $ @
$         $ @
$         $ @@
$        $ @
$        $ @
$        $ @
$        $ @
$        $ @
$        $ @
##########$@@
:::$@
 :+$@
 $$ @
 $$ @
 $$ @
 $$ @
 $$ @@
    :::$    @
  :+: :+:$  @
 +:+   +:+$ @
+#++:++#++:$@
+#+     +#+$@
#+#     #+#$@
###     ###$@@
:::::::::$ @
:+:    :+:$@
+:+    +:+$@
+#++:++#+$ @
+#+    +#+$@
#+#    #+#$@
#########$ @@
 ::::::::$ @
:+:    :+:$@
+:+      $ @
+#+      $ @
+#+      $ @
#+#    #+#$@
 ########$ @@
:::::::::$ @
:+:    :+:$@
+:+    +:+$@
+#+    +:+$@
+#+    +#+$@
#+#    #+#$@
#########$ @@
::::::::::$@
:+:$       @
+:+$       @
+#++:++#$  @
+#+$       @
#+#$       @
##########$@@
::::::::::$@
:+:$       @
+:+$       @
:#::+::#$  @
+#+$       @
#+#$       @
###$       @@
 ::::::::$ @
:+:    :+:$@
+:+      $ @
:#:      $ @
+#+   +#+#$@
#+#    #+#$@
 ########$ @@
:::    :::$@
:+:    :+:$@
+:+    +:+$@
+#++:++#++$@
+#+    +#+$@
#+#    #+#$@
###    ###$@@
:::::::::::$@
    :+:$    @
    +:+$    @
    +#+$    @
    +#+$    @
    #+#$    @
###########$@@
:::::::::::$@
    :+:$    @
    +:+$    @
    +#+$    @
    +#+$    @
#+# #+#$    @
 #####$     @@
:::    :::$@
:+:   :+:$ @
+:+  +:+$  @
+#++:++$   @
+#+  +#+$  @
#+#   #+#$ @
###    ###$@@
:::$       @
:+:$       @
+:+$       @
+#+$       @
+#+$       @
#+#$       @
##########$@@
::::    ::::$ @
+:+:+: :+:+:+$@
+:+ +:+:+ +:+$@
+#+  +:+  +#+$@
+#+       +#+$@
#+#       #+#$@
###       ###$@@
::::    :::$@
:+:+:   :+:$@
:+:+:+  +:+$@
+#+ +:+ +#+$@
+#+  +#+#+#$@
#+#   #+#+#$@
###    ####$@@
 ::::::::$ @
:+:    :+:$@
+:+    +:+$@
+#+    +:+$@
+#+    +#+$@
#+#    #+#$@
 ########$ @@
:::::::::$ @
:+:    :+:$@
+:+    +:+$@
+#++:++#+$ @
+#+$       @
#+#$       @
###$       @@
 ::::::::$  @
:+:    :+:$ @
+:+    +:+$ @
+#+    +:+$ @
+#+  # +#+$ @
#+#   +#+ $ @
 ###### ###$@@
:::::::::$ @
:+:    :+:$@
+:+    +:+$@
+#++:++#:$ @
+#+    +#+$@
#+#    #+#$@
###    ###$@@
 ::::::::$ @
:+:    :+:$@
+:+      $ @
+#++:++#++$@
$      +#+$@
#+#    #+#$@
 ########$ @@
:::::::::::$@
    :+:$    @
    +:+$    @
    +#+$    @
    +#+$    @
    #+#$    @
    ###$    @@
:::    :::$@
:+:    :+:$@
+:+    +:+$@
+#+    +:+$@
+#+    +#+$@
#+#    #+#$@
 ########$ @@
:::     :::$@
:+:     :+:$@
+:+     +:+$@
+#+     +:+$@
 +#+   +#+$ @
  #+#+#+#$  @
    ###$    @@
:::       :::$@
:+:       :+:$@
+:+       +:+$@
+#+  +:+  +#+$@
+#+ +#+#+ +#+$@
 #+#+# #+#+#$ @
  ###   ###$  @@
:::    :::$@
:+:    :+:$@
 +:+  +:+$ @
  +#++:+$  @
 +#+  +#+$ @
#+#    #+#$@
###    ###$@@
:::   :::$@
:+:   :+:$@
 +:+ +:+$ @
  +#++:$  @
   +#+$   @
   #+#$   @
   ###$   @@
:::::::::$@
     :+:$ @
    +:+$  @
   +#+$   @
  +#+$    @
 #+#$     @
#########$@@
   ::::$@
  :+:$  @
  +:+$  @
+#+$    @
  +#+$  @
  #+#$  @
   ####$@@
:::$@
:+:$@
+:+$@
$ $ @
+#+$@
#+#$@
###$@@
::::$   @
  :+:$  @
  +:+$  @
    +#+$@
  +#+$  @
  #+#$  @
####$   @@
  :::::   :::$@
:+:   :+:+:$  @
$         $   @
$         $   @
$         $   @
$         $   @
$         $   @@
@
@
@
@
@
@
@@
@
@
@
@
@
@
@@
@
@
@
@
@
@
@@
@
@
@
@
@
@
@@
@
@
@
@
@
@
@@
@
@
@
@
@
@
@@
@
@
@
@
@
@
@@
`

type ASCIIFontConfig = {
  hardblank: string
  height: number
  definition: Array<string>
  characters: Record<number, Array<string>>
}

function parseFont(): ASCIIFontConfig {
  const lines = FONT_CONTENT.split('\n')
  const header = lines[0].split(' ')
  const hardblank = header[0].charAt(header[0].length - 1)
  const height = +header[1]
  const comments = +header[5]

  return {
    definition: lines.slice(comments + 1),
    height,
    hardblank,
    characters: {},
  }
}

function parseChar(char: number, font: ASCIIFontConfig): Array<string> {
  if (char in font.characters) {
    return font.characters[char]
  }

  const height = font.height
  const start = (char - 32) * height
  const characterDefinition: Array<string> = []

  for (let i = 0; i < height; i++) {
    characterDefinition[i] = font.definition[start + i]
      .replace(/@/g, '')
      .replace(RegExp('\\' + font.hardblank, 'g'), ' ')
  }
  font.characters[char] = characterDefinition
  return characterDefinition
}

const PARSED_FONT = parseFont()

export function convertToAsciiFont(text: string): string {
  const len = text.length
  const chars = []
  let result = ''

  for (let i = 0; i < len; i++) {
    chars[i] = parseChar(text.charCodeAt(i), PARSED_FONT)
  }

  for (let i = 0, height = chars[0].length; i < height; i++) {
    for (let j = 0; j < len; j++) {
      result += chars[j][i]
    }
    result += '\n'
  }

  return result
}
