import {
  Sidebar,
  SidebarSide,
  Terminal,
  TerminalCommand,
  TerminalMessageType,
  useIsMobile,
  useTerminal,
  useTerminalNetwork,
} from '@chain-runners/ui'
import { TerminalCommandFunction } from '@chain-runners/ui/dist/components/Terminal/types'
import { Box, CloseButton, Flex, useDisclosure } from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BsGlobe } from 'react-icons/all'
import { useNavigate } from 'react-router-dom'
import { useAuthentication } from '../../hooks/useAuthentication'
import { useTraits } from '../../hooks/useTraits'
import { formatAddress } from '../../utils/wallet'
import { buildQueryParamsFromRunnerFilters } from '../hub/applications/RunnerDatabase'
import { LOCAL_FILE_SYSTEM, localBootSequence } from '../hub/networks/local'
import {
  SOMNET_FILE_SYSTEM,
  SOMNET_NETWORK_NAME,
  somnetBootSequence,
} from '../hub/networks/somnet'
import { TerminalDrawerNetworkTab } from '../hub/TerminalDrawerNetworkTab'
import {
  FormatAppLinkOptions,
  HandleNavigateOptions,
  HubApplicationRoute,
} from '../hub/types'

const rootMountPath = '/genesis'

function normalizeSearchString(value: string): string {
  return value.replaceAll(' ', '').trim().toLowerCase()
}

export const LandingPageTerminal: React.FC = () => {
  const {
    data: { traits },
  } = useTraits()
  const { currentUser } = useAuthentication()
  const { isOpen, onToggle } = useDisclosure()
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const formatAppLink = useCallback(
    (route: HubApplicationRoute, options: FormatAppLinkOptions = {}): string => {
      const { queryParams, extraRouteSegments } = options

      const params = queryParams?.toString()
      return `${rootMountPath}${route}${extraRouteSegments ?? ''}${
        params ? `?${params}` : ''
      }`
    },
    [],
  )

  const handleNavigate = useCallback(
    (route: HubApplicationRoute, options: HandleNavigateOptions = {}) => {
      const { queryParams, replace, extraRouteSegments, state } = options
      navigate(formatAppLink(route, { queryParams, extraRouteSegments }), {
        replace,
        state,
      })
    },
    [formatAppLink, navigate],
  )

  const [isNetworkOpen, setIsNetworkOpen] = useState(!isMobile)

  const somnetTerminalPrompt = useMemo(() => {
    return currentUser?.address ? `${formatAddress(currentUser.address)}@somnet $` : '$'
  }, [currentUser])

  const somnetTerminal = useTerminal({
    fileSystem: SOMNET_FILE_SYSTEM,
    bootSequence: somnetBootSequence,
    prompt: somnetTerminalPrompt,
  })

  const localTerminalPrompt = useMemo(() => {
    return currentUser?.address ? `${formatAddress(currentUser.address)} $` : '$'
  }, [currentUser])

  const runnerCommand: TerminalCommandFunction = useCallback(
    async ({ sendToStdout }, args) => {
      const filterValue = args[0]
      if (!filterValue) {
        handleNavigate(HubApplicationRoute.RunnerDatabase)
        return
      }

      if (filterValue.length === 42 && filterValue.toLowerCase().startsWith('0x')) {
        handleNavigate(HubApplicationRoute.RunnerDatabase, {
          queryParams: buildQueryParamsFromRunnerFilters({ ownedBy: filterValue }),
        })
        return
      }

      const tokenId = parseInt(filterValue)
      if (!isNaN(tokenId) && tokenId > 0 && tokenId <= 10_000) {
        handleNavigate(HubApplicationRoute.RunnerDatabase, {
          extraRouteSegments: `/${tokenId}`,
        })
        return
      }

      const filterText = args.join(' ')
      const normalizedFilterText = normalizeSearchString(filterText)
      const trait = traits.find(
        (t) => normalizeSearchString(t.displayName) === normalizedFilterText,
      )
      if (trait) {
        handleNavigate(HubApplicationRoute.RunnerDatabase, {
          queryParams: buildQueryParamsFromRunnerFilters({
            traits: [
              {
                traitType: trait.type,
                values: [trait.displayName],
              },
            ],
          }),
        })
        return
      }

      sendToStdout({
        message: `Invalid parameter: ${filterText}`,
        type: TerminalMessageType.Error,
      })
      return
    },
    [traits, handleNavigate],
  )

  const customAppCommands = useMemo((): Array<TerminalCommand> => {
    return [
      {
        name: 'runners',
        helpText:
          'runners - View Runners -runners <id> | runners <address> | runners <trait name>',
        func: runnerCommand,
      },
      {
        name: 'runner',
        helpText:
          'runner - View Runners -runner <id> | runner <address> | runner <trait name>',
        func: runnerCommand,
      },
    ]
  }, [runnerCommand])

  const localTerminal = useTerminal({
    fileSystem: LOCAL_FILE_SYSTEM,
    customCommands: customAppCommands,
    prompt: localTerminalPrompt,
    bootSequence: localBootSequence,
  })

  const { activeNetwork, availableNetworks } = useTerminalNetwork({
    localTerminal,
    connectedNetworks: [
      {
        name: SOMNET_NETWORK_NAME,
        terminalData: somnetTerminal,
      },
    ],
  })

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.code === 'Backquote') {
        onToggle()
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [onToggle])

  return (
    <Flex
      width={isOpen || isMobile ? 'calc(100vw - 32px)' : '267px'}
      height={isOpen ? '350px' : '54px'}
      bgColor="#1F1E1E"
      borderRadius="8px"
      position="fixed"
      bottom={4}
      right={4}
      color="hackerGreen"
      fontWeight="bold"
      alignItems="center"
      fontSize="18px"
      fontFamily="mono"
      borderColor="whiteAlpha.300"
      borderStyle="solid"
      borderWidth="1px"
      zIndex={6}
      transition="width 0.2s ease, height 0.2s ease"
      direction="column"
    >
      <Flex
        userSelect="none"
        cursor="pointer"
        py={3}
        px={4}
        width="full"
        borderBottomColor="whiteAlpha.300"
        borderBottomWidth={isOpen ? '1px' : 0}
        alignItems="center"
        onClick={onToggle}
      >
        <Box>
          &gt;_{' '}
          <Box as="span" ml="10px">
            Terminal
          </Box>
        </Box>

        <Flex
          flex={1}
          justifyContent="flex-end"
          display={isOpen ? undefined : 'none'}
          opacity={isOpen ? 1 : 0}
          transitionDelay="0.2s"
          transition="opacity 0.2s ease"
        >
          <CloseButton _focus={{ outline: 'none' }} />
        </Flex>
      </Flex>
      <Flex
        flex={1}
        width="full"
        display={isOpen ? undefined : 'none'}
        opacity={isOpen ? 1 : 0}
        transitionDelay="0.2s"
        transition="opacity 0.2s ease"
        position="relative"
        overflowY="auto"
      >
        {isOpen && (
          <Terminal
            terminalData={activeNetwork.terminalData}
            containerProps={{ mr: isMobile ? '39px' : 0 }}
          />
        )}

        <Sidebar
          side={SidebarSide.Right}
          isOpen={isNetworkOpen}
          setIsOpen={setIsNetworkOpen}
          title={<Box fontWeight="semibold">Known networks</Box>}
          containerProps={{
            left: !isNetworkOpen ? 'calc(100vw - 40px - 34px)' : 0,
            bgColor: '#1F1E1E',
            borderColor: 'whiteAlpha.300',
            borderBottomRightRadius: '8px',
          }}
          tabContainerProps={{
            bgColor: '#1F1E1E',
            borderColor: 'whiteAlpha.300',
            borderBottomRightRadius: isNetworkOpen ? 0 : '8px',
          }}
          tabButtonProps={{
            bgColor: isNetworkOpen ? 'hackerGreen' : '#1F1E1E',
            borderColor: 'whiteAlpha.300',
          }}
          tabs={[
            {
              icon: BsGlobe,
              content: (
                <TerminalDrawerNetworkTab
                  activeNetwork={activeNetwork}
                  availableNetworks={availableNetworks}
                />
              ),
            },
          ]}
        />
      </Flex>
    </Flex>
  )
}
