import { RunnerTrait } from '@chain-runners/types'
import { HackerButton, Sidebar, SidebarCheckboxFilter } from '@chain-runners/ui'
import { ALL_TRAIT_ORDERING } from '@chain-runners/utils'
import { Box, Flex } from '@chakra-ui/react'
import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { TokenFilters, TraitType } from '../../clients/api/generated'
import { useAuthentication } from '../../hooks/useAuthentication'
import { TraitFilterGroup } from './components/TraitFilterGroup'

export type RunnerFiltersSidebarProps = {
  filters: TokenFilters
  setFilters: Dispatch<SetStateAction<TokenFilters>>
  isOpen?: boolean
  setIsOpen?: Dispatch<SetStateAction<boolean>>
  traitsByType: Record<TraitType, Array<RunnerTrait>>
}

export const RunnerFiltersSidebar: React.FC<RunnerFiltersSidebarProps> = ({
  filters,
  setFilters,
  isOpen,
  setIsOpen,
  traitsByType,
}) => {
  const { currentUser } = useAuthentication()

  const createOwnedByFilterSetter = useCallback(
    (address?: string) => {
      return () => {
        setFilters((filters) => {
          return {
            ...filters,
            ownedBy: address,
          }
        })
      }
    },
    [setFilters],
  )

  const createTraitFilterSetter = useCallback(
    (traitType: TraitType) => {
      return (selectedTraits?: Array<string>) => {
        setFilters((filters) => {
          const newTraits = filters.traits?.filter((t) => t.traitType !== traitType) ?? []
          if (selectedTraits?.length) {
            newTraits.push({
              traitType,
              values: selectedTraits,
            })
          }

          return {
            ...filters,
            traits: newTraits,
          }
        })
      }
    },
    [setFilters],
  )

  const handleOnlyBioChange = useCallback(
    (onlyBio?: boolean) => {
      setFilters((filters) => ({
        ...filters,
        withBioOnly: onlyBio ?? null,
      }))
    },
    [setFilters],
  )

  return (
    <Sidebar
      title={<Box fontWeight="semibold">Filters</Box>}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Flex py={4} px={4} justifyContent="center">
        <HackerButton
          flex={1}
          size="sm"
          onClick={createOwnedByFilterSetter(currentUser?.address)}
          filled={Boolean(filters.ownedBy === currentUser?.address)}
          textTransform="none"
          borderRightStyle="none"
          isDisabled={!currentUser}
        >
          My Runners
        </HackerButton>
        <HackerButton
          flex={1}
          size="sm"
          onClick={createOwnedByFilterSetter()}
          filled={!Boolean(filters.ownedBy)}
          textTransform="none"
        >
          All Runners
        </HackerButton>
      </Flex>
      <SidebarCheckboxFilter
        label="With Bio Only"
        onChange={handleOnlyBioChange}
        isChecked={filters.withBioOnly ?? false}
      />
      {ALL_TRAIT_ORDERING.map((traitType: TraitType, index: number) => (
        <TraitFilterGroup
          key={traitType}
          traitType={traitType}
          traits={traitsByType[traitType]}
          defaultOpen={index < 2}
          selectedTraits={filters.traits?.find((t) => t.traitType === traitType)?.values}
          setSelectedTraits={createTraitFilterSetter(traitType)}
        />
      ))}
    </Sidebar>
  )
}
