import { Text, TextProps } from '@chakra-ui/react'
import { DateTime } from 'luxon'
import React from 'react'
import { useCountdown } from '../../hooks/useCountdown'

export type CountdownProps = {
  to: DateTime
  refreshIntervalMS?: number
  forceReady?: boolean
  readyText?: string
  textProps?: Partial<TextProps>
  hideWhenComplete?: boolean
}

export const Countdown: React.FC<CountdownProps> = ({
  to,
  forceReady,
  textProps,
  readyText,
  refreshIntervalMS = 1000,
  hideWhenComplete,
}) => {
  const { remaining } = useCountdown({ to, forceReady, refreshIntervalMS })

  if (hideWhenComplete && remaining === null) {
    return <React.Fragment />
  }

  return (
    <Text letterSpacing="4px" {...textProps}>
      {remaining ? remaining.toFormat('dd:hh:mm:ss') : readyText ?? '00 00 00 00'}
    </Text>
  )
}
