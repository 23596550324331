import { alphaColor, usePrimaryColor } from '@chain-runners/ui'
import { Box, Flex } from '@chakra-ui/react'
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { IoCloseSharp } from 'react-icons/all'
import { TokenFilters, TokenTraitFilter } from '../../clients/api/generated'
import { useAuthentication } from '../../hooks/useAuthentication'
import { formatAddress } from '../../utils/wallet'
import { FilterItem, FilterType } from './types'

export type RunnerActiveFilterItemsProps = {
  filters: TokenFilters
  setFilters: Dispatch<SetStateAction<TokenFilters>>
}

export const RunnerActiveFilterItems: React.FC<RunnerActiveFilterItemsProps> = ({
  filters,
  setFilters,
}) => {
  const { primaryColor } = usePrimaryColor()
  const { currentUser } = useAuthentication()

  const filterItems: Array<FilterItem> = useMemo(() => {
    const items =
      filters.traits?.flatMap((t) =>
        t.values.map((value): FilterItem => ({ type: t.traitType, label: value, value })),
      ) ?? []

    if (filters.ownedBy && filters.ownedBy !== currentUser?.address) {
      items.push({
        type: FilterType.Address,
        value: filters.ownedBy,
        label: formatAddress(filters.ownedBy),
      })
    }

    if (filters.withBioOnly) {
      items.push({
        type: FilterType.WithBioOnly,
        value: 'Bio Only',
        label: 'Bio Only',
      })
    }
    return items
  }, [currentUser, filters])

  const handleRemoveFilterItem = useCallback(
    (item: FilterItem) => {
      setFilters((filters) => {
        if (item.type === FilterType.Address) {
          return {
            traits: filters.traits,
            withBioOnly: filters.withBioOnly,
          }
        }

        if (item.type === FilterType.WithBioOnly) {
          return {
            traits: filters.traits,
            ownedBy: filters.ownedBy,
          }
        }

        const itemTrait: TokenTraitFilter | undefined = filters?.traits?.find(
          (t) => t.traitType === item.type,
        )
        if (!itemTrait) return filters
        const newValues = itemTrait.values.filter((v) => v !== item.value)
        const newTrait = newValues.length ? [{ ...itemTrait, values: newValues }] : []
        return {
          ...filters,
          traits: [
            ...(filters.traits?.filter((t) => t.traitType !== item.type) ?? []),
            ...newTrait,
          ],
        }
      })
    },
    [setFilters],
  )

  const handleClearFilters = useCallback(() => {
    setFilters({})
  }, [setFilters])

  return (
    <Flex flexWrap="wrap" alignItems="center" paddingBottom={filterItems.length ? 2 : 0}>
      {filterItems.map((item) => {
        return (
          <Flex
            key={item.value}
            mb={2}
            px={2}
            py={1}
            bgColor="black"
            borderColor={primaryColor}
            borderWidth="1px"
            color={primaryColor}
            alignItems="center"
            cursor="pointer"
            userSelect="none"
            fontSize="14px"
            mr={2}
            _hover={{
              bgColor: alphaColor(primaryColor, 0.1),
            }}
            _active={{
              bgColor: alphaColor(primaryColor, 0.15),
            }}
            onClick={() => {
              handleRemoveFilterItem(item)
            }}
          >
            {item.label}
            <Box as="span" ml={2}>
              <IoCloseSharp />
            </Box>
          </Flex>
        )
      })}
      {filterItems.length > 0 && (
        <Box
          mb={2}
          _hover={{ textDecoration: 'underline' }}
          cursor="pointer"
          userSelect="none"
          px={1}
          fontSize="12px"
          onClick={handleClearFilters}
        >
          Clear All
        </Box>
      )}
    </Flex>
  )
}
