import { Web3Provider } from '@ethersproject/providers'

export type Signature = {
  id?: string
  result: string
  jsonrpc: string
}

export async function getSignature(
  provider: Web3Provider,
  account: string,
  message: string,
): Promise<Signature | string> {
  return await provider.send('personal_sign', [message, account])
}

export function formatAddress(address: string): string {
  return address.slice(0, 8)
}
