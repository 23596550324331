import { MotionBox, MotionFlex, usePrimaryColor, useWindowSize } from '@chain-runners/ui'
import { Box, Flex, FlexProps } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import { range } from 'lodash-es'
import React, { useEffect, useMemo } from 'react'
import { useAuthentication } from '../../hooks/useAuthentication'
import { convertToAsciiFont } from '../../utils/ascii'
import { formatAddress } from '../../utils/wallet'

export type HubLoadingPageProps = {
  onDone: () => void
  containerProps?: Partial<FlexProps>
  loadDurationInSeconds?: number
}

const LOAD_DURATION_IN_SECONDS = 2

export const HubLoadingPage: React.FC<HubLoadingPageProps> = ({
  loadDurationInSeconds = LOAD_DURATION_IN_SECONDS,
  containerProps,
  onDone,
}) => {
  const { currentUser } = useAuthentication()
  const { address } = currentUser ?? {}
  const { primaryColor } = usePrimaryColor()

  useEffect(() => {
    const interval = setTimeout(onDone, 1000 * loadDurationInSeconds)

    return () => {
      clearTimeout(interval)
    }
  }, [onDone, loadDurationInSeconds])

  const textContent = useMemo(() => {
    const text = address ? formatAddress(address) : 'Loading...'
    return convertToAsciiFont(text)
  }, [address])

  const { width } = useWindowSize()

  return (
    <MotionFlex
      flexDirection="column"
      animate={{
        opacity: 1,
        transition: {
          ease: 'easeOut',
          duration: 0.5,
        },
      }}
      overflowY="hidden"
      exit={{
        opacity: 0,
        transition: {
          ease: 'easeOut',
          duration: 0.5,
        },
      }}
      flex={1}
      {...containerProps}
    >
      <Flex mt={['15vh', null, '12%']} justifyContent="center" alignItems="center">
        <Box>
          {textContent
            .split('\n')
            .filter((l) => Boolean(l))
            .map((line, index) => {
              return (
                <Flex
                  key={index}
                  fontSize={`${Math.min(Math.trunc(0.012 * width), 10)}px`}
                >
                  {range(line.length).map((li) => {
                    return (
                      <Box as="span" minH="1.5em" minW="1em" key={li}>
                        {line[li] || ' '}
                      </Box>
                    )
                  })}
                </Flex>
              )
            })}
        </Box>
      </Flex>
      <Flex justifyContent="center" alignItems="center" mt={8}>
        <Box
          borderWidth="1px"
          borderColor={primaryColor}
          maxWidth="80%"
          width="600px"
          height="20px"
          p="2px"
        >
          <AnimatePresence>
            <MotionBox
              initial={{ width: '0' }}
              bgColor={primaryColor}
              height="full"
              animate={{
                width: '100%',
                transition: {
                  duration: loadDurationInSeconds || 0.5,
                  ease: 'easeIn',
                },
              }}
            />
          </AnimatePresence>
        </Box>
      </Flex>
      <Box
        className="hub-loading-floor"
        backgroundImage={`linear-gradient(to right, ${primaryColor} 1px, transparent 1px), linear-gradient(to bottom, ${primaryColor} 1px, transparent 1px) !important;`}
      />
    </MotionFlex>
  )
}
