import { RunnerTrait, TraitType } from '@chain-runners/types'
import React from 'react'

export enum HubApplicationRoute {
  Home = '/',
  RunnerDatabase = '/runners',
  TraitExplorer = '/traits',
  EventPOAPClaim = '/event-poap-claim',
}

export type HubBreadcrumbItem = {
  name: string
}

export type HandleNavigateOptions = {
  queryParams?: URLSearchParams
  extraRouteSegments?: string
  replace?: boolean
  state?: any
}

export type FormatAppLinkOptions = {
  queryParams?: URLSearchParams
  extraRouteSegments?: string
}

export type HandleNavigateFunction = (
  route: HubApplicationRoute,
  options?: HandleNavigateOptions,
) => void

export type FormatAppLinkFunction = (
  route: HubApplicationRoute,
  options?: FormatAppLinkOptions,
) => string

export type BaseHubApplicationProps = {
  setApplicationBreadcrumbs: (breadcrumbs: Array<HubBreadcrumbItem>) => void
  rootMountPath: string
  handleNavigate: HandleNavigateFunction
  formatAppLink: FormatAppLinkFunction
  application: HubApplication
  traits: Array<RunnerTrait>
  traitsById: Record<number, RunnerTrait>
  traitsByType: Record<TraitType, Array<RunnerTrait>>
}

export type IconProps = {
  size: string | number
}

export type HubApplication = {
  route: HubApplicationRoute
  name: string
  icon: React.FC<IconProps>
  component: React.FC<BaseHubApplicationProps>
}

export type HiddenTraits = Partial<Record<TraitType, boolean>>

export enum FilterType {
  TraitType = 'TraitType',
  Address = 'Address',
  WithBioOnly = 'WithBioOnly',
}

export type FilterItem = { type: string; label: string; value: string }
