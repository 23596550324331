import { isNotNullOrUndefined } from '@chain-runners/utils'
import { AiFillAppstore, AiFillDatabase, RiQuestionAnswerFill } from 'react-icons/all'
import { EVENT_POAP_CLAIM_ACTIVE } from '../../config/base'
import { EventPOAPClaim } from './applications/EventPOAPClaim'
import { RunnerDatabase } from './applications/RunnerDatabase'
import { TraitExplorer } from './applications/TraitExplorer'
import { HubApplication, HubApplicationRoute } from './types'

export const RunnerDatabaseApplication: HubApplication = {
  route: HubApplicationRoute.RunnerDatabase,
  name: 'Runner Database',
  icon: AiFillDatabase,
  component: RunnerDatabase,
}

export const TraitExplorerApplication: HubApplication = {
  route: HubApplicationRoute.TraitExplorer,
  name: 'Trait Explorer',
  icon: AiFillAppstore,
  component: TraitExplorer,
}

export const EventPOAPClaimApplication: HubApplication = {
  route: HubApplicationRoute.EventPOAPClaim,
  name: 'Trivia Night',
  icon: RiQuestionAnswerFill,
  component: EventPOAPClaim,
}

export const APPLICATIONS: Array<HubApplication> = [
  EVENT_POAP_CLAIM_ACTIVE ? EventPOAPClaimApplication : null,
  TraitExplorerApplication,
  RunnerDatabaseApplication,
].filter(isNotNullOrUndefined)
