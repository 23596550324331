import { getOrThrow, setImageServiceBaseURI } from '@chain-runners/utils'

export const INFURA_ID = process.env.REACT_APP_INFURA_PROJECT_ID

export const DISCORD_INVITE_LINK = 'https://discord.gg/HxSmvX675m'
export const TWITTER_HANDLE = 'chain_runners'

export const COUNTDOWN_TIMESTAMP = Number(process.env.REACT_APP_COUNTDOWN_TIMESTAMP)
export const EVENT_POAP_CLAIM_ACTIVE =
  process.env.REACT_APP_EVENT_POAP_CLAIM_ACTIVE === 'true'

export const EXTERNAL_SERVER_URI = getOrThrow(process.env.REACT_APP_EXTERNAL_SERVER_URI)
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY

export const DISCORD_AUTH_SCOPES = ['identify']
export const DISCORD_OAUTH_URL = 'https://discord.com/api/oauth2/authorize'
export const DISCORD_CLIENT_ID = getOrThrow(process.env.REACT_APP_DISCORD_CLIENT_ID)
export const DISCORD_OAUTH_REDIRECT_URI = `${EXTERNAL_SERVER_URI}/account`
export const IMAGE_API_BASE_URI =
  process.env.REACT_APP_IMAGE_API_BASE_URI ?? 'https://img.chainrunners.xyz'

console.log(`Using ${IMAGE_API_BASE_URI} for image server...`)
setImageServiceBaseURI(IMAGE_API_BASE_URI)
