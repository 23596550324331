import { RunnerTrait } from '@chain-runners/types'
import { HackerTooltip, usePrimaryColor } from '@chain-runners/ui'
import { formatTraitType } from '@chain-runners/utils'
import { Box, Flex, FlexProps, useBoolean } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/all'
import { formatPercent } from '../utils/numbers'

export type TraitListItemProps = {
  trait: RunnerTrait
  isHidden?: boolean
  onToggleVisibility?: () => void
  containerProps?: Partial<FlexProps>
}

export const TraitListItem: React.FC<TraitListItemProps> = ({
  trait,
  containerProps,
  isHidden = false,
  onToggleVisibility,
}) => {
  const [isHovering, { on: onHoverStart, off: onHoverStop }] = useBoolean(false)
  const { primaryColor } = usePrimaryColor()

  return (
    <HackerTooltip
      label={
        <Box textAlign="center">
          <Box>{trait.displayName}</Box>
          <Box
            hidden={!onToggleVisibility}
            fontSize="10px"
            fontWeight="semibold"
            color="white"
          >
            (Click to {isHidden ? 'Show' : 'Hide'})
          </Box>
        </Box>
      }
      bgColor="black"
    >
      <Flex
        key={trait.type}
        alignItems="center"
        fontSize="13px"
        fontWeight="semibold"
        onClick={onToggleVisibility}
        onMouseEnter={onHoverStart}
        onMouseLeave={onHoverStop}
        cursor={onToggleVisibility ? 'pointer' : undefined}
        opacity={isHidden ? 0.5 : 1}
        userSelect="none"
        {...containerProps}
      >
        <Box
          w="112px"
          minW="112px"
          borderStyle="solid"
          borderWidth="1px"
          borderColor={primaryColor}
          px={3}
          py={2}
          bgColor={primaryColor}
          color="black"
          h="full"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {formatTraitType(trait.type)}
        </Box>

        <Flex
          flex={1}
          px={3}
          py={2}
          borderStyle="solid"
          borderWidth="1px"
          borderColor={primaryColor}
          overflow="hidden"
          alignItems="center"
        >
          <Box flex={1} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {trait.displayName}
          </Box>
          <Box color="#737373" ml={2}>
            {onToggleVisibility && isHovering ? (
              <Box>
                {isHidden ? (
                  <AiOutlineEyeInvisible size={18} />
                ) : (
                  <AiOutlineEye size={18} />
                )}
              </Box>
            ) : (
              <Box>{formatPercent(trait.tokenCount, 10000)}</Box>
            )}
          </Box>
        </Flex>
      </Flex>
    </HackerTooltip>
  )
}
