import { Box } from '@chakra-ui/react'
import React from 'react'
import { LandingPageSectionWithDetails } from '../LandingPageSectionWithDetails'
import { LandingPageSectionDetailsConfig } from '../types'

const MEGA_CITY_SECTION_CONFIG: LandingPageSectionDetailsConfig = {
  title: 'Mega City',
  content: (
    <>
      <Box as="p" mb={8}>
        In the heights of Mega City&apos;s glittering towers, the good life is on tap for a complacent, cooperative citizen. Methodically constructed to sustain, contain, and tranquilize its population, Mega City is humanity&apos;s deliverance from the chaos, exploitation and greed of the 21st century, a thriving technological and commercial hub drawing all manner of people.
      </Box>

      <Box as="p">
        Few care to think about how this place came to be, finding themselves more than content to enjoy the effortless prosperity. But for the restive and rebellious, down in the bustling streets and twisting, claustrophobic back alleys, the structured and artificial lifestyle of Mega City is nothing but a thin veil over an oppressive, manipulative system.
      </Box>
    </>
  ),
  backgroundImage: '/static/CR_Site_Mega_City.png',
}

const ABOUT_PAGE_DETAILS_CONFIG: Array<LandingPageSectionDetailsConfig> = [
  {
    ...MEGA_CITY_SECTION_CONFIG,
    imageProps: {
      backgroundPosition: 'right -240px top 0px',
    },
  },
  {
    ...MEGA_CITY_SECTION_CONFIG,
    title: 'Runners',
    backgroundImage: '/static/CR_Site_Underground.png',
    imageProps: {
      backgroundPosition: 'right 225px top 0px',
    },
    content: (
      <>
      <Box as="p" mb={8}>
        Hackers, infiltrators, saboteurs, vandals, wheelers and dealers, freaks, pirates and rebels, the Chain Runners are those who remain unimpressed by the Mega City sales pitch. These technologically-savvy renegades spend their days infiltrating Mega City&apos;s vast networks, looking behind its systems and institutions, searching for answers, advantages, or just anarchic amusement.
      </Box>
       <Box as="p">
        Whether vandals, opportunists, or revolutionaries, the Runners live their lives on the line, always exposed to the threat of retribution or worse from Mega City&apos;s authorities and, above all, from its omnipotent ruler: Somnus.
      </Box>
      </>
    )
  },
  {
    ...MEGA_CITY_SECTION_CONFIG,
    title: 'Somnus',
    backgroundImage: '/static/CR_Site_Somnolith.png',
    content: (
      <>
      <Box as="p" mb={8}>
        The enigmatic face of Mega City, adored by the law-abiding elite as a benevolent leader and symbol of prosperity, Somnus operates the vast machinery of the sprawling metropolis with what the Runners recognize as one ominous goal: order, at all costs.
      </Box>
       <Box as="p" mb={8}>
        In a society defined by carefully curated comforts and constraints, the Runners represent disconcerting chaos for Somnus; a problematic human unpredictability that appears to threaten the carefully balanced ecosystem of Mega City.
      </Box>
       <Box as="p">
        Now, as the Runners begin expanding their ranks, challenges arise to Somnus&apos; previously unimpeded ambitions for control, and it remains to be seen how far Somnus will go to protect the established order.
      </Box>
      </>
    )
  },
]

export const UniverseLandingPageHomeSection: React.FC = () => {
  return (
    <LandingPageSectionWithDetails
      title="The Universe"
      detailsConfig={ABOUT_PAGE_DETAILS_CONFIG}
    />
  )
}
