import { getOrThrow } from '@chain-runners/utils'
import { GraphQLClient } from 'graphql-request'
import { getSdk } from './generated'

export const GRAPHQL_API_URI = getOrThrow(process.env.REACT_APP_GRAPHQL_API_URI)
export type GraphQLAPIClient = ReturnType<typeof getSdk>

export function getAPIHeaders(token?: string): Record<string, string> {
  return token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {}
}

export function getAPIClient(token?: string): GraphQLAPIClient {
  const client = new GraphQLClient(GRAPHQL_API_URI, {
    credentials: 'include',
  })

  console.log('GRAPHQL_API_URI', GRAPHQL_API_URI)

  const headers = getAPIHeaders(token)
  return getSdk(client, (action) => {
    return action(headers)
  })
}
