import { BaseProvider } from '@ethersproject/providers/src.ts/base-provider'
import { ethers } from 'ethers'
import React, { useMemo } from 'react'
import { getNetworkConfig } from '../utils/network'

export type BackupProviderContextValue = {
  provider: BaseProvider
}

export const BackupProviderContext = React.createContext<BackupProviderContextValue>({
  provider: {} as any,
})

export const BackupWalletProvider: React.FC = ({ children }) => {
  const provider = useMemo(
    () => new ethers.providers.JsonRpcProvider(getNetworkConfig().rpcUrl),
    [],
  )
  return (
    <BackupProviderContext.Provider
      value={{
        provider,
      }}
    >
      {children}
    </BackupProviderContext.Provider>
  )
}

export type UseBackupProviderValue = BackupProviderContextValue

export function useBackupWalletProvider(): UseBackupProviderValue {
  return React.useContext<BackupProviderContextValue>(BackupProviderContext)
}
