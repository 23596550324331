import { RunnerTrait } from '@chain-runners/types'
import { alphaColor, usePrimaryColor } from '@chain-runners/ui'
import { formatTraitTypeLong } from '@chain-runners/utils'
import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import { orderBy } from 'lodash-es'
import React, { useCallback, useMemo, useRef } from 'react'
import { BiCheckbox, BiCheckboxSquare } from 'react-icons/bi'
import { TraitType } from 'src/clients/api/generated'

export type TraitTypeFilterGroupProps = {
  defaultOpen?: boolean
  selectedTraits?: Array<TraitType>
  setSelectedTraits: (selectedTraits?: Array<TraitType>) => void
  traitsByType: Record<TraitType, Array<RunnerTrait>>
}

export const TraitTypeFilterGroup: React.FC<TraitTypeFilterGroupProps> = ({
  selectedTraits,
  setSelectedTraits,
  defaultOpen,
  traitsByType,
}) => {
  const { primaryColor } = usePrimaryColor()

  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: defaultOpen })
  const {
    isOpen: isExpanded,
    onOpen: onExpand,
    onClose: onCollapse,
  } = useDisclosure({ defaultIsOpen: isOpen })

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const handleOpen = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current)
    onOpen()
    onExpand()
  }, [onOpen, onExpand])

  const handleClose = useCallback(() => {
    onClose()
    timeoutRef.current = setTimeout(onCollapse, 300)
  }, [onClose, onCollapse])

  const sortedFilterItems: Array<{
    displayName: string
    value: TraitType
    count: number
  }> = useMemo(() => {
    return orderBy(
      Object.values(TraitType).map((traitType) => {
        return {
          displayName: formatTraitTypeLong(traitType),
          value: traitType,
          count: traitsByType[traitType].length,
        }
      }),
      (t) => t.count,
      'desc',
    )
  }, [traitsByType])

  const onToggleItem = useCallback(
    (isSelected: boolean, displayName: string) => {
      if (!setSelectedTraits) return

      if (isSelected) {
        setSelectedTraits(selectedTraits?.filter((n) => n !== displayName))
      } else {
        setSelectedTraits([...(selectedTraits ?? []), displayName as TraitType])
      }
    },
    [setSelectedTraits, selectedTraits],
  )

  return (
    <Box
      w="full"
      borderColor={primaryColor}
      borderTopWidth="1px"
      _last={{ borderBottomWidth: '1px' }}
    >
      <Flex
        w="full"
        py={2}
        px={2}
        borderBottomColor={primaryColor}
        borderBottomWidth={isExpanded ? '1px' : 0}
        color={isOpen ? 'black' : primaryColor}
        bgColor={isOpen ? primaryColor : 'black'}
        fontSize={14}
        fontWeight="semibold"
        onClick={isOpen ? handleClose : handleOpen}
        cursor="pointer"
        _active={{
          bgColor: isOpen ? alphaColor(primaryColor, 0.9) : alphaColor(primaryColor, 0.2),
        }}
        userSelect="none"
      >
        <Flex>Type</Flex>
      </Flex>
      <Box h={isOpen ? '230px' : 0} transition="height 0.3s" overflowY="auto">
        {sortedFilterItems.map((filterItem) => {
          const isChecked = selectedTraits?.includes(filterItem.value) ?? false
          return (
            <Flex
              alignItems="center"
              key={filterItem.displayName}
              py={1}
              px={2}
              fontSize="12px"
              cursor="pointer"
              userSelect="none"
              _hover={{
                bgColor: alphaColor(primaryColor, 0.1),
              }}
              _active={{
                bgColor: alphaColor(primaryColor, 0.15),
              }}
              onClick={() => onToggleItem(isChecked, filterItem.value)}
            >
              <Flex fontSize="16px" mr={2}>
                {isChecked ? <BiCheckboxSquare /> : <BiCheckbox />}
              </Flex>
              <Flex flex={1}>{filterItem.displayName}</Flex>
              <Flex>{filterItem.count}</Flex>
            </Flex>
          )
        })}
      </Box>
    </Box>
  )
}
