import { HackerButton, HackerButtonProps, usePrimaryColor } from '@chain-runners/ui'
import { Box, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import React from 'react'
import { useAuthentication } from '../hooks/useAuthentication'
import { formatAddress } from '../utils/wallet'

export type ConnectWalletButtonProps = {
  buttonProps?: Partial<HackerButtonProps>
}

export const ConnectWalletButton: React.FC<ConnectWalletButtonProps> = ({
  children,
  buttonProps,
}) => {
  const { primaryColor } = usePrimaryColor()
  const { isLoading, currentUser, login, logout } = useAuthentication()

  if (currentUser) {
    const { address } = currentUser
    return (
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              isActive={isOpen}
              as={HackerButton}
              textTransform="initial"
              paddingX={[2, 4, 6]}
              {...buttonProps}
            >
              <Box px={2}>{formatAddress(address)}</Box>
            </MenuButton>
            <MenuList
              zIndex={10000}
              backgroundColor="black"
              borderRadius={0}
              borderColor={primaryColor}
              color={primaryColor}
            >
              {children}
              <MenuItem onClick={logout}>Logout</MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    )
  }

  return (
    <HackerButton
      isLoading={isLoading}
      onClick={() => login()}
      paddingX={[4, 8, 12]}
      {...buttonProps}
    >
      Connect
    </HackerButton>
  )
}
