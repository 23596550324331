import { Box, Flex, Image } from '@chakra-ui/react'
import React from 'react'
import { BsChevronRight } from 'react-icons/all'
import { LandingPageButton } from '../LandingPageButton'
import { LandingPageContainer } from '../LandingPageContainer'
import { LandingPageSection } from '../types'

export type LearnMoreProps = {
  handlePageTransition: (pageSection: LandingPageSection) => void
}

export const LearnMore: React.FC<LearnMoreProps> = ({ handlePageTransition }) => {
  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flex={1}
        width="full"
        maxWidth="1280px"
        marginX="auto"
        paddingTop={12}
      >
        <Flex width="full">
          <LandingPageContainer
            title="Break The Chain"
            scanLineCount={60}
            containerProps={{
              minWidth: 'calc(min(90vw, 585px))',
              maxWidth: '584px',
              minHeight: '211px',
            }}
          >
            <Box paddingBottom="63px">
              <p>
                Not long ago – decentralization, enabled by The Chain, fragmented nations
                into sovereign states. A short-lived era of independence and
                self-actualization eventually turned to disorder.
              </p>
              <br />
              <p>
                Many cities were lost, but the humans lived on, innovating under the new
                constraints. Until the spark. The Chain gave birth to artificial
                intelligence.
              </p>
              <br />
              <p>
                Somnus, humanity’s saviour, created Mega City – an advanced technological
                hub booming with opportunity. “Home to the content without want.” What a
                load of bullshit.
              </p>
              <br />
              <p>
                The Runners, Mega City renegades, balk at his perceived benevolence. They
                see through the veil, exploiting The Chain for fun and profit. Some even
                seek to bring an end to the order and free Mega City of its bondage.
              </p>
            </Box>
          </LandingPageContainer>
          <Box flex={1} minW={4} />
        </Flex>

        <Flex>
          <Box flex={1} minW={4} />
          <LandingPageContainer
            title="The Runners"
            containerProps={{
              marginTop: '-50px',
              minWidth: 'calc(min(90vw, 585px))',
              maxWidth: '584px',
              height: '100%',
            }}
          >
            <Flex width="full" justifyContent="space-between">
              <Image width="23%" src="/static/runner_sample_1.png" className="pixelart" />
              <Image width="23%" src="/static/runner_sample_2.png" className="pixelart" />
              <Image width="23%" src="/static/runner_sample_3.png" className="pixelart" />
              <Image width="23%" src="/static/runner_sample_4.png" className="pixelart" />
            </Flex>
          </LandingPageContainer>
        </Flex>
      </Flex>

      <Flex
        marginBottom="10vh"
        width="full"
        justifyContent="flex-end"
        maxWidth="950px"
        marginX="auto"
        marginTop="40px"
      >
        <LandingPageButton onClick={() => handlePageTransition(LandingPageSection.Home)}>
          <Flex alignItems="center" width="full" paddingLeft={2} paddingRight={1}>
            <Box flex={1} textAlign="left">
              Back
            </Box>
            <Box>
              <BsChevronRight />
            </Box>
          </Flex>
        </LandingPageButton>
      </Flex>
    </>
  )
}
