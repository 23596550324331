import { ScanLines } from '@chain-runners/ui'
import { Box, BoxProps, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'

export type LandingPageContainerProps = {
  containerProps?: Partial<BoxProps>
  scanLineCount?: number
  title: string
}

export const LandingPageContainer: React.FC<LandingPageContainerProps> = ({
  children,
  title,
  scanLineCount,
  containerProps,
}) => {
  return (
    <Flex
      bgColor="#0D2331"
      padding="6px"
      position="relative"
      direction="column"
      {...containerProps}
    >
      <Box
        position="absolute"
        bgColor="#0D2331"
        height="55px"
        width="calc(min(70vw, 300px))"
        top="-26px"
        left="0"
        paddingTop="6px"
        paddingLeft="6px"
        borderTopRightRadius="7px"
      >
        <Box
          width="full"
          height="full"
          borderColor="#32688B"
          borderTopWidth="2px"
          borderLeftWidth="2px"
          bgColor="#1B4E71"
          position="relative"
        >
          <Image src="/static/container_texture.svg" />
          <Image
            src="/static/container_notch_only.svg"
            position="absolute"
            top="-6px"
            right="-25px"
          />
          <Flex
            position="absolute"
            top={0}
            bottom={0}
            left="18px"
            right={0}
            alignItems="center"
          >
            <Text
              fontFamily="MPSC"
              color="white"
              textTransform="uppercase"
              fontSize="18px"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {title}
            </Text>
          </Flex>
        </Box>
      </Box>
      <Flex
        flex={1}
        width="full"
        height="full"
        borderColor="#32688B"
        borderStyle="solid"
        borderWidth="2px"
        flexDirection="column"
      >
        <Box width="full" height="21px" bgColor="#1B4E71" />
        <Box
          width="full"
          flex={1}
          position="relative"
          padding="20px"
          color="rgba(0, 224, 255, 0.8)"
        >
          <ScanLines
            top="16px"
            borderColor="rgba(0, 153, 255, 0.1)"
            numLines={scanLineCount ?? 15}
          />
          {children}
        </Box>
      </Flex>
    </Flex>
  )
}
