import { usePrimaryColor } from '@chain-runners/ui'
import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { BsChevronLeft } from 'react-icons/all'
import { LandingPageButton } from '../LandingPageButton'
import { LandingPageContainer } from '../LandingPageContainer'
import { LandingPageSection } from '../types'

export type FAQProps = {
  handlePageTransition: (pageSection: LandingPageSection) => void
}

export const FAQ: React.FC<FAQProps> = ({ handlePageTransition }) => {
  const { primaryColor } = usePrimaryColor()

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flex={1}
        width="full"
        maxWidth="1280px"
        marginX="auto"
      >
        <Flex width="full">
          <Box flex={1} />

          <LandingPageContainer
            title="FAQ"
            scanLineCount={30}
            containerProps={{
              marginTop: '50px',
              minWidth: 'calc(min(90vw, 585px))',
              maxWidth: '584px',
              minHeight: '211px',
            }}
          >
            <Box>
              <Box fontWeight="bolder" color={primaryColor}>
                How many Chain Runners are there?
              </Box>
              <Box>10,000</Box>
              <br />
              <Box fontWeight="bolder" color={primaryColor}>
                How much did Chain Runners cost to mint?
              </Box>
              <Box>0.05Ξ per Runner. </Box>
              <br />
              <Box fontWeight="bolder" color={primaryColor}>
                Where are Chain Runners stored?
              </Box>
              <Box>All image data and attribute metadata is stored 100% on-chain.</Box>
              <br />
              <Box fontWeight="bolder" color={primaryColor}>
                How are Chain Runners generated?
              </Box>
              <Box>
                Traits are selected all on-chain at the time of mint. The image and trait
                information will be immediately revealed upon mint.
              </Box>
              <br />
              <Box fontWeight="bolder" color={primaryColor}>
                Can I still mint Chain Runners?
              </Box>
              <Box>We are all sold out but you can buy some on OpenSea.</Box>
              <br />
              <Box fontWeight="bolder" color={primaryColor}>
                What rights do I have to the artwork?
              </Box>
              <Box>You are free to do whatever you want with your Chain Runners.</Box>
              <br />
            </Box>
          </LandingPageContainer>
        </Flex>
      </Flex>

      <Flex
        marginBottom="10vh"
        width="full"
        justifyContent="flex-start"
        maxWidth="950px"
        marginX="auto"
        marginTop="40px"
      >
        <LandingPageButton onClick={() => handlePageTransition(LandingPageSection.Home)}>
          <Flex alignItems="center" width="full" paddingLeft={1} paddingRight={2}>
            <Box>
              <BsChevronLeft />
            </Box>
            <Box flex={1} textAlign="right">
              Back
            </Box>
          </Flex>
        </LandingPageButton>
      </Flex>
    </>
  )
}
