import {
  HackerButton,
  HackerIconButton,
  HackerModalSection,
  usePrimaryColor,
} from '@chain-runners/ui'
import { getImageUrlForToken, ImageFileType } from '@chain-runners/utils'
import {
  AspectRatio,
  Box,
  ButtonGroup,
  Flex,
  FlexProps,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { AiOutlineCaretDown, AiOutlineCaretUp, MdFileDownload } from 'react-icons/all'
import { TokenFilters } from '../../../../clients/api/generated'
import { useAuthentication } from '../../../../hooks/useAuthentication'
import { getOpenSeaUrl } from '../../../../utils/opensea'
import { RunnerWithTraitIds } from '../../../../utils/runners'
import { formatAddress } from '../../../../utils/wallet'
import { OpenSeaIcon } from '../../../icons/OpenSeaIcon'
import { ChainRunnerTraitDisplay } from '../../../runners/ChainRunnerTraitDisplay'
import { HiddenTraits } from '../../types'

export type RunnerDetailsOverviewSectionProps = {
  token: RunnerWithTraitIds
  setFilters: Dispatch<SetStateAction<TokenFilters>>
  hiddenTraits?: HiddenTraits
  containerProps?: Partial<FlexProps>
}

export const RunnerDetailsOverviewSection: React.FC<
  RunnerDetailsOverviewSectionProps
> = ({ token, hiddenTraits, containerProps, setFilters }) => {
  const { currentUser } = useAuthentication()
  const { primaryColor } = usePrimaryColor()
  const currentUserIsOwner = useMemo(() => {
    return Boolean(
      token && token.ownerAddress.toLowerCase() === currentUser?.address.toLowerCase(),
    )
  }, [token, currentUser])

  const ownedByText = useMemo(() => {
    if (!token) return ''
    if (currentUserIsOwner) {
      return 'you'
    }

    return formatAddress(token.ownerAddress)
  }, [token, currentUserIsOwner])

  const handleClickAddress = useCallback(() => {
    setFilters({
      ownedBy: token.ownerAddress,
    })
  }, [token, setFilters])

  return (
    <HackerModalSection
      title={`RUNNER #${token.id}`}
      extraTitleContent={
        <Link onClick={handleClickAddress}>
          <Box color="white">
            Owned by{' '}
            <Box as="span" color={primaryColor}>
              {ownedByText}
            </Box>
          </Box>
        </Link>
      }
      containerProps={containerProps}
    >
      <AspectRatio ratio={1} width="100%" mb={5}>
        <ChainRunnerTraitDisplay
          hiddenTraits={hiddenTraits}
          selectedTraits={token.traits}
        />
      </AspectRatio>

      <Link href={getOpenSeaUrl(token)} target="_blank" rel="noopener noreferrer">
        <HackerButton
          textAlign="left"
          alignItems="center"
          isFullWidth
          textTransform="initial"
          fontWeight="semibold"
          mb={3}
          fontSize="14px"
        >
          <Flex alignItems="center" width="full">
            <Box mr={2}>
              <OpenSeaIcon color={primaryColor} />
            </Box>
            <Box flex={1}>View on OpenSea</Box>
          </Flex>
        </HackerButton>
      </Link>
      <ButtonGroup isAttached w="full">
        <Link
          href={getImageUrlForToken(token.id, hiddenTraits)}
          target="_blank"
          rel="noopener noreferrer"
          flex={1}
        >
          <HackerButton
            isFullWidth
            textAlign="left"
            alignItems="center"
            textTransform="initial"
            fontWeight="semibold"
            fontSize="14px"
            mb={3}
            borderRightStyle="none"
          >
            <Flex alignItems="center" width="full">
              <Box mr={2}>
                <MdFileDownload size={20} />
              </Box>
              <Box flex={1}>Download Avatar</Box>
            </Flex>
          </HackerButton>
        </Link>
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={HackerIconButton}
                textTransform="initial"
                textAlign="center"
              >
                <Flex justifyContent="center">
                  {isOpen ? (
                    <AiOutlineCaretUp size={20} />
                  ) : (
                    <AiOutlineCaretDown size={20} />
                  )}
                </Flex>
              </MenuButton>
              <MenuList
                zIndex={10000}
                backgroundColor="black"
                borderRadius={0}
                borderColor={primaryColor}
                color={primaryColor}
              >
                <Link
                  href={getImageUrlForToken(token.id, hiddenTraits, ImageFileType.png)}
                  target="_blank"
                  rel="noopener noreferrer"
                  flex={1}
                >
                  <MenuItem icon={<MdFileDownload size={20} />}>Download as PNG</MenuItem>
                </Link>
                <Link
                  href={getImageUrlForToken(token.id, hiddenTraits, ImageFileType.svg)}
                  target="_blank"
                  rel="noopener noreferrer"
                  flex={1}
                >
                  <MenuItem icon={<MdFileDownload size={20} />}>Download as SVG</MenuItem>
                </Link>
              </MenuList>
            </>
          )}
        </Menu>
      </ButtonGroup>
    </HackerModalSection>
  )
}
