import { MotionBox, useIsMobile } from '@chain-runners/ui'
import { Box, Flex } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import React, { RefObject, useMemo, useRef } from 'react'
import { ScrollDirection, useScroll } from '../../hooks/useScroll'
import { HACKER_RED_COLOR } from '../../theme'
import { LANDING_PAGE_HEADER_HEIGHT } from './constants'
import { LandingPageDetailsPane } from './LandingPageDetailsPane'
import { LandingPageSectionTransitionWrapper } from './LandingPageSectionTransitionWrapper'
import { LandingPageSectionDetailsConfig } from './types'

export type LandingPageSectionWithDetailsProps = {
  title: string
  detailsConfig: Array<LandingPageSectionDetailsConfig>
}

function isElementShowing(
  element: HTMLDivElement,
  scrollPos: number,
  scrollDirection: ScrollDirection,
): boolean {
  const top = element.offsetTop
  const height = element.clientHeight
  const bottom = top + height

  if (scrollDirection === ScrollDirection.Down) {
    const topIsAboveScrollPos = top - height * 0.4 <= scrollPos
    const bottomIsBelowScrollPos = bottom > scrollPos

    return topIsAboveScrollPos && bottomIsBelowScrollPos
  } else {
    const topIsAboveScrollPos = top - height * 0.4 <= scrollPos
    const bottomIsBelowScrollPos = bottom - height * 0.4 > scrollPos

    return topIsAboveScrollPos && bottomIsBelowScrollPos
  }
}

export const LandingPageSectionWithDetails: React.FC<
  LandingPageSectionWithDetailsProps
> = ({ title, detailsConfig }) => {
  const isMobile = useIsMobile()
  const sectionRefs = useRef<Array<RefObject<HTMLDivElement | null>>>(
    detailsConfig.map(() => React.createRef()),
  )

  const { scrollPosition, scrollDirection } = useScroll()

  const activeDetailsSection: LandingPageSectionDetailsConfig = useMemo(() => {
    const sortedConfig =
      scrollDirection === ScrollDirection.Down
        ? detailsConfig.slice().reverse()
        : detailsConfig

    return (
      sortedConfig.find((config, index) => {
        const adjustedIndex =
          scrollDirection === ScrollDirection.Down
            ? sortedConfig.length - 1 - index
            : index
        const ref = sectionRefs.current[adjustedIndex].current

        if (!ref) return false
        return isElementShowing(ref, scrollPosition, scrollDirection)
      }) ?? detailsConfig[0]
    )
  }, [detailsConfig, scrollPosition, scrollDirection])

  return (
    <LandingPageSectionTransitionWrapper>
      {detailsConfig.map((config, index) => {
        const showing = activeDetailsSection === config

        return (
          <Box
            key={index}
            top={0}
            position="fixed"
            bgColor="black"
            minH="100vh"
            width="100vw"
            opacity={showing ? 1 : 0}
            transition="opacity 0.2s ease"
            backgroundImage={config.backgroundImage}
            backgroundPosition="top right"
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            {...config.imageProps}
          />
        )
      })}

      <Flex
        position="fixed"
        top={`${LANDING_PAGE_HEADER_HEIGHT}px`}
        bottom={0}
        left="55px"
        width="16px"
        direction="column"
        justifyContent="center"
        display={isMobile ? 'none' : 'flex'}
        zIndex={5}
      >
        <Flex direction="column" flex={1} maxH="65vh">
          {detailsConfig.map((config, index) => {
            const showing = activeDetailsSection === config

            return (
              <Flex
                key={index}
                flex={1}
                cursor="pointer"
                mb={3}
                _last={{ mb: 0 }}
                px="6px"
                direction="column"
                onClick={() => {
                  sectionRefs.current[index].current?.scrollIntoView({
                    behavior: 'smooth',
                  })
                }}
                opacity={showing ? 1 : 0.5}
                _hover={{ opacity: 0.8 }}
              >
                <Box
                  bgColor={showing ? HACKER_RED_COLOR : 'white'}
                  flex={1}
                  width="full"
                />
              </Flex>
            )
          })}
        </Flex>
      </Flex>

      <AnimatePresence>
        <MotionBox
          initial={{ opacity: 0, translateY: '-1%' }}
          animate={{
            opacity: 1,
            translateY: '0%',
            transition: { duration: 0.3, delay: 0.1 },
          }}
          paddingLeft={isMobile ? 0 : '100px'}
          w="full"
        >
          {detailsConfig.map((config, index) => {
            return (
              <LandingPageDetailsPane
                ref={sectionRefs.current[index]}
                key={index}
                pageTitle={config.subTitle ?? title}
                titleContent={config.titleHeaderContent}
                sectionTitle={config.title}
                titleContainerProps={config.titleContainerProps}
                contentContainerProps={config.contentContainerProps}
                secondaryContent={config.secondaryContent}
                secondaryContentContainerProps={config.secondaryContentContainerProps}
                containerProps={config.containerProps}
              >
                {config.content}
              </LandingPageDetailsPane>
            )
          })}
        </MotionBox>
      </AnimatePresence>
    </LandingPageSectionTransitionWrapper>
  )
}
