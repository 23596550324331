import { RunnerTrait } from '@chain-runners/types'
import {
  MotionBox,
  MotionFlex,
  TerminalCommand,
  TerminalMessageType,
  usePersistedState,
  usePrimaryColor,
  useTerminal,
  useTerminalNetwork,
} from '@chain-runners/ui'
import { Flex, useDisclosure } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { HUB_APPLICATIONS, HubHomeApplication } from 'src/components/hub/config'
import { HubHeader } from 'src/components/hub/HubHeader'
import { HubLoadingPage } from 'src/components/hub/HubLoadingPage'
import { HubNavigationBar } from 'src/components/hub/HubNavigationBar'
import { HubTerminalDrawer } from 'src/components/hub/HubTerminalDrawer'
import { LOCAL_FILE_SYSTEM, localBootSequence } from 'src/components/hub/networks/local'
import {
  SOMNET_FILE_SYSTEM,
  SOMNET_NETWORK_NAME,
  somnetBootSequence,
} from 'src/components/hub/networks/somnet'
import {
  FormatAppLinkOptions,
  HandleNavigateOptions,
  HubApplication,
  HubApplicationRoute,
  HubBreadcrumbItem,
} from 'src/components/hub/types'
import { useAuthentication } from 'src/hooks/useAuthentication'
import { useTraits } from 'src/hooks/useTraits'
import { TraitType } from '../../clients/api/generated'
import { formatAddress } from '../../utils/wallet'
import { buildQueryParamsFromRunnerFilters } from './applications/RunnerDatabase'
import { buildQueryParamsFromTraitFilters } from './applications/TraitExplorer'

export type HubProps = {
  rootMountPath?: string
  onExit?: () => void
  showLoadingScreen?: boolean
  hideTerminal?: boolean
  hideHeader?: boolean
  rootApp?: HubApplication
}

function normalizeSearchString(value: string): string {
  return value.replaceAll(' ', '').trim().toLowerCase()
}

export const Hub: React.FC<HubProps> = ({
  rootMountPath = '/hub',
  onExit,
  showLoadingScreen = true,
  hideTerminal,
  hideHeader,
  rootApp = HubHomeApplication,
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { currentUser } = useAuthentication()

  const [isFirstTimeViewing, setIsFirstTimeViewing] = usePersistedState<boolean>(
    true,
    'first-time-hub-viewing-experience',
  )

  const [applicationBreadcrumbs, setApplicationBreadcrumbs] = useState<
    Array<HubBreadcrumbItem>
  >([])

  const { data: traitData } = useTraits()
  const { traits, traitsById } = traitData
  const [isTerminalOpen, setIsTerminalOpen] = useState(!showLoadingScreen)

  const toggleTerminalOpen = useCallback(() => {
    setIsTerminalOpen((open) => !open)
  }, [setIsTerminalOpen])

  const {
    isOpen: isLoadingPageDone,
    onOpen: onLoadingPageDone,
    onClose: onResetLoadingPage,
  } = useDisclosure({ defaultIsOpen: !showLoadingScreen })

  const {
    isOpen: showMainContent,
    onOpen: onShowMainContent,
    onClose: onResetShowMainContent,
  } = useDisclosure({ defaultIsOpen: !showLoadingScreen })

  const resetState = useCallback(() => {
    if (showLoadingScreen) {
      onResetLoadingPage()
      onResetShowMainContent()
    }
    setIsTerminalOpen(false)
  }, [showLoadingScreen, onResetLoadingPage, onResetShowMainContent])

  const activeApplication = useMemo(() => {
    return (
      HUB_APPLICATIONS.find((app) => {
        return location.pathname.startsWith(`${rootMountPath}${app.route}`)
      }) ?? rootApp
    )
  }, [location, rootMountPath, rootApp])

  const formatAppLink = useCallback(
    (route: HubApplicationRoute, options: FormatAppLinkOptions = {}): string => {
      const { queryParams, extraRouteSegments } = options

      const params = queryParams?.toString()
      return `${rootMountPath}${route}${extraRouteSegments ?? ''}${
        params ? `?${params}` : ''
      }`
    },
    [rootMountPath],
  )

  const handleNavigate = useCallback(
    (route: HubApplicationRoute, options: HandleNavigateOptions = {}) => {
      setApplicationBreadcrumbs([])
      const { queryParams, replace, extraRouteSegments, state } = options
      navigate(formatAppLink(route, { queryParams, extraRouteSegments }), {
        replace,
        state,
      })
    },
    [formatAppLink, navigate],
  )

  const { primaryColor } = usePrimaryColor()

  const somnetTerminalPrompt = useMemo(() => {
    return currentUser?.address ? `${formatAddress(currentUser.address)}@somnet $` : '$'
  }, [currentUser])

  const somnetTerminal = useTerminal({
    fileSystem: SOMNET_FILE_SYSTEM,
    bootSequence: somnetBootSequence,
    prompt: somnetTerminalPrompt,
  })

  const localTerminalPrompt = useMemo(() => {
    return currentUser?.address ? `${formatAddress(currentUser.address)} $` : '$'
  }, [currentUser])

  const runnerCommand = useCallback(
    async ({ sendToStdout }, args) => {
      const filterValue = args[0]
      if (!filterValue) {
        handleNavigate(HubApplicationRoute.RunnerDatabase)
        return
      }

      if (filterValue.length === 42 && filterValue.toLowerCase().startsWith('0x')) {
        handleNavigate(HubApplicationRoute.RunnerDatabase, {
          queryParams: buildQueryParamsFromRunnerFilters({ ownedBy: filterValue }),
        })
        return
      }

      const tokenId = parseInt(filterValue)
      if (!isNaN(tokenId) && tokenId > 0 && tokenId <= 10_000) {
        handleNavigate(HubApplicationRoute.RunnerDatabase, {
          extraRouteSegments: `/${tokenId}`,
        })
        return
      }

      const filterText = args.join(' ')
      const normalizedFilterText = normalizeSearchString(filterText)
      const trait = traits.find(
        (t) => normalizeSearchString(t.displayName) === normalizedFilterText,
      )
      if (trait) {
        handleNavigate(HubApplicationRoute.RunnerDatabase, {
          queryParams: buildQueryParamsFromRunnerFilters({
            traits: [
              {
                traitType: trait.type,
                values: [trait.displayName],
              },
            ],
          }),
        })
        return
      }

      sendToStdout({
        message: `Invalid parameter: ${filterText}`,
        type: TerminalMessageType.Error,
      })
      return
    },
    [handleNavigate, traits],
  )

  const traitCommand = useCallback(
    async ({ sendToStdout }, args) => {
      const filterValue = args[0]
      const filterText = args.join(' ')
      if (!filterValue) {
        handleNavigate(HubApplicationRoute.TraitExplorer)
        return
      }

      const traitId = parseInt(filterValue)
      let trait: RunnerTrait | undefined = traitsById[traitId]
      if (trait) {
        handleNavigate(HubApplicationRoute.TraitExplorer, {
          extraRouteSegments: `/${trait.id}`,
        })
        return
      }

      const traitType = Object.values(TraitType).find(
        (traitType) =>
          traitType.toLowerCase() === filterText.replaceAll(' ', '').toLowerCase(),
      )

      if (traitType) {
        handleNavigate(HubApplicationRoute.TraitExplorer, {
          queryParams: buildQueryParamsFromTraitFilters({
            traitTypes: [traitType],
          }),
        })
        return
      }

      trait = traits.find(
        (trait) =>
          trait.displayName.toLowerCase().replaceAll(' ', '') ===
          filterText.toLowerCase().replaceAll(' ', ''),
      )
      if (trait) {
        handleNavigate(HubApplicationRoute.TraitExplorer, {
          extraRouteSegments: `/${trait.id}`,
        })
        return
      }

      sendToStdout({
        message: `Invalid parameter: ${filterValue}`,
        type: TerminalMessageType.Error,
      })
    },
    [traitsById, traits, handleNavigate],
  )

  const customAppCommands = useMemo((): Array<TerminalCommand> => {
    return [
      {
        name: 'runners',
        helpText:
          'runners - View Runners -runners <id> | runners <address> | runners <trait name>',
        func: runnerCommand,
      },
      {
        name: 'runner',
        helpText:
          'runner - View Runners -runner <id> | runner <address> | runner <trait name>',
        func: runnerCommand,
      },
      {
        name: 'traits',
        helpText:
          'traits - View Traits - traits <id> | traits <name> | traits <trait type>',
        func: traitCommand,
      },
      {
        name: 'trait',
        helpText: 'trait - View Traits - trait <id> | trait <name> | trait <trait type>',
        func: traitCommand,
      },
    ]
  }, [runnerCommand, traitCommand])

  const localTerminal = useTerminal({
    fileSystem: LOCAL_FILE_SYSTEM,
    customCommands: customAppCommands,
    prompt: localTerminalPrompt,
    bootSequence: localBootSequence,
  })

  const { activeNetwork, availableNetworks } = useTerminalNetwork({
    localTerminal,
    connectedNetworks: [
      {
        name: SOMNET_NETWORK_NAME,
        terminalData: somnetTerminal,
      },
    ],
  })

  const combinedIsLoading = useMemo(() => !isLoadingPageDone, [isLoadingPageDone])

  useEffect(() => {
    if (!currentUser) {
      resetState()
    }
  }, [currentUser, resetState])

  const handleLoadingPageDone = useCallback(() => {
    onLoadingPageDone()
    setTimeout(() => {
      if (isFirstTimeViewing) {
        setIsTerminalOpen(true)
      }
      localTerminal.initializeTerminal()
    }, 0)
    setTimeout(() => {
      onShowMainContent()
      setIsFirstTimeViewing(false)
    }, 300)
  }, [
    onLoadingPageDone,
    isFirstTimeViewing,
    localTerminal,
    onShowMainContent,
    setIsFirstTimeViewing,
  ])

  return (
    <Flex
      flex={1}
      color={primaryColor}
      direction="column"
      bgColor="black"
      width="full"
      height="full"
    >
      <AnimatePresence>
        {!hideHeader && !combinedIsLoading && (
          <MotionBox
            initial={{
              translateY: '-110%',
            }}
            animate={{
              translateY: 0,
              transition: {
                ease: 'easeIn',
                duration: 0.2,
              },
            }}
            zIndex={501}
          >
            <HubHeader onExit={onExit} rootMountPath={rootMountPath} />
            <HubNavigationBar
              onNavigate={handleNavigate}
              activeApplication={activeApplication}
              applicationBreadcrumbs={applicationBreadcrumbs}
            />
          </MotionBox>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showLoadingScreen && (combinedIsLoading || !showMainContent) ? (
          <HubLoadingPage
            loadDurationInSeconds={isFirstTimeViewing ? 2 : 0}
            onDone={handleLoadingPageDone}
            containerProps={{ marginTop: combinedIsLoading ? '60px' : 0 }}
          />
        ) : (
          <MotionFlex
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                ease: 'easeIn',
                duration: 0.5,
              },
            }}
            direction="column"
            position="relative"
            flex={1}
            w="full"
            overflowY="hidden"
            maxH="full"
          >
            {React.createElement(activeApplication.component, {
              setApplicationBreadcrumbs,
              handleNavigate,
              formatAppLink,
              application: activeApplication,
              ...traitData,
              rootMountPath,
            })}
          </MotionFlex>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!hideTerminal && !combinedIsLoading && (
          <MotionBox
            initial={{
              opacity: 0,
            }}
            bgColor="black"
            animate={{
              opacity: 1,
              transition: {
                ease: 'easeIn',
                duration: 0.1,
              },
            }}
          >
            <HubTerminalDrawer
              terminalData={activeNetwork.terminalData}
              isOpen={isTerminalOpen}
              toggleOpen={toggleTerminalOpen}
              activeNetwork={activeNetwork}
              availableNetworks={availableNetworks}
            />
          </MotionBox>
        )}
      </AnimatePresence>
    </Flex>
  )
}
