import { RunnerTrait } from '@chain-runners/types'
import { Token } from '../clients/api/generated'

export type BaseToken = {
  id: number
}

export type RunnerWithTraitIds = {
  id: number
  ownerAddress: string
  traitIds: Array<number>
  traits: Array<RunnerTrait>
}

export type RawTokenResponse = Omit<
  Token,
  'attributes' | 'svg' | 'traits' | 'rawMetadata'
>

export function parseRawTokenResponse(
  token: RawTokenResponse,
  traitsById: Record<number, RunnerTrait>,
): RunnerWithTraitIds {
  return {
    id: token.id,
    ownerAddress: token.ownerAddress,
    traitIds: token.traitIds,
    traits: token.traitIds.map((traitId) => traitsById[traitId]),
  }
}
