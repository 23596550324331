import { RunnerTrait } from '@chain-runners/types'
import { usePrimaryColor } from '@chain-runners/ui'
import { getImageUrlForTrait, ImageFileType } from '@chain-runners/utils'
import { Box, BoxProps, Flex, Image } from '@chakra-ui/react'
import React from 'react'
import { TraitType } from 'src/clients/api/generated'
import { usePlaceholderRunner } from 'src/hooks/usePlaceholderRunner'

export type TraitPreviewDisplayProps = {
  trait: RunnerTrait
  containerProps?: Partial<BoxProps>
}

export const TraitPreviewDisplay: React.FC<TraitPreviewDisplayProps> = ({
  trait,
  containerProps,
}) => {
  const { primaryColor } = usePrimaryColor()
  const placeholderRunner = usePlaceholderRunner({
    fillColor: trait.type === TraitType.Background ? undefined : 'rgba(0, 0, 0, 0.3)',
  })

  return (
    <Box
      position="relative"
      borderColor={primaryColor}
      borderWidth="1px"
      bgColor="whiteAlpha.500"
      {...containerProps}
    >
      <Flex>
        <Image
          src={placeholderRunner}
          marginLeft="0.3px"
          opacity={trait.type === TraitType.Race ? 0 : 1}
          width="full"
          height="full"
          zIndex={trait.type === TraitType.Background ? 3 : undefined}
        />
      </Flex>
      <Image
        position="absolute"
        top={0}
        bottom={0}
        right={0}
        left={0}
        width="full"
        height="full"
        className="pixelart"
        src={getImageUrlForTrait(trait.id, ImageFileType.png)}
        alt={trait.displayName}
      />
    </Box>
  )
}
