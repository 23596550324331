import { DateTime, Duration } from 'luxon'
import { useEffect, useMemo, useState } from 'react'

export type UseCountdownConfig = {
  to: DateTime
  forceReady?: boolean
  refreshIntervalMS?: number
}

export type UseCountDownValue = {
  remaining: Duration | null
}

export function useCountdown({
  refreshIntervalMS = 1000,
  forceReady,
  to,
}: UseCountdownConfig): UseCountDownValue {
  const [compareDate, setCompareDate] = useState(DateTime.now())

  useEffect(() => {
    if (forceReady) return

    const interval = setInterval(() => {
      setCompareDate(DateTime.now)
    }, refreshIntervalMS)
    return () => {
      clearInterval(interval)
    }
  }, [forceReady, refreshIntervalMS])

  const remaining = useMemo((): Duration | null => {
    const durationRemaining = to.diff(compareDate)
    if (forceReady || durationRemaining.as('seconds') < 0) {
      return null
    }

    return durationRemaining
  }, [to, forceReady, compareDate])

  return {
    remaining,
  }
}
