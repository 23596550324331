import { HackerTooltip, NetworkedTerminal } from '@chain-runners/ui'
import { Box, Flex } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { BsGlobe } from 'react-icons/all'

export type TerminalDrawerNetworkTabProps = {
  activeNetwork: NetworkedTerminal
  availableNetworks: Array<NetworkedTerminal>
}

export const TerminalDrawerNetworkTab: React.FC<TerminalDrawerNetworkTabProps> = ({
  activeNetwork,
  availableNetworks,
}) => {
  const [connectingNetwork, setConnectingNetwork] = useState<string | null>(null)

  const createSetNetworkHandler = useCallback(
    (networkName: string) => {
      return async () => {
        const newNetwork = activeNetwork.name === networkName ? null : networkName
        setConnectingNetwork(newNetwork)
        await activeNetwork.terminalData.executeCommand(
          newNetwork ? `ssh ${newNetwork}` : 'logout',
        )
        setConnectingNetwork(null)
      }
    },
    [activeNetwork.name, activeNetwork.terminalData],
  )

  return (
    <Box pt={6} pl={6} fontSize="14px">
      <Box color="white" fontWeight="semibold" mb={3}>
        Known networks
      </Box>
      {availableNetworks.map((network) => {
        const isConnected = activeNetwork.name === network.name
        const isConnecting = connectingNetwork === network.name
        const status = isConnecting
          ? 'Connecting...'
          : isConnected
          ? 'Connected'
          : 'Available'

        return (
          <Flex
            key={network.name}
            alignItems="center"
            fontFamily="mono"
            onClick={createSetNetworkHandler(network.name)}
            cursor="pointer"
          >
            <Box color={isConnected ? undefined : 'whiteAlpha.400'} as="span">
              <BsGlobe />
            </Box>
            <Box color="white" as="span" mx={2}>
              somnet
            </Box>
            <HackerTooltip
              zIndex={99}
              key={network.name}
              label={isConnected ? 'Click to Disconnect' : 'Click to Connect'}
              isDisabled={isConnecting}
            >
              <Box as="span" color={isConnected ? undefined : 'whiteAlpha.400'}>
                {status}
              </Box>
            </HackerTooltip>
          </Flex>
        )
      })}
    </Box>
  )
}
