import { usePrimaryColor } from '@chain-runners/ui'
import { Box, Flex, MenuDivider, MenuItem } from '@chakra-ui/react'
import React from 'react'
import { BsGlobe } from 'react-icons/all'
import { Link } from 'react-router-dom'
import { ConnectWalletButton } from '../ConnectWalletButton'
import { PrimaryColorPicker } from '../PrimaryColorPicker'

export type HubHeaderProps = {
  rootMountPath: string
  onExit?: () => void
}

export const HubHeader: React.FC<HubHeaderProps> = ({ onExit, rootMountPath }) => {
  const { primaryColor } = usePrimaryColor()

  return (
    <Flex
      alignItems="center"
      w="full"
      h="36px"
      minH="36px"
      borderBottomWidth="1px"
      borderBottomColor={primaryColor}
      fontSize="12px"
    >
      <Flex alignItems="center" px={4}>
        <Link to={rootMountPath}>
          <Flex alignItems="center" _hover={{ textDecoration: 'underline' }}>
            <Box as="span" color="white" mr={2}>
              <BsGlobe />
            </Box>
            <Box>RUNNERHUB</Box>
          </Flex>
        </Link>
        <Box ml={5}>V0</Box>
      </Flex>
      <Flex flex={1} justifyContent="flex-end" alignItems="center">
        <PrimaryColorPicker
          buttonProps={{
            variant: 'unstyled',
            size: 'xs',
            height: '36px',
          }}
        />
        <ConnectWalletButton
          buttonProps={{
            variant: 'unstyled',
            size: 'xs',
            height: '36px',
          }}
        >
          {onExit && (
            <>
              <MenuItem onClick={onExit}>Exit Hub</MenuItem>
              <MenuDivider />
            </>
          )}
        </ConnectWalletButton>
      </Flex>
    </Flex>
  )
}
