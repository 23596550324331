import { MotionFlex } from '@chain-runners/ui'
import { Box } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import React from 'react'

export type VideoOverlayProps = {
  isOpen: boolean
  onClose: () => void
}

export const VideoOverlay: React.FC<VideoOverlayProps> = ({ isOpen, onClose }) => {
  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <MotionFlex
            left="5%"
            width="90%"
            top="calc(50vh - min(25.3125vw, 40vh))"
            position="fixed"
            zIndex={1000}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                ease: 'easeIn',
                duration: 0.2,
              },
            }}
          >
            <iframe
              src="https://player.vimeo.com/video/710771436?autoplay=1&h=f53bb611fa&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              title="Announcement_01.2.mp4"
              style={{
                width: '100%',
                height: '50.625vw',
                maxHeight: '80vh',
                backgroundColor: 'black',
              }}
            />
          </MotionFlex>
        )}
      </AnimatePresence>
      <Box
        position="fixed"
        top={0}
        bottom={0}
        height={isOpen ? undefined : 0}
        left={0}
        right={0}
        bgColor={isOpen ? 'blackAlpha.800' : 'transparent'}
        transition="background-color 0.2s"
        zIndex={999}
        onClick={onClose}
      />
    </>
  )
}
