import { NETWORK_CONFIG } from '../config/network'
import { getNetworkConfig } from './network'

export type TokenLike = {
  id: number | string
}

export function getOpenSeaUrl<T extends TokenLike>(token: T | undefined): string {
  if (!token) return ''

  const {
    openSeaBaseUrl,
    contractConfig: { mainContractAddress },
  } = getNetworkConfig()
  const baseUrl = openSeaBaseUrl ?? NETWORK_CONFIG.mainnet.openSeaBaseUrl ?? ''

  return `${baseUrl}/assets/${mainContractAddress}/${token.id}`
}
