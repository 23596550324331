import { HackerUIProvider } from '@chain-runners/ui'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AuthenticationProvider } from './hooks/useAuthentication'
import { BackupWalletProvider } from './hooks/useBackupWalletProvider'
import { WalletProvider } from './hooks/useWallet'
import { AppRoutes } from './routes'
import { defaultHackerUITheme } from './theme'

export const App: React.FC = () => {
  return (
    <HackerUIProvider theme={defaultHackerUITheme}>
      <BrowserRouter>
        <BackupWalletProvider>
          <WalletProvider>
            <AuthenticationProvider>
              <AppRoutes />
            </AuthenticationProvider>
          </WalletProvider>
        </BackupWalletProvider>
      </BrowserRouter>
    </HackerUIProvider>
  )
}
