import { MotionFlex, STANDARD_TRANSITION_CONFIG } from '@chain-runners/ui'
import { FlexProps } from '@chakra-ui/react'
import { TargetAndTransition } from 'framer-motion/types/types'
import React, { useMemo } from 'react'
import { LandingPageSection } from './types'

export type LandingPageHomeSectionProps = {
  activeSection: LandingPageSection
  containerProps?: Partial<FlexProps>
}

const HOME_SECTION_ANIMATION_VARIANTS: Record<LandingPageSection, TargetAndTransition> = {
  [LandingPageSection.Home]: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: STANDARD_TRANSITION_CONFIG,
  },
  [LandingPageSection.Hub]: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: STANDARD_TRANSITION_CONFIG,
  },
  [LandingPageSection.Left]: {
    opacity: 0,
    x: '74vh',
    transition: STANDARD_TRANSITION_CONFIG,
  },
  [LandingPageSection.Right]: {
    opacity: 0,
    x: '-74vh',
    transition: STANDARD_TRANSITION_CONFIG,
  },
  [LandingPageSection.Up]: {
    opacity: 0,
    y: '74vh',
    transition: STANDARD_TRANSITION_CONFIG,
  },
}

export const LandingPageHomeSection: React.FC<LandingPageHomeSectionProps> = ({
  activeSection,
  containerProps,
  children,
}) => {
  const isActive = useMemo(
    () => activeSection === LandingPageSection.Home,
    [activeSection],
  )

  return (
    <MotionFlex
      zIndex={1}
      initial={{
        opacity: 0,
      }}
      variants={HOME_SECTION_ANIMATION_VARIANTS}
      animate={activeSection}
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      pointerEvents={isActive ? 'initial' : 'none'}
      paddingY={3}
      paddingX={[3, null, 8]}
      {...containerProps}
    >
      {children}
    </MotionFlex>
  )
}
