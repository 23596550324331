import { usePrimaryColor } from '@chain-runners/ui'
import { useMemo } from 'react'
import { base64EncodeImageContent } from 'src/utils/metadata'

export type UsePlaceholderRunnerProps = {
  outlineColor?: string
  fillColor?: string
}

export function usePlaceholderRunner(props?: UsePlaceholderRunnerProps): string {
  const { primaryColor } = usePrimaryColor()

  const outlineColor = useMemo(
    () => props?.outlineColor ?? primaryColor,
    [primaryColor, props],
  )
  const fillColor = useMemo(() => props?.fillColor ?? 'black', [props])

  return useMemo(() => {
    return base64EncodeImageContent(`<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 6H13H14H15H16H17H18H19H20V7H21H22V8V9H23V10V11V12V13V14V15V16V17V18V19V20V21V22V23H22V24V25H21V26H20V27H19V28H18V29H17H16H15H14V28H13V27H12V26H11V25H10V24V23H9V22V21V20V19V18V17V16V15V14V13V12V11V10V9H10V8V7H11H12V6ZM12 27V28H13V29H14V30H15H16H17H18V29H19V28H20V27H21V28V29V30V31V32H20H19H18H17H16H15H14H13H12H11V31V30V29V28V27H12ZM8 16H7V17V18V19V20H8V19V18V17V16ZM24 16H25V17V18V19V20H24V19V18V17V16ZM10 29H9V30H8V31H7V32H8H9H10V31V30V29ZM22 29H23V30H24V31H25V32H24H23H22V31V30V29Z" fill="${fillColor}"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 5H13H14H15H16H17H18H19H20V6H19H18H17H16H15H14H13H12V5ZM10 7V6H11H12V7H11H10ZM9 9V8V7H10V8V9H9ZM9 23V22V21V20V19V18V17V16V15V14V13V12V11V10V9H8V10V11V12V13V14V15H7V16H6V17V18V19V20H7V21H8V22V23H9ZM10 25H9V24V23H10V24V25ZM12 27V26H11V25H10V26V27V28H9V29H8V30H7V31H6V32H7V31H8V30H9V29H10V30V31V32H11V31V30V29V28V27H12ZM13 28H12V27H13V28ZM14 29H13V28H14V29ZM18 29V30H17H16H15H14V29H15H16H17H18ZM19 28V29H18V28H19ZM20 27V28H19V27H20ZM22 25H21V26H20V27H21V28V29V30V31V32H22V31V30V29H23V30H24V31H25V32H26V31H25V30H24V29H23V28H22V27V26V25ZM23 23V24V25H22V24V23H23ZM23 9V10V11V12V13V14V15V16V17V18V19V20V21V22V23H24V22V21H25V20H26V19V18V17V16H25V15H24V14V13V12V11V10V9H23ZM22 7H23V8V9H22V8V7ZM22 7V6H21H20V7H21H22ZM25 16V17V18V19V20H24V19V18V17V16H25ZM7 20V19V18V17V16H8V17V18V19V20H7Z" fill="${outlineColor}"/>
</svg>`)
  }, [outlineColor, fillColor])
}
