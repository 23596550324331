import { usePrimaryColor } from '@chain-runners/ui'
import { Flex } from '@chakra-ui/react'
import React from 'react'
import { Hub } from 'src/components/hub/Hub'
import { RunnerDatabaseApplication } from '../../hub/applications'
import { LANDING_PAGE_HEADER_HEIGHT } from '../constants'
import { LandingPageSectionTransitionWrapper } from '../LandingPageSectionTransitionWrapper'

export const GalleryLandingPageHomeSection: React.FC = () => {
  const { primaryColor } = usePrimaryColor()
  return (
    <LandingPageSectionTransitionWrapper>
      <Flex h="100vh" overflowY="auto" pt={`${LANDING_PAGE_HEADER_HEIGHT}px`} flex={1}>
        <Flex w="full" borderTop="1px solid" borderTopColor={primaryColor}>
          <Hub
            rootMountPath="/genesis"
            showLoadingScreen={false}
            rootApp={RunnerDatabaseApplication}
            hideTerminal
            hideHeader
          />
        </Flex>
      </Flex>
    </LandingPageSectionTransitionWrapper>
  )
}
