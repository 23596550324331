import { alphaColor, usePrimaryColor } from '@chain-runners/ui'
import { Box, Flex, FlexProps, useBoolean } from '@chakra-ui/react'
import React, { forwardRef, useMemo } from 'react'

export type HubNavigationBarItemProps = {
  isFirst?: boolean
  containerProps?: Partial<FlexProps>
  innerContainerProps?: Partial<FlexProps>
  canInteract?: boolean
  onClick?: () => void
}

const HEIGHT = 24
const WIDTH = 29

export const HubNavigationBarItem: React.FC<HubNavigationBarItemProps> = forwardRef<
  HTMLDivElement,
  HubNavigationBarItemProps
>(
  (
    { onClick, children, isFirst, innerContainerProps, containerProps, canInteract },
    ref,
  ) => {
    const { primaryColor } = usePrimaryColor()

    const [isHovering, { on: onHoverStart, off: onHoverEnd }] = useBoolean()
    const [isClicking, { on: onClickStart, off: onClickEnd }] = useBoolean()

    const stateColor = useMemo(() => {
      if (!canInteract) return primaryColor
      if (isClicking) return alphaColor(primaryColor, 0.9)
      if (isHovering) return alphaColor(primaryColor, 0.7)
      return primaryColor
    }, [canInteract, isClicking, isHovering, primaryColor])

    return (
      <Flex
        ref={ref}
        onClick={onClick}
        onMouseEnter={onHoverStart}
        onMouseLeave={onHoverEnd}
        onMouseDown={onClickStart}
        onMouseUp={onClickEnd}
        userSelect="none"
        cursor={canInteract ? 'pointer' : undefined}
        {...containerProps}
      >
        <Flex
          fontSize="12px"
          paddingLeft={isFirst ? 4 : 2}
          bgColor={isFirst ? stateColor : 'black'}
          color={isFirst ? 'black' : stateColor}
          alignItems="center"
          paddingRight={8}
          {...innerContainerProps}
        >
          {children}
        </Flex>
        <Box>
          <svg
            width={WIDTH}
            height={HEIGHT}
            viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polyline
              points={`0 -1 ${WIDTH} ${HEIGHT} 0 ${WIDTH}`}
              fill={isFirst ? stateColor : 'black'}
            />
            <line
              stroke={stateColor}
              strokeLinecap="square"
              x1={0}
              y1={-1}
              x2={WIDTH}
              y2={HEIGHT}
            />
            <line
              stroke={stateColor}
              strokeWidth="1.6"
              strokeLinecap="square"
              x1={WIDTH}
              y1={HEIGHT - 0.2}
              x2={0}
              y2={HEIGHT - 0.2}
            />
          </svg>
        </Box>
      </Flex>
    )
  },
)
