import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  Box,
  BoxProps,
  Link,
} from '@chakra-ui/react'
import React from 'react'

export type QuestionProps = Partial<AccordionItemProps> & {
  question: string
  children: React.ReactNode
}

export const Question: React.FC<QuestionProps> = ({ question, children }) => {
  return (
    <AccordionItem borderColor="white" _first={{ borderTopWidth: 0 }}>
      <h2>
        <AccordionButton
          color="white"
          _focus={{ outline: 'none' }}
          _expanded={{
            borderBottomWidth: '1px',
            borderBottomColor: 'white',
          }}
          fontWeight="semibold"
          paddingY={3}
        >
          <Box flex="1" textAlign="left">
            {question}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4} fontSize="16px" color="whiteAlpha.800">
        {children}
      </AccordionPanel>
    </AccordionItem>
  )
}

export type XRFAQProps = Partial<BoxProps>

export const XRFAQ: React.FC<XRFAQProps> = ({ ...props }) => {
  return (
    <Box {...props}>
      <Box fontSize={['24px', '28px', '32px']} className="orbitron" fontWeight="900">
        FAQ
      </Box>
      <Accordion
        borderRightWidth="1px"
        borderLeftWidth="1px"
        borderColor="hackerGreen"
        allowToggle
        allowMultiple
        mt={3}
      >
        <Question question="When is the mint for Chain Runner XR?">
          XR is minting now. You can mint yours{' '}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://somnet.chainrunners.xyz/"
          >
            here.
          </Link>
        </Question>
        <Question question="Is there going to be a public mint option?">
          Drop details will be announced at a later date but there will be a public mint
          option available.
        </Question>
        <Question question="What does XR mean?">
          XR stands for{' '}
          <Link
            href="https://en.wikipedia.org/wiki/Extended_reality"
            _target="blank"
            rel="noopener noreferrer"
            color="hackerGreen"
          >
            extended reality
          </Link>
          .
        </Question>
        <Question question="Is this collection expansion?">
          Yes, a Chain Runner XR will count as citizenship in Mega City, granting you
          access to unique perks such as holder-only channels. However, there will
          continue to be unique benefits for holders of Genesis Chain Runners.
        </Question>
        <Question question="Where is the data for Chain Runners XR stored?">
          The DNA and trait data will be stored on-chain like Genesis Chain Runners. The
          art for the XR Runner PFP and model data will be stored off-chain on IPFS or
          equivalent.
        </Question>
      </Accordion>
    </Box>
  )
}
