import { MotionFlex } from '@chain-runners/ui'
import { Variant } from 'framer-motion'
import React, { useEffect, useState } from 'react'

export const TRANSITION_CONFIG = {
  ease: 'easeIn',
  duration: 0.5,
}

const INITIAL_ANIMATION_STATE: Variant = {
  opacity: 0,
  top: '50vh',
  bottom: '50vh',
  left: '50vw',
  right: '50vw',
  transition: TRANSITION_CONFIG,
}

const SHOWN_ANIMATION_STATE: Variant = {
  opacity: 1,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  transition: TRANSITION_CONFIG,
}

export type LandingPageFullscreenSectionProps = {
  isShowing: boolean
  initializeAsShowing?: boolean
  content: () => React.ReactElement
}

export const LandingPageFullscreenSection: React.FC<
  LandingPageFullscreenSectionProps
> = ({
  isShowing: externalIsShowing,
  initializeAsShowing: externalInitializeAsShowing,
  content,
}) => {
  const [initializeAsShowing, setInitializeAsShowing] = useState(
    externalInitializeAsShowing ?? false,
  )
  const [isShowing, setIsShowing] = useState(initializeAsShowing)

  useEffect(() => {
    if (externalIsShowing) {
      setTimeout(() => {
        setIsShowing(true)
      }, 500)
    } else {
      setIsShowing(false)
      setInitializeAsShowing(false)
    }
  }, [externalIsShowing])

  return (
    <MotionFlex
      zIndex={2}
      position="fixed"
      initial={initializeAsShowing ? SHOWN_ANIMATION_STATE : INITIAL_ANIMATION_STATE}
      exit={INITIAL_ANIMATION_STATE}
      animate={SHOWN_ANIMATION_STATE}
      alignItems="center"
      flexDirection="column"
      overflowY="auto"
      bgColor="black"
    >
      {isShowing && content()}
    </MotionFlex>
  )
}
