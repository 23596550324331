import {
  AnimatedHackerGrid,
  AnimatedHackerGridItem,
  HackerBox,
  HackerModal,
  LoadingContainer,
  NotchedCornerPosition,
  useIsMobile,
} from '@chain-runners/ui'
import { COLLAPSIBLE_SIDEBAR_CLOSED_WIDTH } from '@chain-runners/ui/dist/components/Sidebar'
import { isNotNullOrUndefined } from '@chain-runners/utils'
import { AspectRatio, Box, Flex, Spinner } from '@chakra-ui/react'
import '@fontsource/source-serif-pro/400.css'
import { debounce } from 'lodash-es'
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation } from 'react-router'
import { ChainRunnerDisplay } from 'src/components/runners/ChainRunnerDisplay'
import { useAuthentication } from 'src/hooks/useAuthentication'
import { TokenFilters, TokenTraitFilter, TraitType } from '../../../clients/api/generated'
import { parseRawTokenResponse, RunnerWithTraitIds } from '../../../utils/runners'
import { RunnerDetailsDisplay } from '../components/runner-details/RunnerDetailsDisplay'
import { RunnerActiveFilterItems } from '../RunnerActiveFilterItems'
import { RunnerFiltersSidebar } from '../RunnerFiltersSidebar'
import { BaseHubApplicationProps } from '../types'

export enum RunnerQueryParamsKey {
  OwnedBy = 'ownedBy',
  WithBioOnly = 'withBioOnly',
}

export function loadFiltersFromQueryParams(search: string): TokenFilters {
  const params = new URLSearchParams(search)

  const ownedBy = params.get(RunnerQueryParamsKey.OwnedBy)
  const withBioOnly =
    params.get(RunnerQueryParamsKey.WithBioOnly) === 'true' ? true : undefined
  const traits: Array<TokenTraitFilter> = Object.values(TraitType)
    .map((traitType: TraitType): TokenTraitFilter | null => {
      const values = params.getAll(traitType)
      return values.length
        ? {
            traitType,
            values,
          }
        : null
    })
    .filter(isNotNullOrUndefined)

  return {
    ownedBy,
    traits,
    withBioOnly,
  }
}

export function buildQueryParamsFromRunnerFilters(
  filters: TokenFilters,
): URLSearchParams {
  const params = new URLSearchParams()

  if (filters.ownedBy) {
    params.set(RunnerQueryParamsKey.OwnedBy, filters.ownedBy)
  }

  if (filters.withBioOnly) {
    params.set(RunnerQueryParamsKey.WithBioOnly, 'true')
  }

  filters?.traits?.forEach((trait) => {
    trait.values.forEach((filterValue) => {
      params.append(trait.traitType, filterValue)
    })
  })

  return params
}

function hashTokenFilters(filters: TokenFilters): string {
  return `${filters.ownedBy} | ${filters.traits
    ?.map((t) => `${t.traitType} -- ${t.values.join('')}`)
    ?.join()}`
}

export type RunnerDatabaseProps = BaseHubApplicationProps & {}

export const RunnerDatabase: React.FC<RunnerDatabaseProps> = (props) => {
  const isMobile = useIsMobile()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const runnerCountRef = useRef<number>(0)
  const isLoadingRef = useRef(false)
  const {
    application: { route },
    traitsById,
    traitsByType,
    handleNavigate,
    rootMountPath,
  } = props

  const location = useLocation()

  const [runners, setRunners] = useState<Array<RunnerWithTraitIds> | null>(null)
  const [runnerCount, setRunnerCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFiltersOpen, setIsFiltersOpen] = useState(!isMobile)
  const popupIsShown = useRef(false)

  const lastFiltersRef = useRef<string>('')
  const [selectedRunnerId, filters]: [number | null, TokenFilters] = useMemo(() => {
    const runnerId = parseInt(location.pathname.replace(`${rootMountPath}${route}/`, ''))
    const parsedRunnerId = Number.isNaN(runnerId) ? null : runnerId ?? null

    const newFilters = loadFiltersFromQueryParams(location.search)
    const filterHash = hashTokenFilters(newFilters)
    if (filterHash !== lastFiltersRef.current) {
      setTimeout(() => setRunners(null), 0)
    }
    lastFiltersRef.current = filterHash
    return [parsedRunnerId, newFilters]
  }, [rootMountPath, location, route])

  const { apiClient } = useAuthentication()

  const handleSelectedRunner = useCallback(
    (runnerId: number | null) => {
      const queryParams = buildQueryParamsFromRunnerFilters(filters)
      if (runnerId === null) {
        handleNavigate(route, { queryParams })
        return
      }
      handleNavigate(route, { extraRouteSegments: `/${runnerId}`, queryParams })
    },
    [filters, handleNavigate, route],
  )

  const handleCloseDetails = useCallback(() => {
    handleSelectedRunner(null)
  }, [handleSelectedRunner])

  const fetchCounter = useRef(0)
  const loadRunners = useCallback(
    async (filters: TokenFilters, offset?: number) => {
      const requestId = fetchCounter.current
      if (!offset) {
        setRunners(null)
      }

      try {
        setIsLoading(true)
        isLoadingRef.current = true

        const response = await apiClient.getRunners({
          options: {
            pagination: {
              limit: 25,
              offset,
            },
            filters,
          },
        })

        const tokens = response.tokens.records.map((record) =>
          parseRawTokenResponse(record, traitsById),
        )
        if (requestId !== fetchCounter.current) return

        setRunners((runners) => {
          runnerCountRef.current = (offset ?? 0) + tokens.length

          if (!offset) return tokens
          return [...(runners ?? []), ...tokens]
        })
        setRunnerCount(response.tokens.count)
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false)
        isLoadingRef.current = false
      }
    },
    [apiClient, traitsById],
  )

  const debouncedLoadRunners = useMemo(() => {
    return debounce(loadRunners, 300)
  }, [loadRunners])

  const handleSetFilters = useCallback(
    (setStateAction: SetStateAction<TokenFilters>) => {
      runnerCountRef.current = 0
      isLoadingRef.current = true

      const newFilters =
        typeof setStateAction === 'function' ? setStateAction(filters) : setStateAction

      handleNavigate(route, {
        queryParams: buildQueryParamsFromRunnerFilters(newFilters),
      })

      fetchCounter.current++
      debouncedLoadRunners(newFilters)
    },
    [route, handleNavigate, filters, debouncedLoadRunners],
  )

  const handleScroll = useCallback(async () => {
    const container = containerRef.current
    if (!runnerCountRef.current || runnerCountRef.current >= runnerCount) return
    if (!container || isLoadingRef.current) return

    const isAtBottom =
      container.scrollTop >= (container.scrollHeight - container.offsetHeight) * 0.8

    if (isAtBottom) {
      await loadRunners(filters, runnerCountRef.current)
    }
  }, [filters, loadRunners, runnerCount])

  useLayoutEffect(() => {
    const container = containerRef.current
    if (!container) return

    container.addEventListener('scroll', handleScroll)
    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    if (!isLoadingRef.current && runners === null) {
      loadRunners(filters)
    }
  }, [filters, loadRunners, runners])

  return (
    <Flex position="relative" width="full" height="full">
      <RunnerFiltersSidebar
        filters={filters}
        setFilters={handleSetFilters}
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        traitsByType={traitsByType}
      />
      <Flex
        flex={1}
        direction="column"
        height="full"
        width="full"
        overflowY="auto"
        ref={containerRef}
        ml={isMobile ? COLLAPSIBLE_SIDEBAR_CLOSED_WIDTH : 0}
        padding={4}
      >
        <RunnerActiveFilterItems filters={filters} setFilters={handleSetFilters} />
        <Box mb={4} fontSize="12px" hidden={isLoading}>
          {runnerCount} Runners
        </Box>
        <Flex justifyContent="center" direction="column" flex={1}>
          {isLoading && !runners ? (
            <Box textAlign="center">
              <Spinner size="xl" />
            </Box>
          ) : (
            <>
              <AnimatedHackerGrid>
                {runners?.map((runner) => {
                  return (
                    <AnimatedHackerGridItem
                      key={runner.id}
                      onClick={() => {
                        handleSelectedRunner(runner.id)
                      }}
                    >
                      <HackerBox
                        notchedCorner={NotchedCornerPosition.BottomRight}
                        notchedCornerSize={32}
                      >
                        <AspectRatio ratio={1}>
                          <ChainRunnerDisplay token={runner} />
                        </AspectRatio>

                        <Box pt={2} fontSize="20px">
                          Runner #{runner.id}
                        </Box>
                      </HackerBox>
                    </AnimatedHackerGridItem>
                  )
                })}
              </AnimatedHackerGrid>
              <Flex py={4}>
                <LoadingContainer loading={isLoading} spinnerSize="lg" />
              </Flex>
              <Box flex={1} />
            </>
          )}
          <HackerModal
            onExitComplete={() => {
              popupIsShown.current = false
            }}
            show={selectedRunnerId !== null}
            onClose={handleCloseDetails}
            layoutId={`runner-modal-${selectedRunnerId}`}
          >
            <RunnerDetailsDisplay
              runnerId={selectedRunnerId ?? 0}
              onClose={handleCloseDetails}
              setFilters={handleSetFilters}
              {...props}
            />
          </HackerModal>
        </Flex>
      </Flex>
    </Flex>
  )
}
