import { getImageUrlForToken } from '@chain-runners/utils'
import { Box, BoxProps, Image, ImageProps } from '@chakra-ui/react'
import React from 'react'
import { BaseToken } from 'src/utils/runners'

export type ChainRunnerDisplayProps = {
  token: BaseToken
  containerProps?: Partial<BoxProps>
  imageProps?: Partial<ImageProps>
}

export const ChainRunnerDisplay: React.FC<ChainRunnerDisplayProps> = ({
  token,
  containerProps,
  imageProps,
}) => {
  return (
    <Box position="relative" {...containerProps}>
      <Image
        width="full"
        height="full"
        className="pixelart"
        src={getImageUrlForToken(token.id)}
        alt="Runner"
        {...imageProps}
      />
    </Box>
  )
}
