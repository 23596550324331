import { RunnerTrait } from '@chain-runners/types'
import {
  ALL_TRAIT_ORDERING,
  getImageUrlForTrait,
  ImageFileType,
} from '@chain-runners/utils'
import { Box, BoxProps, Image } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { HiddenTraits } from '../hub/types'

export type ChainRunnerTraitDisplayProps = {
  selectedTraits: Array<RunnerTrait>
  hiddenTraits?: HiddenTraits
  containerProps?: Partial<BoxProps>
}

export const ChainRunnerTraitDisplay: React.FC<ChainRunnerTraitDisplayProps> = ({
  selectedTraits,
  hiddenTraits = {},
  containerProps,
}) => {
  const orderedTraits = useMemo(() => {
    return selectedTraits.sort(
      (a, b) => ALL_TRAIT_ORDERING.indexOf(a.type) - ALL_TRAIT_ORDERING.indexOf(b.type),
    )
  }, [selectedTraits])

  const bgIsHidden = useMemo(() => hiddenTraits?.Background, [hiddenTraits])

  return (
    <Box
      position="relative"
      bgColor={bgIsHidden ? 'whiteAlpha.500' : undefined}
      {...containerProps}
    >
      {orderedTraits.map((trait, idx) => {
        if (hiddenTraits[trait.type]) return
        if (idx === 0) {
          return (
            <Image
              key={trait.id}
              width="full"
              height="full"
              className="pixelart"
              src={getImageUrlForTrait(trait.id, ImageFileType.svg)}
              alt={trait.displayName}
            />
          )
        }
        return (
          <Image
            key={trait.id}
            position="absolute"
            width="full"
            height="full"
            top={0}
            bottom={0}
            left={0}
            right={0}
            className="pixelart"
            src={getImageUrlForTrait(trait.id, ImageFileType.svg)}
            alt={trait.displayName}
          />
        )
      })}
    </Box>
  )
}
