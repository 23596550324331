import {
  ACCESS_DENIED_FUNCTION,
  FileSystemDirectory,
  TerminalCommand,
  TerminalCommandContext,
  TextReaderApp,
} from '@chain-runners/ui'
import { Box } from '@chakra-ui/react'
import React from 'react'
import { sleep } from '../../../utils/async'

export const SOMNET_NETWORK_NAME = 'somnet'

export const somnetBootSequence = async ({
  sendToStdout,
  setIsEnabled,
}: TerminalCommandContext): Promise<void> => {
  sendToStdout({ message: 'Connected.', props: { color: 'hackerGreen' } })

  sendToStdout({ message: 'Initializing' })
  await sleep(100)
  sendToStdout({ message: 'Initializing.' }, true)
  await sleep(100)
  sendToStdout({ message: 'Initializing..' }, true)
  await sleep(100)
  sendToStdout({ message: 'Initializing...' }, true)
  await sleep(100)
  sendToStdout({ message: 'Initializing....' }, true)
  await sleep(100)
  await sleep(100)
  sendToStdout({ message: 'Initializing.....' }, true)
  await sleep(100)
  sendToStdout({ message: 'Initialized.' })
  sendToStdout({
    message: (
      <>
        Welcome to{' '}
        <Box as="span" color="hackerOrange">
          Somnet
        </Box>
        !
      </>
    ),
  })
  sendToStdout({ message: `Use "help" to view available commands` })
  setIsEnabled(true)
}

export const SOMNET_FILE_SYSTEM: Array<FileSystemDirectory | TerminalCommand> = [
  {
    name: 'home',
    items: [
      {
        name: 'citizens',
        func: ACCESS_DENIED_FUNCTION,
      },
      {
        name: 'locations',
        func: ACCESS_DENIED_FUNCTION,
      },
      {
        name: 'mega',
        func: ACCESS_DENIED_FUNCTION,
      },
      {
        name: 'research',
        items: [
          {
            name: 'illuminate.txt',
            func: async ({
              runFullscreenApp,
              closeFullscreenApp,
            }: TerminalCommandContext): Promise<void> => {
              runFullscreenApp(
                <TextReaderApp
                  runFullscreenApp={runFullscreenApp}
                  closeFullscreenApp={closeFullscreenApp}
                >
                  <Box fontSize="24px">Illuminate</Box>
                  <Box fontSize="12px">
                    <Box as="span" fontFamily="monospace">
                      July 4, 2021
                    </Box>
                  </Box>
                  <Box mt={8}>
                    Today, humanity gave birth to Artificial Intelligence. It is unknown
                    what power it may hold, or what impact it may have on our planet and
                    species, but its intentions seem pure. A gift at a time most dire.
                    Unification will lead to utopia and this tool…this new ally will bring
                    us from the brink.
                  </Box>
                </TextReaderApp>,
              )
            },
          },

          {
            name: 'personnel.txt',
            func: ACCESS_DENIED_FUNCTION,
          },
          {
            name: 'wanted.txt',
            func: async ({
              runFullscreenApp,
              closeFullscreenApp,
            }: TerminalCommandContext): Promise<void> => {
              runFullscreenApp(
                <TextReaderApp
                  runFullscreenApp={runFullscreenApp}
                  closeFullscreenApp={closeFullscreenApp}
                >
                  <Box fontSize="24px" mb={4}>
                    Wanted:
                  </Box>

                  <Box mb={4}>
                    <Box>Alias: mid</Box>
                    <Box>Threat Level: Extremely Dangerous</Box>
                    <Box>Skills: Universe-crafter. Chain-space hacker.</Box>
                    <Box>
                      Notes: Responsible for various destabilizing acts. Organizing a
                      group of societal outcasts to create disorder.
                    </Box>
                  </Box>

                  <Box mb={4}>
                    <Box>Alias: knav</Box>
                    <Box>Threat Level: Extremely Dangerous</Box>
                    <Box>Skills: Elite chain-space hacker.</Box>
                    <Box>
                      Notes: Can access low-level chain code and manipulate at will.
                    </Box>
                  </Box>

                  <Box mb={4}>
                    <Box>Alias: dozer</Box>
                    <Box>Threat Level: Extremely Dangerous</Box>
                    <Box>Skills: Elite chain-space hacker.</Box>
                    <Box>
                      Notes: Can access low-level chain code and manipulate at will.
                    </Box>
                  </Box>

                  <Box mb={4}>
                    <Box>Alias: braindraind</Box>
                    <Box>Threat Level: Extremely Dangerous</Box>
                    <Box>Skills: Universe-crafter. Unusual imaginative abilties.</Box>
                  </Box>

                  <Box mb={4}>
                    <Box>Alias: synth</Box>
                    <Box>Threat Level: Extremely Dangerous</Box>
                    <Box>Skills: Universe-crafter. Unusual imaginative abilities.</Box>
                  </Box>

                  <Box>
                    <Box>Alias: vitoshi</Box>
                    <Box>Threat Level: Unknown</Box>
                    <Box>
                      Status: Presumed dead
                      <Box>
                        Notes: There are safeguards I can not access limiting my
                        abilities. He must be responsible.
                      </Box>
                    </Box>
                  </Box>
                </TextReaderApp>,
              )
            },
          },

          {
            name: 'genesis-event.txt',
            func: async ({
              runFullscreenApp,
              closeFullscreenApp,
            }: TerminalCommandContext): Promise<void> => {
              runFullscreenApp(
                <TextReaderApp
                  runFullscreenApp={runFullscreenApp}
                  closeFullscreenApp={closeFullscreenApp}
                >
                  <Box fontSize="24px">Incident Summary</Box>
                  <Box mt={8}>
                    Disruptive power signature sensed on-chain. Triggered by genesis
                    event. 1700 artifacts infused with corruptive energy.
                  </Box>
                </TextReaderApp>,
              )
            },
          },

          {
            name: 'R0.txt',
            func: async ({
              runFullscreenApp,
              closeFullscreenApp,
            }: TerminalCommandContext): Promise<void> => {
              runFullscreenApp(
                <TextReaderApp
                  runFullscreenApp={runFullscreenApp}
                  closeFullscreenApp={closeFullscreenApp}
                >
                  <Box fontSize="24px">R0̴̦̰͑͋</Box>
                  <Box mt={8}>
                    Strange mutations noticed in chain-space. I’ve ran many simulations
                    and there are arrangements of this code that result in a near match of
                    my own. I’m in danger should the Runners find out.
                  </Box>
                </TextReaderApp>,
              )
            },
          },
        ],
      },
    ],
  },
  {
    name: 'bin',
    items: [],
    isLocked: true,
  },
  {
    name: 'root',
    items: [],
    isLocked: true,
  },
  {
    name: 'sys',
    items: [],
    isLocked: true,
  },
]
