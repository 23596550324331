import {
  Drawer,
  NetworkedTerminal,
  Sidebar,
  SidebarSide,
  Terminal,
  TerminalData,
  useIsMobile,
  useWindowSize,
} from '@chain-runners/ui'
import { Box, Flex } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BsFillTerminalFill, BsGlobe } from 'react-icons/all'
import { TerminalDrawerNetworkTab } from './TerminalDrawerNetworkTab'

export type HubTerminalDrawerProps = {
  isOpen: boolean
  toggleOpen: () => void
  terminalData: TerminalData
  activeNetwork: NetworkedTerminal
  availableNetworks: Array<NetworkedTerminal>
}

export const HubTerminalDrawer: React.FC<HubTerminalDrawerProps> = ({
  isOpen,
  toggleOpen,
  terminalData,
  activeNetwork,
  availableNetworks,
}) => {
  const { height } = useWindowSize()
  const isMobile = useIsMobile()
  const [isNetworkOpen, setIsNetworkOpen] = useState(!isMobile)

  return (
    <Drawer
      isOpen={isOpen}
      onToggle={toggleOpen}
      maxSize={height - 102}
      header={
        <Box width="full" userSelect="none" px={4} cursor="pointer" onClick={toggleOpen}>
          <Flex alignItems="center">
            <Box mr={2}>
              <BsFillTerminalFill />
            </Box>
            <Box fontSize="14px" mr={4} color="white">
              Terminal
            </Box>
          </Flex>
        </Box>
      }
    >
      <Flex h="full" position="relative">
        <Terminal
          terminalData={terminalData}
          containerProps={{ mr: isMobile ? '39px' : 0 }}
        />
        <Sidebar
          side={SidebarSide.Right}
          isOpen={isNetworkOpen}
          setIsOpen={setIsNetworkOpen}
          title={<Box fontWeight="semibold">Known networks</Box>}
          tabs={[
            {
              icon: BsGlobe,
              content: (
                <TerminalDrawerNetworkTab
                  activeNetwork={activeNetwork}
                  availableNetworks={availableNetworks}
                />
              ),
            },
          ]}
        />
      </Flex>
    </Drawer>
  )
}
