import { alphaColor } from '@chain-runners/ui'
import {
  Box,
  Flex,
  FlexProps,
  IconButton,
  Image,
  Link as ExternalLink,
  MenuItem,
  Stack,
  StackItem,
  StackProps,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useCallback, useMemo } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { MdOutlineClose } from 'react-icons/all'
import { useNavigate } from 'react-router-dom'
import { DISCORD_INVITE_LINK, TWITTER_HANDLE } from '../../config/base'
import { useWallet } from '../../hooks/useWallet'
import { HACKER_GREEN_COLOR } from '../../theme'
import { getNetworkConfig } from '../../utils/network'
import { ConnectWalletButton } from '../ConnectWalletButton'
import { LANDING_PAGE_SECTIONS } from './constants'
import { LandingPageSection } from './types'
import { getSectionURL } from './utils'

type MobileMenuItemProps = Partial<StackProps> & {
  isActive?: boolean
}

const MobileMenuItem: React.FC<MobileMenuItemProps> = ({
  children,
  isActive,
  ...props
}) => {
  return (
    <StackItem
      textTransform="uppercase"
      fontWeight="700"
      color={isActive ? 'hackerGreen' : undefined}
      px={3}
      py={3}
      fontSize="20px"
      borderBottom="1px solid white"
      width="full"
      {...props}
    >
      {children}
    </StackItem>
  )
}

export type MobileLandingPageHeaderProps = {
  activeSection: LandingPageSection
  mountPath: string
  containerProps?: Partial<FlexProps>
}

export const MobileLandingPageHeader: React.FC<MobileLandingPageHeaderProps> = ({
  mountPath,
  activeSection,
  containerProps,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const { address } = useWallet()

  const navigateAndClose = useCallback(
    (url: string) => {
      onClose()
      navigate(url)
    },
    [navigate, onClose],
  )

  const networkConfig = useMemo(() => getNetworkConfig(), [])

  const openseaUrl: string | undefined = useMemo(() => {
    const { openSeaBaseUrl, openSeaProjectSlug } = networkConfig
    return (
      openSeaBaseUrl &&
      openSeaProjectSlug &&
      `${openSeaBaseUrl}/collection/${openSeaProjectSlug}`
    )
  }, [networkConfig])

  const looksRareUrl: string | undefined = useMemo(() => {
    const {
      contractConfig: { mainContractAddress },
    } = networkConfig
    return (
      mainContractAddress && `https://looksrare.org/collections/${mainContractAddress}`
    )
  }, [networkConfig])

  return (
    <Flex
      px={4}
      py={4}
      alignItems="center"
      justifyContent="flex-end"
      zIndex={99}
      {...containerProps}
    >
      <Box
        onClick={() => {
          navigateAndClose(mountPath)
        }}
        position="fixed"
        top={4}
        left={4}
        _hover={{ opacity: 0.8 }}
        zIndex={5}
        width="100px"
      >
        <Image width="100px" src="/static/chain-runners-logo-white.png" />
      </Box>

      <Box>
        <IconButton
          aria-label="Open Menu"
          fontSize="24px"
          icon={<AiOutlineMenu />}
          variant="ghost"
          _focus={{ outline: 0, bgColor: 'transparent' }}
          _hover={{ bgColor: alphaColor(HACKER_GREEN_COLOR, 0.1) }}
          _active={{ bgColor: 'transparent' }}
          onClick={onOpen}
        />
      </Box>

      <Flex
        bgColor="blackAlpha.800"
        backdropFilter="blur(10px)"
        position="fixed"
        top={0}
        bottom={0}
        left={isOpen ? 0 : '100vh'}
        right={0}
        p={4}
        opacity={isOpen ? 1 : 0}
        transition="left 0.2s ease, opacity 0.3s ease"
        direction="column"
      >
        <Flex justifyContent="flex-end" width="full">
          <Box>
            <IconButton
              aria-label="Open Menu"
              fontSize="24px"
              icon={<MdOutlineClose />}
              variant="ghost"
              _focus={{ outline: 0, bgColor: 'transparent' }}
              _hover={{ bgColor: alphaColor(HACKER_GREEN_COLOR, 0.1) }}
              _active={{ bgColor: 'transparent' }}
              onClick={onClose}
            />
          </Box>
        </Flex>

        <Flex mt={8} direction="column">
          <Stack direction="column" spacing={0} width="full">
            {LANDING_PAGE_SECTIONS.map((landingPageSection) => {
              const sectionURL = getSectionURL(mountPath, landingPageSection)
              const isActive = activeSection === landingPageSection

              return (
                <MobileMenuItem
                  key={landingPageSection.name}
                  isActive={isActive}
                  onClick={() => {
                    navigateAndClose(sectionURL)
                  }}
                >
                  {landingPageSection.name}
                </MobileMenuItem>
              )
            })}
          </Stack>
          <ExternalLink
            href={`https://twitter.com/${TWITTER_HANDLE}`}
            target="_blank"
            rel="noopener noreferrer"
            _focus={{ outline: 'none' }}
          >
            <MobileMenuItem>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>Twitter</Box>
                <Box>
                  <Image src="/static/twitter_logo.svg" width="24px" height="24px" />
                </Box>
              </Flex>
            </MobileMenuItem>
          </ExternalLink>
          <ExternalLink
            href={DISCORD_INVITE_LINK}
            target="_blank"
            rel="noopener noreferrer"
            _focus={{ outline: 'none' }}
          >
            <MobileMenuItem>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>Discord</Box>
                <Box>
                  <Image src="/static/discord_logo.svg" width="24px" height="24px" />
                </Box>
              </Flex>
            </MobileMenuItem>
          </ExternalLink>
          <ExternalLink
            href={openseaUrl}
            target="_blank"
            rel="noopener noreferrer"
            _focus={{ outline: 'none' }}
          >
            <MobileMenuItem>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>OpenSea</Box>
                <Box>
                  <Image src="/static/opensea_logo.png" width="24px" height="24px" />
                </Box>
              </Flex>
            </MobileMenuItem>
          </ExternalLink>
          <ExternalLink
            href={looksRareUrl}
            target="_blank"
            rel="noopener noreferrer"
            _focus={{ outline: 'none' }}
          >
            <MobileMenuItem>
              <Flex justifyContent="space-between" alignItems="center">
                <Box>LooksRare</Box>
                <Box>
                  <Image src="/static/looks-rare-logo.svg" width="24px" height="24px" />
                </Box>
              </Flex>
            </MobileMenuItem>
          </ExternalLink>
        </Flex>
        <Flex
          flex={1}
          dir="column"
          alignItems="flex-end"
          width="full"
          justifyContent="flex-end"
        >
          <ConnectWalletButton buttonProps={{ isFullWidth: true }}>
            <MenuItem onClick={() => navigateAndClose('/hub')}>Hub</MenuItem>
            {address && (
              <MenuItem
                onClick={() =>
                  navigateAndClose(`/genesis/runners?ownedBy=${address?.toLowerCase()}`)
                }
              >
                My Runners
              </MenuItem>
            )}
            <MenuItem onClick={() => navigateAndClose('/account')}>Account</MenuItem>
          </ConnectWalletButton>
        </Flex>
      </Flex>
    </Flex>
  )
}
