import { RunnerTrait } from '@chain-runners/types'
import { HackerButton, HackerIconButton, usePrimaryColor } from '@chain-runners/ui'
import {
  formatTraitTypeLong,
  getImageUrlForTrait,
  ImageFileType,
} from '@chain-runners/utils'
import {
  Box,
  ButtonGroup,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import React, { useCallback } from 'react'
import {
  AiFillDatabase,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
  MdFileDownload,
} from 'react-icons/all'
import { TraitPreviewDisplay } from '../../runners/TraitPreviewDisplay'
import { buildQueryParamsFromRunnerFilters } from '../applications/RunnerDatabase'
import { HandleNavigateFunction, HubApplicationRoute } from '../types'

export type TraitDetailsDisplayProps = {
  trait: RunnerTrait
  handleNavigate: HandleNavigateFunction
}

export const TraitDetailsDisplay: React.FC<TraitDetailsDisplayProps> = ({
  trait,
  handleNavigate,
}) => {
  const { primaryColor } = usePrimaryColor()

  const handleViewRunnerWithTrait = useCallback(() => {
    handleNavigate(HubApplicationRoute.RunnerDatabase, {
      queryParams: buildQueryParamsFromRunnerFilters({
        traits: [
          {
            traitType: trait.type,
            values: [trait.displayName],
          },
        ],
      }),
    })
  }, [handleNavigate, trait.displayName, trait.type])

  return (
    <>
      <TraitPreviewDisplay trait={trait} />

      <Flex
        alignItems="center"
        fontSize="13px"
        fontWeight="semibold"
        userSelect="none"
        mt={3}
      >
        <Box
          w="112px"
          minW="112px"
          borderStyle="solid"
          borderWidth="1px"
          borderColor={primaryColor}
          px={3}
          py={2}
          bgColor={primaryColor}
          color="black"
          h="full"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          Type
        </Box>

        <Flex
          flex={1}
          px={3}
          py={2}
          borderStyle="solid"
          borderWidth="1px"
          borderColor={primaryColor}
          overflow="hidden"
          alignItems="center"
        >
          <Box
            flex={1}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            textAlign="right"
          >
            {formatTraitTypeLong(trait.type)}
          </Box>
        </Flex>
      </Flex>
      <HackerButton
        textAlign="left"
        alignItems="center"
        isFullWidth
        textTransform="initial"
        fontWeight="semibold"
        fontSize="14px"
        mt={3}
        onClick={handleViewRunnerWithTrait}
      >
        <Flex alignItems="center" width="full">
          <Box mr={2}>
            <AiFillDatabase size={20} />
          </Box>
          <Box flex={1}> View Runners With Trait</Box>
        </Flex>
      </HackerButton>
      <ButtonGroup isAttached w="full" mt={3}>
        <Link
          href={getImageUrlForTrait(trait.id)}
          target="_blank"
          rel="noopener noreferrer"
          flex={1}
        >
          <HackerButton
            isFullWidth
            textAlign="left"
            alignItems="center"
            textTransform="initial"
            fontWeight="semibold"
            fontSize="14px"
            mb={3}
            borderRightStyle="none"
          >
            <Flex alignItems="center" width="full">
              <Box mr={2}>
                <MdFileDownload size={20} />
              </Box>
              <Box flex={1}>Download Trait</Box>
            </Flex>
          </HackerButton>
        </Link>
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={HackerIconButton}
                textTransform="initial"
                textAlign="center"
              >
                <Flex justifyContent="center">
                  {isOpen ? (
                    <AiOutlineCaretUp size={20} />
                  ) : (
                    <AiOutlineCaretDown size={20} />
                  )}
                </Flex>
              </MenuButton>
              <MenuList
                zIndex={10000}
                backgroundColor="black"
                borderRadius={0}
                borderColor={primaryColor}
                color={primaryColor}
              >
                <Link
                  href={getImageUrlForTrait(trait.id, ImageFileType.png)}
                  target="_blank"
                  rel="noopener noreferrer"
                  flex={1}
                >
                  <MenuItem icon={<MdFileDownload size={20} />}>Download as PNG</MenuItem>
                </Link>
                <Link
                  href={getImageUrlForTrait(trait.id, ImageFileType.svg)}
                  target="_blank"
                  rel="noopener noreferrer"
                  flex={1}
                >
                  <MenuItem icon={<MdFileDownload size={20} />}>Download as SVG</MenuItem>
                </Link>
              </MenuList>
            </>
          )}
        </Menu>
      </ButtonGroup>
    </>
  )
}
