import { MotionBox } from '@chain-runners/ui'
import React from 'react'

export const LandingPageSectionTransitionWrapper: React.FC = ({ children }) => {
  return (
    <MotionBox
      display="flex"
      direction="column"
      flex={1}
      initial={{
        opacity: 0,
        transition: { duration: 0.2 },
      }}
      exit={{
        opacity: 0,
        transition: { duration: 0.2 },
      }}
      animate={{
        opacity: 1,
        transition: { duration: 0.2 },
      }}
    >
      {children}
    </MotionBox>
  )
}
