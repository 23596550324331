import { MotionFlex, useIsMobile } from '@chain-runners/ui'
import { Box, Flex } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import { LandingPageHeader } from 'src/components/new-landing/LandingPageHeader'
import { ExternalLogos } from '../components/legacy-landing/ExternalLogos'
import {
  LANDING_PAGE_OVERLAYS,
  LANDING_PAGE_SECTIONS,
} from '../components/new-landing/constants'
import { LandingPageTerminal } from '../components/new-landing/LandingPageTerminal'
import { MobileLandingPageHeader } from '../components/new-landing/MobileLandingPageHeader'
import { LandingPageSection } from '../components/new-landing/types'
import { getSectionURL } from '../components/new-landing/utils'

export type LandingPageProps = {
  mountPath?: string
}

export const Landing: React.FC<LandingPageProps> = ({ mountPath = '/' }) => {
  const isMobile = useIsMobile()
  const location = useLocation()

  const overlaySection: LandingPageSection | undefined = useMemo(() => {
    window.scrollTo({ top: 0 })

    return LANDING_PAGE_OVERLAYS.find((section) => {
      return section.exactPath
        ? location.pathname === getSectionURL(mountPath, section)
        : location.pathname.startsWith(getSectionURL(mountPath, section))
    })
  }, [location, mountPath])

  const activeSection: LandingPageSection = useMemo(() => {
    return (
      LANDING_PAGE_SECTIONS.find((section) => {
        return section.exactPath
          ? location.pathname === getSectionURL(mountPath, section)
          : location.pathname.startsWith(getSectionURL(mountPath, section))
      }) ?? LANDING_PAGE_SECTIONS[0]
    )
  }, [location, mountPath])

  return (
    <Flex
      color="white"
      width="full"
      minH="100vh"
      flexDir="column"
      fontFamily="inter"
      position="relative"
    >
      {isMobile ? (
        <MobileLandingPageHeader
          mountPath={mountPath}
          activeSection={activeSection}
          containerProps={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
          }}
        />
      ) : (
        <LandingPageHeader
          mountPath={mountPath}
          activeSection={activeSection}
          containerProps={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
          }}
        />
      )}

      <AnimatePresence>
        {React.createElement(activeSection.component, { key: activeSection.name })}
      </AnimatePresence>

      <AnimatePresence>
        {overlaySection && (
          <MotionFlex
            position="fixed"
            height="100vh"
            width="100vw"
            overflowY="auto"
            bgColor="black"
            zIndex={50}
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1, transition: { duration: 0.2 } }}
            exit={{ scale: 0, opacity: 0 }}
          >
            {React.createElement(overlaySection.component, { key: activeSection.name })}
          </MotionFlex>
        )}
      </AnimatePresence>

      {!isMobile && !activeSection.hideExternalLinks && (
        <Box position="fixed" right={4} bottom="calc(16px + 51px + 8px)" zIndex={3}>
          <ExternalLogos hideContractLink imageSize="28px" />
        </Box>
      )}

      {!isMobile && <LandingPageTerminal />}
    </Flex>
  )
}
