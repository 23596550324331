import { getOrThrow } from '@chain-runners/utils'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { getAPIClient, getAPIHeaders, GraphQLAPIClient } from '../clients/api'
import { User } from '../clients/api/generated'
import { getSignature } from '../utils/wallet'
import { useWallet } from './useWallet'

// TODO: Make this dynamic
export const VERIFICATION_TEXT = 'Verify your wallet with Chain Runners'

type UseAuthenticationState = {
  currentUser: User | null
  token: string | null
}

const INITIAL_STATE: UseAuthenticationState = {
  currentUser: null,
  token: null,
}

export type AuthenticationContextValue = {
  login: (rethrow?: boolean) => Promise<User | undefined>
  logout: () => Promise<void>
  currentUser: User | null
  token: string | null
  isLoading: boolean
  apiClient: GraphQLAPIClient
  initialized: boolean
  refetchCurrentUser: () => Promise<User | undefined>
}

const AuthenticationContext = React.createContext<AuthenticationContextValue>({
  login: async () => {
    return {} as any
  },
  logout: async () => {
    return
  },
  refetchCurrentUser: async (): Promise<User | undefined> => {
    return
  },
  apiClient: {} as any,
  currentUser: null,
  token: null,
  isLoading: false,
  initialized: false,
})

export const AuthenticationProvider: React.FC = ({ children }) => {
  const { connect, disconnect, wallet } = useWallet()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [state, setState] = useState<UseAuthenticationState>(INITIAL_STATE)
  const [initialized, setInitialized] = useState<boolean>(false)
  const { token } = state
  const apiClient = useMemo(() => getAPIClient(token ?? undefined), [token])

  const getCurrentUser = useCallback(async (): Promise<User | undefined> => {
    setIsLoading(true)
    try {
      const refreshResponse = await apiClient.refreshToken()
      const token = refreshResponse.refreshToken.token ?? null
      const currentUserResponse = await apiClient.currentUser(
        {},
        getAPIHeaders(token ?? undefined),
      )
      setState((existing) => ({
        ...existing,
        currentUser: currentUserResponse.whoAmI ?? null,
        token,
      }))
      return currentUserResponse.whoAmI ?? undefined
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
      setInitialized(true)
    }
  }, [apiClient])

  const handleLogin = useCallback(
    async (rethrow = false): Promise<User | undefined> => {
      setIsLoading(true)
      try {
        const { provider, address } = wallet ?? (await connect())
        const signature = await getSignature(provider, address, VERIFICATION_TEXT)
        const response = await apiClient.login({
          address,
          signature: typeof signature === 'string' ? signature : signature.result,
        })
        setState((existing) => ({
          ...existing,
          currentUser: response.login.user ?? null,
          token: response.login.token ?? null,
        }))
        return getOrThrow(response.login.user, 'User not found')
      } catch (e) {
        console.error(e)
        if (rethrow) throw e
      } finally {
        setIsLoading(false)
      }
    },
    [wallet, connect, apiClient],
  )

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      disconnect()
      setState(INITIAL_STATE)
      await apiClient.logout()
    } catch (e) {
      console.error(e)
    }
  }, [apiClient, disconnect])

  const firstRender = useRef(true)
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      getCurrentUser()
    }
  }, [getCurrentUser])

  return (
    <AuthenticationContext.Provider
      value={{
        apiClient,
        token: state.token,
        currentUser: state.currentUser,
        isLoading,
        login: handleLogin,
        logout: handleLogout,
        refetchCurrentUser: getCurrentUser,
        initialized,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

export function useAuthentication(): AuthenticationContextValue {
  return useContext(AuthenticationContext)
}
