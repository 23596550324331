import { Box, Flex, Image, Link, Stack, StackItem } from '@chakra-ui/react'
import React from 'react'
import { LandingPageSectionWithDetails } from '../LandingPageSectionWithDetails'
import { LandingPageSectionDetailsConfig } from '../types'

type TeamMember = {
  name: string
  title: string
  twitterUsername: string
  profilePicture: string
}

const TEAM_MEMBERS: Array<TeamMember> = [
  {
    name: 'mid',
    title: 'Master of Vibes',
    twitterUsername: 'evandenn',
    profilePicture: '/static/pfps/mid.png',
  },
  {
    name: 'knav',
    title: 'Engineering',
    twitterUsername: 'knaveth',
    profilePicture: '/static/pfps/knav.png',
  },
  {
    name: 'dozer',
    title: 'Engineering',
    twitterUsername: 'dozerparker721',
    profilePicture: '/static/pfps/dozer.png',
  },
  {
    name: 'braindraind',
    title: 'Creative',
    twitterUsername: 'braindraind',
    profilePicture: '/static/pfps/braindraind.png',
  },
  {
    name: 'saintstereo',
    title: 'Creative',
    twitterUsername: 'SaintStereo',
    profilePicture: '/static/pfps/saintstereo.png',
  },
]

const ENTER_THE_METAVERSE_SECTION_CONFIG: LandingPageSectionDetailsConfig = {
  title: (
    <span>
      Enter The
      <br />
      Runnerverse
    </span>
  ),
  content: (
    <>
      <Box as="p">
        Chain Runners is a collaboratively built cyberpunk universe owned and influenced
        by the community. We design entertaining and innovative experiences that span
        mediums and break convention. We build primitives that are open and free to use.
        We enable self-expression across the connected web of digital spaces now linked by interoperable assets. We make stories – and <i>we invite you</i> to
        help write them.
      </Box>

      <Box as="p" mt={8}>
        Run with us, renegade, and see how deep the rabbit hole goes.
      </Box>
    </>
  ),
  backgroundImage: '/static/CR_Site_Dealer.png',
}

const ABOUT_PAGE_DETAILS_CONFIG: Array<LandingPageSectionDetailsConfig> = [
  ENTER_THE_METAVERSE_SECTION_CONFIG,
  {
    title: 'Genesis',
    content: (
      <>
        <Box as="p">
          It all started with 10,000 avatars completely generated and stored on the
          Ethereum blockchain. A diverse collection of characters powered by a
          hyper-optimized contract, beautiful pixel art, and rich narrative. We built
          something we wanted to exist, a world inspired by stories and characters that
          shaped our taste and interests.
        </Box>

        <Box as="p" mt={8}>
          This collection is the genesis of a fictional universe that captured the
          imagination of thousands and it is forever imprinted on chain.
        </Box>
      </>
    ),
    secondaryContent: (
      <Flex h="full" maxW="400px" alignItems="center" justifyContent="center" w="full">
        <Image w="calc(min(90vh, 600px))" mx="auto" src="/static/genesis.png" />
      </Flex>
    ),
  },
  {
    title: 'The Team',
    content: (
      <>
        <Box as="p" mb={8}>
          We’re a diverse group of builders with experience across the tech and gaming
          industries. Collectively we’ve built digital products for some of the largest
          technology companies and helped ship AAA games.
        </Box>
        <Stack direction={['column', 'column', 'row']} spacing={6}>
          {TEAM_MEMBERS.map((member) => {
            return (
              <StackItem key={member.name}>
                <Box w="100%" marginX="auto">
                  <Image width="128px" src={member.profilePicture} mb={3} />

                  <Box fontWeight="semibold" fontSize="14px">
                    {member.name}
                  </Box>
                  <Box fontSize="14px">{member.title}</Box>
                  <Link
                    fontSize="14px"
                    color="hackerGreen"
                    href={`https://twitter.com/${member.twitterUsername}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @{member.twitterUsername}
                  </Link>
                </Box>
              </StackItem>
            )
          })}
        </Stack>
      </>
    ),
  },
]

export const AboutLandingPageHomeSection: React.FC = () => {
  return (
    <LandingPageSectionWithDetails
      title="About"
      detailsConfig={ABOUT_PAGE_DETAILS_CONFIG}
    />
  )
}
