import { HackerTooltip } from '@chain-runners/ui'
import { Image, Link, Stack, StackItem } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { DISCORD_INVITE_LINK, TWITTER_HANDLE } from 'src/config/base'
import { getNetworkConfig } from '../../utils/network'

export type LogoItemProps = {
  href: string
  image: string
  tooltip: string
  imageSize?: number | string | Array<string | number | null>
}

export const LogoItem: React.FC<LogoItemProps> = ({
  image,
  href,
  tooltip,
  imageSize = '32px',
}) => {
  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      _focus={{ outline: 'none' }}
    >
      <StackItem _hover={{ opacity: 0.8 }}>
        <HackerTooltip label={tooltip} placement="top">
          <Image src={image} width={imageSize} height={imageSize} />
        </HackerTooltip>
      </StackItem>
    </Link>
  )
}

export type ExternalLogosProps = {
  hideContractLink?: boolean
  imageSize?: number | string | Array<string | number | null>
}

export const ExternalLogos: React.FC<ExternalLogosProps> = ({
  hideContractLink,
  imageSize,
}) => {
  const networkConfig = useMemo(() => getNetworkConfig(), [])

  const etherscanLink: string | undefined = useMemo(() => {
    const {
      blockExplorer,
      contractConfig: { mainContractAddress },
    } = networkConfig
    return (
      networkConfig.blockExplorer && `${blockExplorer}/address/${mainContractAddress}`
    )
  }, [networkConfig])

  const openseaUrl: string | undefined = useMemo(() => {
    const { openSeaBaseUrl, openSeaProjectSlug } = networkConfig
    return (
      openSeaBaseUrl &&
      openSeaProjectSlug &&
      `${openSeaBaseUrl}/collection/${openSeaProjectSlug}`
    )
  }, [networkConfig])

  const looksRareUrl: string | undefined = useMemo(() => {
    const {
      contractConfig: { mainContractAddress },
    } = networkConfig
    return (
      mainContractAddress && `https://looksrare.org/collections/${mainContractAddress}`
    )
  }, [networkConfig])

  return (
    <Stack direction="row" spacing={[4, null, 8]}>
      <LogoItem
        href={DISCORD_INVITE_LINK}
        image="/static/discord_logo.svg"
        tooltip="Join Discord"
        imageSize={imageSize}
      />
      {etherscanLink && !hideContractLink && (
        <LogoItem
          href={etherscanLink}
          image="/static/etherscan_logo.svg"
          tooltip="View Contract"
          imageSize={imageSize}
        />
      )}
      {openseaUrl && (
        <LogoItem
          href={openseaUrl}
          image="/static/opensea_logo.png"
          tooltip="View Collection"
          imageSize={imageSize}
        />
      )}
      {openseaUrl && (
        <LogoItem
          href={looksRareUrl}
          image="/static/looks-rare-logo.svg"
          tooltip="View Collection"
          imageSize={imageSize}
        />
      )}
      <LogoItem
        href={`https://twitter.com/${TWITTER_HANDLE}`}
        image="/static/twitter_logo.svg"
        tooltip="View Twitter"
        imageSize={imageSize}
      />
    </Stack>
  )
}
