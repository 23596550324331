import { MotionImage, STANDARD_TRANSITION_CONFIG } from '@chain-runners/ui'
import { TargetAndTransition } from 'framer-motion/types/types'
import React, { forwardRef, useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SnowStorm from 'react-snowstorm'
import { LandingPageSection } from './types'

const BACKGROUND_INITIAL_POSITION: TargetAndTransition = { x: '-30vh' }

const BACKGROUND_IMAGE_ANIMATION_VARIANTS: Record<
  LandingPageSection,
  TargetAndTransition
> = {
  [LandingPageSection.Home]: {
    x: '-30vh',
    top: '-37vh',
    transition: STANDARD_TRANSITION_CONFIG,
  },
  [LandingPageSection.Hub]: {
    x: '-30vh',
    top: '-37vh',
    transition: STANDARD_TRANSITION_CONFIG,
  },
  [LandingPageSection.Left]: {
    x: '44vh',
    top: '-37vh',
    transition: STANDARD_TRANSITION_CONFIG,
  },
  [LandingPageSection.Right]: {
    x: '-88vh',
    top: '-37vh',
    transition: STANDARD_TRANSITION_CONFIG,
  },
  [LandingPageSection.Up]: {
    x: '-30vh',
    top: '45vh',
    transition: STANDARD_TRANSITION_CONFIG,
  },
}

export type LandingPageBackgroundImageProps = {
  initialSection?: LandingPageSection
  onLoad?: () => void
  activeSection: LandingPageSection
}

export const LandingPageBackgroundImage = forwardRef<
  HTMLImageElement,
  LandingPageBackgroundImageProps
>(({ initialSection, activeSection, onLoad }, ref) => {
  const isWinter = useMemo(() => {
    // TODO: Add end date for winter
    return false
  }, [])

  return (
    <>
      <MotionImage
        ref={ref}
        className="pixelart"
        src={isWinter ? '/static/winter_bg.png' : '/static/key_art.png'}
        onLoad={onLoad}
        position="absolute"
        minH="170vh"
        minW="340vh"
        top="-20vh"
        initial={
          initialSection === LandingPageSection.Home || !initialSection
            ? BACKGROUND_INITIAL_POSITION
            : BACKGROUND_IMAGE_ANIMATION_VARIANTS[initialSection]
        }
        variants={BACKGROUND_IMAGE_ANIMATION_VARIANTS}
        animate={activeSection}
      />
      {isWinter && (
        <SnowStorm
          flakesMax={256}
          flakesMaxActive={128}
          followMouse={false}
          excludeMobile={false}
        />
      )}
    </>
  )
})
