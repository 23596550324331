import {
  FileSystemDirectory,
  TerminalCommand,
  TerminalCommandContext,
} from '@chain-runners/ui'
import { Box } from '@chakra-ui/react'
import React from 'react'
import { sleep } from '../../../utils/async'

export const localBootSequence = async ({
  sendToStdout,
  setIsEnabled,
}: TerminalCommandContext): Promise<void> => {
  sendToStdout({ message: 'Connected.', props: { color: 'hackerGreen' } })

  sendToStdout({ message: 'Initializing' })
  await sleep(100)
  sendToStdout({ message: 'Initializing.' }, true)
  await sleep(100)
  sendToStdout({ message: 'Initializing..' }, true)
  await sleep(100)
  sendToStdout({ message: 'Initializing...' }, true)
  await sleep(100)
  sendToStdout({ message: 'Initializing....' }, true)
  await sleep(100)
  await sleep(100)
  sendToStdout({ message: 'Initializing.....' }, true)
  await sleep(100)
  sendToStdout({ message: 'Initialized.' })
  sendToStdout({
    message: (
      <>
        Welcome to{' '}
        <Box as="span" color="hackerGreen">
          RunnerHub
        </Box>
        !
      </>
    ),
  })
  sendToStdout({ message: `Use "help" to view available commands` })
  setIsEnabled(true)
}

export const LOCAL_FILE_SYSTEM: Array<FileSystemDirectory | TerminalCommand> = [
  {
    name: 'home',
    items: [],
  },
  {
    name: 'bin',
    items: [],
    isLocked: true,
  },
  {
    name: 'root',
    items: [],
    isLocked: true,
  },
  {
    name: 'sys',
    items: [],
    isLocked: true,
  },
]
