import { HackerButton } from '@chain-runners/ui'
import { Box, Flex, Image, MenuDivider, MenuItem } from '@chakra-ui/react'
import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import { BsChevronLeft, BsChevronRight } from 'react-icons/all'
import { COUNTDOWN_TIMESTAMP } from '../../../config/base'
import { ConnectWalletButton } from '../../ConnectWalletButton'
import { Countdown } from '../../utils/Countdown'
import { ExternalLogos } from '../ExternalLogos'
import { LandingPageButton } from '../LandingPageButton'
import { LandingPageSection } from '../types'

export type HomeProps = {
  handlePageTransition: (pageSection: LandingPageSection) => void
}

export const Home: React.FC<HomeProps> = ({ handlePageTransition }) => {
  const handleShowHub = useCallback(() => {
    handlePageTransition(LandingPageSection.Hub)
  }, [handlePageTransition])

  return (
    <>
      <Flex alignItems="center" w="full" mt={4}>
        <ExternalLogos/>
        <Flex flex={1} justifyContent="flex-end">
          <Box p={1} bgColor="blackAlpha.700">
            <ConnectWalletButton>
              <MenuItem onClick={() => handlePageTransition(LandingPageSection.Up)}>
                Account
              </MenuItem>
              <MenuDivider/>
            </ConnectWalletButton>
          </Box>
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flex={1}
        mt="10rem"
      >
        {Boolean(COUNTDOWN_TIMESTAMP) && (
          <Countdown
            to={DateTime.fromMillis(COUNTDOWN_TIMESTAMP)}
            hideWhenComplete
            textProps={{
              fontSize: 24,
              fontFamily: 'Nokia',
              color: 'hackerGreen',
              className: 'outlined-text',
            }}
          />
        )}
        <Image
          className="pixelart"
          userSelect="none"
          src="/static/chain_runners_logo.svg"
        />
        <Box p={1} bgColor="blackAlpha.700">
          <HackerButton onClick={handleShowHub} textTransform={undefined}>
            Enter RunnerHub
          </HackerButton>
        </Box>
      </Flex>

      <Flex
        marginBottom="8vh"
        width="full"
        justifyContent="space-between"
        maxWidth="950px"
        marginX="auto"
      >
        <LandingPageButton
          mr={4}
          onClick={() => handlePageTransition(LandingPageSection.Left)}
        >
          <Flex alignItems="center" width="full" paddingLeft={1} paddingRight={2}>
            <Box>
              <BsChevronLeft/>
            </Box>
            <Box flex={1} textAlign="right">
              Learn more
            </Box>
          </Flex>
        </LandingPageButton>
        <LandingPageButton onClick={() => handlePageTransition(LandingPageSection.Right)}>
          <Flex alignItems="center" width="full" paddingLeft={2} paddingRight={1}>
            <Box flex={1} textAlign="left">
              FAQ
            </Box>
            <Box>
              <BsChevronRight/>
            </Box>
          </Flex>
        </LandingPageButton>
      </Flex>
    </>
  )
}
