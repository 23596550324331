import { IoHomeSharp } from 'react-icons/all'
import { APPLICATIONS } from './applications'
import { HubHome } from './applications/Home'
import { HubApplication, HubApplicationRoute } from './types'

export const HubHomeApplication: HubApplication = {
  route: HubApplicationRoute.Home,
  name: 'Home',
  icon: IoHomeSharp,
  component: HubHome,
}

export const HUB_APPLICATIONS: Array<HubApplication> = [
  ...APPLICATIONS,
  HubHomeApplication,
]

export const HOME_APPLICATIONS = [...APPLICATIONS].reverse()
export const NAVIGATION_APPLICATIONS = [...HUB_APPLICATIONS].reverse()
