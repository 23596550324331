import { Flex } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AccountPage } from '../../AccountPage'

export const AccountLandingPageHomeSection: React.FC = () => {
  const navigate = useNavigate()
  return (
    <Flex flexDir="column" flex={1}>
      <AccountPage onDone={() => navigate('/')} />
    </Flex>
  )
}
