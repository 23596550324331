import { HackerButton, MotionBox, MotionFlex, MotionImage, useIsMobile } from '@chain-runners/ui'
import { Box, Flex, Link, useDisclosure } from '@chakra-ui/react'
import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { MdOutlinePlayCircleOutline } from 'react-icons/md'
import { HACKER_GREEN_COLOR } from '../../../theme'
import { LandingPageSectionTransitionWrapper } from '../LandingPageSectionTransitionWrapper'
import { VideoOverlay } from '../VideoOverlay'

export const HomeLandingPageSection: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobile = useIsMobile()

  return (
    <LandingPageSectionTransitionWrapper>
      <Box
        top={0}
        position="absolute"
        minH="100vh"
        width="100vw"
        backgroundImage="/static/background.png"
        backgroundPosition={["left", null, null, "center"]}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      />

      <AnimatePresence>
        <MotionFlex
          position="relative"
          zIndex={2}
          flexDir="column"
          flex={1}
          justifyContent="flex-end"
          minH="100vh"
        >
          <Box position="absolute" top="0" left="0" bottom={0} right={0} margin={"auto"} overflow="hidden">
            <MotionBox position="relative" width={["100%", 700, 800, 1200]} height={[450, 700, 800, 1000]} left="50%" top={["49%", null, null, "50%"]} transform={"translateX(-50%) translateY(-50%)"}>
            <MotionImage initial={{ opacity: 0, translateY: '0%' }} animate={{ opacity: 1, translateY: '0%', transition: { duration: 0.75, delay: 0.7 } }} src="/static/somnuszero.png" width={[1000, 1200, 900, 1500]} position="absolute" top={["-45%", "-35%", "-10%", "-10%"]} left={["0", "7%", "-8%", "-12%"]} maxW="none"/>
            <MotionImage initial={{ opacity: 0, translateY: '0%' }} animate={{ opacity: 1, translateY: '0%', transition: { duration: 0.75, delay: 0.4 } }} src="/static/alien-girl.png" width={[120, 180, 220, 320]} position="absolute" left={["2%", "5%", "3%",  "5%"]} bottom={["8%", "8%", "6%", "5%"]} />
            <MotionImage initial={{ opacity: 0, translateY: '0%' }} animate={{ opacity: 1, translateY: '0%', transition: { duration: 0.75, delay: 0.5 } }} src="/static/grenade-guy.png" width={[150, 250, 300, 350]} position="absolute" left={["17%", "19%", "17%", "22%"]} bottom={["10%", "7%", "5%", "10%"]} />
            <MotionImage initial={{ opacity: 0, translateY: '0%' }} animate={{ opacity: 1, translateY: '0%', transition: { duration: 0.75, delay: .6 } }} src="/static/skull.png" width={[127, 200, 240, 280]} position="absolute" right={["15%", null, null, "25%"]} bottom={"10%"} />
            <MotionImage initial={{ opacity: 0, translateY: '0%' }} animate={{ opacity: 1, translateY: '0%', transition: { duration: 0.75, delay: 0.6 } }} src="/static/standing-guy.png" width={[170, 250, 300, 320]} position="absolute" left={["40%", null, "40%"]} top={["6%", "8%", "8%", "10%"]} />
            <MotionImage initial={{ opacity: 0, translateY: '0%' }} animate={{ opacity: 1, translateY: '0%', transition: { duration: 0.75, delay: 0.1 } }} src="/static/hacker-girl.png" width={[300, 450, 500, 700]} position="absolute" bottom={0} left={["6%", "10%", "9%", "10%"]} zIndex={1}/>
            <MotionImage initial={{ opacity: 0, translateY: '0%' }} animate={{ opacity: 1, translateY: '0%', transition: { duration: 0.75, delay: .6 } }} src="/static/hacker.png" width={[300, 450, 520, 670]} position="absolute" bottom={["-4%", "-8%", "-12%", "-8%"]} right={["-30%", "-27%", "-27%", "-17%" ]}/>
            </MotionBox>
          </Box>

          <Box
            direction="column"
            position="absolute"
            bottom={4}
            left={4}
            right={isMobile ? 4 : undefined}
          >
            <Box mb={2}>
              <Link href="https://somnet.chainrunners.xyz/">
                <HackerButton
                  fontWeight="bold"
                  borderRadius="4px"
                  w="full"
                  bgColor="blackAlpha.700"
                  color="hackerGreen"
                  borderWidth="1px"
                  letterSpacing="1.5px"
                  _hover={{
                    bgColor: 'blackAlpha.800',
                  }}
                  _active={{
                    bgColor: 'blackAlpha.700',
                  }}
                >
                  MINT NOW
                </HackerButton>
              </Link>
            </Box>

            <Flex
              height={isMobile ? '200px' : '200px'}
              width={isMobile ? undefined : '350px'}
              backgroundImage="/static/video_preview.png"
              backgroundPosition="center"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              borderRadius="8px"
              justifyContent="center"
            >
              <MotionFlex
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                onClick={onOpen}
                animate={{ color: '#fff' }}
                whileHover={{
                  scale: 1.1,
                  color: HACKER_GREEN_COLOR,
                  transition: { duration: 0.2 },
                }}
              >
                <MotionBox display="inline-block" zIndex={4}>
                  <MotionFlex
                    direction="column"
                    textAlign="center"
                    display="inline-block"
                  >
                    <Box display="inline-block" fontSize={[24, null, 42]}>
                      <MdOutlinePlayCircleOutline />
                    </Box>
                    <Box
                      fontSize={[10, null, 12]}
                      fontWeight="semibold"
                      userSelect="none"
                    >
                      WATCH VIDEO
                    </Box>
                  </MotionFlex>
                </MotionBox>
              </MotionFlex>
            </Flex>
          </Box>
        </MotionFlex>
      </AnimatePresence>

      <VideoOverlay isOpen={isOpen} onClose={onClose} />
    </LandingPageSectionTransitionWrapper>
  )
}
