import {
  HACKER_GREEN_COLOR,
  HACKER_ORANGE_COLOR,
  HACKER_PINK_COLOR,
  HACKER_RED_COLOR,
  HackerButtonProps,
  HackerIconButton,
  usePrimaryColor,
} from '@chain-runners/ui'
import { Box, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import React from 'react'
import { SiTarget } from 'react-icons/all'

export type PrimaryColorPickerProps = {
  buttonProps?: Partial<HackerButtonProps>
}

const availableColors: Array<string> = [
  HACKER_GREEN_COLOR,
  HACKER_RED_COLOR,
  HACKER_PINK_COLOR,
  HACKER_ORANGE_COLOR,
]

export const PrimaryColorPicker: React.FC<PrimaryColorPickerProps> = ({
  buttonProps,
}) => {
  const { primaryColor, setPrimaryColor } = usePrimaryColor()

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            as={HackerIconButton}
            textTransform="initial"
            fontSize="14px"
            {...buttonProps}
          >
            <Box px={3}>
              <SiTarget />
            </Box>
          </MenuButton>
          <MenuList
            zIndex={10000}
            backgroundColor="black"
            borderRadius={0}
            borderColor={primaryColor}
            minW={0}
          >
            {availableColors.map((color) => {
              return (
                <MenuItem
                  key={color}
                  onClick={() => {
                    setPrimaryColor(color)
                  }}
                  fontSize="14px"
                  color={color}
                >
                  <Box px={3}>
                    <SiTarget />
                  </Box>
                </MenuItem>
              )
            })}
          </MenuList>
        </>
      )}
    </Menu>
  )
}
