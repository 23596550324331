import { MotionFlex, STANDARD_TRANSITION_CONFIG } from '@chain-runners/ui'
import { FlexProps } from '@chakra-ui/react'
import { Variant } from 'framer-motion'
import React, { useMemo } from 'react'
import { LandingPageSection } from './types'

export type LandingPageContentSectionProps = {
  initialSection?: LandingPageSection
  section: LandingPageSection
  activeSection: LandingPageSection
  containerProps?: Partial<FlexProps>
}

const INITIAL_CONFIG_MAPPING: Record<LandingPageSection, Variant> = {
  Home: {},
  Hub: {},
  Left: {
    opacity: 0,
    x: '-74vh',
    transition: STANDARD_TRANSITION_CONFIG,
  },
  Right: {
    opacity: 0,
    x: '74vh',
    transition: STANDARD_TRANSITION_CONFIG,
  },
  Up: {
    opacity: 0,
    y: '-74vh',
    transition: STANDARD_TRANSITION_CONFIG,
  },
}

const SHOWN_CONFIG_MAPPING: Record<LandingPageSection, Variant> = {
  Home: {},
  Hub: {},
  Left: {
    opacity: 1,
    x: 0,
    transition: STANDARD_TRANSITION_CONFIG,
  },
  Right: {
    opacity: 1,
    x: 0,
    transition: STANDARD_TRANSITION_CONFIG,
  },
  Up: {
    opacity: 1,
    y: 0,
    transition: STANDARD_TRANSITION_CONFIG,
  },
}

export const LandingPageContentSection: React.FC<LandingPageContentSectionProps> = ({
  initialSection,
  section,
  activeSection,
  containerProps,
  children,
}) => {
  const isActive = useMemo(() => activeSection === section, [activeSection, section])
  const initialState = useMemo(() => INITIAL_CONFIG_MAPPING[section], [section])
  const shownState = useMemo(() => SHOWN_CONFIG_MAPPING[section], [section])

  return (
    <MotionFlex
      zIndex={1}
      initial={initialSection === section ? shownState : initialState}
      animate={shownState}
      exit={initialState}
      position="absolute"
      top={0}
      left={0}
      right={0}
      alignItems="center"
      flexDirection="column"
      pointerEvents={isActive ? 'initial' : 'none'}
      paddingY={3}
      paddingX={[3, null, 8]}
      overflowY="auto"
      maxHeight="100vh"
      minH="100vh"
      {...containerProps}
    >
      {children}
    </MotionFlex>
  )
}
