import { Flex } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Hub } from '../../hub/Hub'

export const HubLandingPageSection: React.FC = () => {
  const navigate = useNavigate()
  return (
    <Flex width="full" maxH="100vh" minH="100vh" height="100vh" overflowY="auto">
      <Hub
        onExit={() => {
          navigate('/')
        }}
      />
    </Flex>
  )
}
