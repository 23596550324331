import { RunnerTrait, TraitType } from '@chain-runners/types'
import { Sidebar } from '@chain-runners/ui'
import { Box } from '@chakra-ui/react'
import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { TraitFilters } from './applications/TraitExplorer'
import { TraitTypeFilterGroup } from './components/TraitTypeFilterGroup'

export type TraitFiltersSidebarProps = {
  filters: TraitFilters
  setFilters: Dispatch<SetStateAction<TraitFilters>>
  isOpen?: boolean
  setIsOpen?: Dispatch<SetStateAction<boolean>>
  traitsByType: Record<TraitType, Array<RunnerTrait>>
}

export const TraitFiltersSidebar: React.FC<TraitFiltersSidebarProps> = ({
  filters,
  setFilters,
  isOpen,
  setIsOpen,
  traitsByType,
}) => {
  const handleSetTraitTypeFilter = useCallback(
    (selectedTraitTypes?: Array<TraitType>) => {
      setFilters((filters) => {
        return {
          ...filters,
          traitTypes: selectedTraitTypes,
        }
      })
    },
    [setFilters],
  )

  return (
    <Sidebar
      title={<Box fontWeight="semibold">Filters</Box>}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <TraitTypeFilterGroup
        traitsByType={traitsByType}
        selectedTraits={filters.traitTypes ?? undefined}
        setSelectedTraits={handleSetTraitTypeFilter}
        defaultOpen
      />
    </Sidebar>
  )
}
