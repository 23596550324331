import { MotionBox, MotionFlex, useIsMobile } from '@chain-runners/ui'
import { Accordion, Box, Flex, Image, Link, useDisclosure } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { MdOutlinePlayCircleOutline } from 'react-icons/md'
import { Question } from '../../../pages/xr/XRFAQ'
import { HACKER_GREEN_COLOR } from '../../../theme'
import { LandingPageSectionWithDetails } from '../LandingPageSectionWithDetails'
import { LandingPageSectionDetailsConfig } from '../types'
import { VideoOverlay } from '../VideoOverlay'

export const XRLandingPageHomeSection: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobile = useIsMobile()

  const config: Array<LandingPageSectionDetailsConfig> = useMemo(() => {
    return [
      {
        titleHeaderContent: (
          <Flex
            flexDir="column"
            justifyContent="center"
            alignItems={'center'}
            w="fit-content"
          >
            <Image w="calc(min(90vh, 400px))" src="static/chain-runners-xr-logo.png" />
            <Flex alignItems={'center'} mb={8} mt={-4}>
              <Box mr={2} fontSize="14px" opacity={0.7}>
                In partnership with
              </Box>
              <Link
                href="https://customuse.com/"
                target="_blank"
                rel="noopener noreferrer"
                _focus={{ outline: 'none' }}
              >
                <Box opacity={0.7} _hover={{ opacity: 1 }}>
                  <Image w="125px" src="static/customuse-logo.svg" />
                </Box>
              </Link>
            </Flex>
          </Flex>
        ),
        subTitle: 'Now Minting',
        title: 'Enter the metaverse',
        titleContainerProps: {
          fontSize: 'calc(min(14vw, 82px))',
          lineHeight: 'calc(min(11.25vw, 70px))',
        },
        content: (
          <>
            <Box as="p">
              Chain Runners XR is the newest addition to the Chain Runners Universe. We’re
              building a player token to be used across the vast web of digital
              experiences known as the metaverse. Play as your runner and dance between
              the ever-blurring lines of the physical and digital world. This is the
              foundation of a 3D Runnerverse.
            </Box>
          </>
        ),
        secondaryContent: (
          <>
            <MotionFlex
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              onClick={onOpen}
              animate={{ color: '#fff' }}
              whileHover={{
                scale: 1.1,
                color: HACKER_GREEN_COLOR,
                transition: { duration: 0.2 },
              }}
            >
              <MotionBox display="inline-block" zIndex={4}>
                <MotionFlex direction="column" textAlign="center" display="inline-block">
                  <Box display="inline-block" fontSize={42}>
                    <MdOutlinePlayCircleOutline />
                  </Box>
                  <Box fontSize={12} fontWeight="semibold" userSelect="none">
                    WATCH VIDEO
                  </Box>
                </MotionFlex>
              </MotionBox>
            </MotionFlex>
          </>
        ),
        backgroundImage: '/static/xr_bg_image.png',
        imageProps: {
          backgroundPosition: 'bottom',
          backgroundSize: 'cover',
        },
      },
      {
        title: 'A new dimension',
        titleContainerProps: {
          fontSize: 'calc(min(14vw, 82px))',
          lineHeight: 'calc(min(11.25vw, 70px))',
        },
        content: (
          <>
            <Box as="p" mb={4}>
              We’ve taken every single trait and have fully realized it in 3D. In addition
              to a high quality PFP render users will be given the underlying 3D model to
              use however they want.
            </Box>
            <Box as="p">
              Every Genesis Chain Runner can claim an identical XR Runner for free. This
              is your key to a new class of 3D experiences in the Runnerverse.
            </Box>
          </>
        ),
        secondaryContent: (
          <Flex
            h="full"
            maxW="400px"
            alignItems="center"
            justifyContent="center"
            w="full"
          >
            <Image
              w="calc(min(90vh, 600px))"
              mx="auto"
              src="/static/runner_pfp_grid.png"
            />
          </Flex>
        ),
      },
      {
        title: 'Express yourself',
        titleContainerProps: {
          fontSize: 'calc(min(14vw, 82px))',
          lineHeight: 'calc(min(11.25vw, 70px))',
        },
        content: (
          <>
            <Box as="p" mb={8}>
              We’re building a variety of experiences that allow self-expression across
              mediums. Bring your runner into the physical world with augmented reality.
            </Box>
            <Flex justifyContent="space-between">
              <Link
                w="25%"
                href="https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=6e6789103fd34c7eab05516cb85376cc&metadata=01"
                _target="blank"
                rel="noopener noreferrer"
              >
                <Image src="/static/snapcode-horns.png" />
              </Link>
              <Link
                w="25%"
                href="https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=d92197f98d764292a5e498aa2dcc65c8&metadata=01"
                _target="blank"
                rel="noopener noreferrer"
              >
                <Image src="/static/snapcode-oni.png" />
              </Link>
              <Link
                w="25%"
                href="https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=dbe455f0ca6642a0a1cf60d0ff65817c&metadata=01"
                _target="blank"
                rel="noopener noreferrer"
              >
                <Image src="/static/snapcode-alien.png" />
              </Link>
            </Flex>
          </>
        ),
        secondaryContent: (
          <Flex
            h="full"
            maxW="300px"
            alignItems="center"
            justifyContent="center"
            w="full"
          >
            <Image w="calc(min(90vh, 600px))" mx="auto" src="/static/runner-ar.gif" />
          </Flex>
        ),
      },
      {
        title: 'Wearables',
        subTitle: 'Coming soon',
        titleContainerProps: {
          fontSize: 'calc(min(14vw, 82px))',
          lineHeight: 'calc(min(11.25vw, 70px))',
        },
        content: (
          <>
            <Box as="p" mb={4}>
              Take Runner customization to the next level with a growing collection of
              wearables. Dress for style or identify as one of the many archetypes that
              will exist in Mega City. Fashion is an important cultural element for any
              universe. What other items might exist in this world?
            </Box>
            {/* <Box as="p">
            Every Genesis Chain Runner can claim an identical XR Runner for free. This is your key to a new class of 3D experiences in the Runnerverse.
          </Box> */}
          </>
        ),
        secondaryContent: (
          <Flex
            h="full"
            maxW="400px"
            alignItems="center"
            justifyContent="center"
            w="full"
          >
            <Image
              w="calc(min(90vh, 600px))"
              mx="auto"
              src="/static/wearable-mechanic.gif"
            />
          </Flex>
        ),
      },
      {
        title: '3D Experiences',
        subTitle: 'Coming soon',
        titleContainerProps: {
          fontSize: 'calc(min(14vw, 82px))',
          lineHeight: 'calc(min(11.25vw, 70px))',
        },
        content: (
          <>
            <Box as="p" mb={4}>
              We&apos;ve partnered with Nifty Island and are exploring other metaverse
              integrations for XR Runners. We&apos;re also building our own 3D experiences
              which we can&apos;t wait to share.
            </Box>
            {/* <Box as="p">
            Every Genesis Chain Runner can claim an identical XR Runner for free. This is your key to a new class of 3D experiences in the Runnerverse.
          </Box> */}
          </>
        ),
        secondaryContent: (
          <Flex
            h="full"
            maxW="400px"
            alignItems="center"
            justifyContent="center"
            w="full"
          >
            <Image w="calc(min(90vh, 600px))" mx="auto" src="/static/3Dhub.png" />
          </Flex>
        ),
      },
      {
        title: 'CC0 Primitives',
        titleContainerProps: {
          fontSize: 'calc(min(14vw, 82px))',
          lineHeight: 'calc(min(11.25vw, 70px))',
        },
        content: (
          <Box as="p">
            The assets that make up this collection will be released under a full Creative
            Commons (CC0) license. By fostering an ecosystem where creators are
            incentivized to build by leveraging a validated brand, active community, and
            high quality primitives, we believe this universe can be massive.
          </Box>
        ),
        secondaryContent: (
          <Flex
            h="full"
            maxW="400px"
            alignItems="center"
            justifyContent="center"
            w="full"
          >
            <Image
              w="calc(min(90vh, 600px))"
              mx="auto"
              src="/static/rigged-xr-runner.png"
            />
          </Flex>
        ),
      },
      {
        title: 'FAQ',
        titleContainerProps: {
          fontSize: 'calc(min(14vw, 82px))',
          lineHeight: 'calc(min(11.25vw, 70px))',
        },
        content: (
          <Accordion allowToggle allowMultiple width="full" mt={3}>
            <Question question="When is the mint for Chain Runner XR?">
              XR is minting now. You can mint yours{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://somnet.chainrunners.xyz/"
                color="hackerGreen"
                fontWeight="bold"
              >
                here.
              </Link>
            </Question>
            <Question question="What does XR mean?">
              XR stands for{' '}
              <Link
                href="https://en.wikipedia.org/wiki/Extended_reality"
                _target="blank"
                rel="noopener noreferrer"
                color="hackerGreen"
              >
                extended reality
              </Link>
              .
            </Question>
            <Question question="Is this collection expansion?">
              Yes, a Chain Runner XR will count as citizenship in Mega City, granting you
              access to unique perks such as holder-only channels. However, there will
              continue to be unique benefits for holders of Genesis Chain Runners.
            </Question>
            <Question question="Where is the data for Chain Runners XR stored?">
              The DNA and trait data will be stored on-chain like Genesis Chain Runners.
              The art for the XR Runner PFP and model data will be stored off-chain on
              IPFS or equivalent.
            </Question>
          </Accordion>
        ),
        contentContainerProps: {
          display: 'block',
          width: isMobile ? '90vw' : '800px',
          flex: 1,
        },
        imageProps: {
          backgroundSize: 'cover',
        },
      },
    ]
  }, [onOpen, isMobile])

  return (
    <>
      <LandingPageSectionWithDetails title="" detailsConfig={config} />
      <VideoOverlay isOpen={isOpen} onClose={onClose} />
    </>
  )
}
