import {
  DISCORD_AUTH_SCOPES,
  DISCORD_CLIENT_ID,
  DISCORD_OAUTH_REDIRECT_URI,
  DISCORD_OAUTH_URL,
} from '../config/base'

export function getDiscordOAuthRedirectUrl(state: string): string {
  const params = new URLSearchParams()
  params.set('client_id', DISCORD_CLIENT_ID)
  params.set('redirect_uri', DISCORD_OAUTH_REDIRECT_URI)
  params.set('response_type', 'code')
  params.set('state', state)
  params.set('scope', DISCORD_AUTH_SCOPES.join(' '))

  return `${DISCORD_OAUTH_URL}?${params.toString()}`
}
