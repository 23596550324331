import WalletConnectProvider from '@walletconnect/web3-provider'
import { providers } from 'ethers'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { INFURA_ID } from 'src/config/base'
import Web3Modal from 'web3modal'

export const PROVIDER_OPTIONS = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: INFURA_ID, // required
    },
  },
}

export type WalletProviderProps = {}

export const WalletContext = React.createContext<WalletContextValue>({
  connect: () => {
    console.log('Default Connect')
    return Promise.resolve({} as any)
  },
  disconnect: () => Promise.resolve(),
  wallet: null,
  loading: true,
})

let web3Modal: Web3Modal
if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet',
    cacheProvider: true,
    providerOptions: PROVIDER_OPTIONS,
  })
}

export type WalletStatus = {
  address: string
  chainId: number
  web3Provider: providers.Web3Provider
  provider: any
}

export type WalletContextValue = {
  connect: () => Promise<WalletStatus>
  disconnect: () => Promise<void>
  loading: boolean
  wallet: WalletStatus | null
}

export const WalletProvider: React.FC<WalletProviderProps> = ({ children }) => {
  const [wallet, setWallet] = useState<WalletStatus | null>(null)
  const [loading, setLoading] = useState(true)

  const connect = useCallback(async (): Promise<WalletStatus> => {
    setLoading(true)
    try {
      const provider = await web3Modal.connect()
      const web3Provider = new providers.Web3Provider(provider)

      const signer = web3Provider.getSigner()
      const address = await signer.getAddress()
      const network = await web3Provider.getNetwork()

      const wallet = {
        address,
        chainId: network.chainId,
        provider,
        web3Provider,
      }

      setWallet(wallet)
      return wallet
    } finally {
      setLoading(false)
    }
  }, [])

  const provider = wallet?.provider
  const disconnect = useCallback(async () => {
    web3Modal.clearCachedProvider()
    if (provider?.disconnect && typeof provider.disconnect === 'function') {
      await provider.disconnect()
    }
    setWallet(null)
  }, [provider])

  useEffect(() => {
    if (web3Modal && web3Modal.cachedProvider) {
      connect()
    } else {
      setLoading(false)
    }
  }, [connect])

  useEffect(() => {
    if (!provider?.on) return

    const handleAccountsChanged = (accounts: string[]): void => {
      console.log('accountsChanged', accounts)
      setWallet((wallet) => {
        if (wallet === null) return null
        const newAccount = accounts[0]
        if (!newAccount) return wallet

        return {
          ...wallet,
          address: newAccount,
        }
      })
    }

    const handleChainChanged = (chainId: string): void => {
      const parsedChainId = parseInt(chainId, 16)
      console.log('chainChanged', parsedChainId)
      setWallet((wallet) => {
        if (wallet === null) return null

        return {
          ...wallet,
          chainId: parsedChainId,
        }
      })
    }

    const handleDisconnect = async (error: {
      code: number
      message: string
    }): Promise<void> => {
      console.log('disconnect', error)
      await disconnect()
    }

    provider.on('accountsChanged', handleAccountsChanged)
    provider.on('chainChanged', handleChainChanged)
    provider.on('disconnect', handleDisconnect)

    // Subscription Cleanup
    return () => {
      if (provider.removeListener) {
        provider.removeListener('accountsChanged', handleAccountsChanged)
        provider.removeListener('chainChanged', handleChainChanged)
        provider.removeListener('disconnect', handleDisconnect)
      }
    }
  }, [provider, disconnect])

  return (
    <WalletContext.Provider
      value={{
        connect,
        disconnect,
        wallet,
        loading,
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}

export type UseWalletValue = WalletContextValue & {
  isConnected: boolean
  address?: string
}

export function useWallet(): UseWalletValue {
  const walletCtx = React.useContext<WalletContextValue>(WalletContext)
  const { wallet } = walletCtx
  const isConnected = useMemo(() => Boolean(wallet), [wallet])

  return {
    ...walletCtx,
    isConnected,
    address: wallet?.address ?? undefined,
  }
}
