import {
  HackerBox,
  HackerButton,
  NotchedCorner,
  NotchedCornerPosition,
  usePrimaryColor,
} from '@chain-runners/ui'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AiFillCheckCircle, AiOutlineCloseCircle } from 'react-icons/all'
import { useNavigate } from 'react-router-dom'
import { DISCORD_INVITE_LINK } from 'src/config/base'
import { User } from '../clients/api/generated'
import { useAuthentication } from '../hooks/useAuthentication'
import { useWallet } from '../hooks/useWallet'
import { getDiscordOAuthRedirectUrl } from '../utils/discord'
import { extractErrorMessage } from '../utils/error'
import { ConnectWalletButton } from './ConnectWalletButton'

export type AccountPageProps = {
  onDone?: () => void
}

type DiscordCallbackInformation = {
  code: string
  state: string
}

export const AccountPage: React.FC<AccountPageProps> = ({ onDone }) => {
  const { primaryColor } = usePrimaryColor()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const {
    currentUser,
    isLoading: isAuthenticationLoading,
    login,
    initialized,
    apiClient,
    refetchCurrentUser,
  } = useAuthentication()
  const { isConnected, connect, wallet, address } = useWallet()
  const provider = wallet?.provider
  const [isLoading, setIsLoading] = useState(false)
  const [isWalletVerified, setIsWalletVerified] = useState(false)
  const [isDiscordLinked, setIsDiscordLinked] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleConnect = useCallback(async (): Promise<void> => {
    try {
      await connect()
    } catch (e) {
      console.error(e)
    }
  }, [connect])

  const handleDiscordSuccess = useCallback(
    async (callbackInfo: DiscordCallbackInformation): Promise<void> => {
      setIsLoading(true)
      try {
        const response = await apiClient.linkDiscordAccount({ code: callbackInfo.code })
        await refetchCurrentUser()
        if (response.linkDiscordAccount.success) {
          setIsDiscordLinked(true)
        } else {
          setErrorMessage(response.linkDiscordAccount.message)
        }
      } catch (e) {
        setErrorMessage(extractErrorMessage(e))
        console.error(e)
      } finally {
        setIsLoading(false)
      }
    },
    [apiClient, refetchCurrentUser],
  )

  useEffect(() => {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search)
      const code = urlParams.get('code')
      const state = urlParams.get('state')

      if (code && state) {
        handleDiscordSuccess({
          code,
          state,
        })

        urlParams.delete('code')
        urlParams.delete('state')
      }

      navigate(window.location.pathname, { replace: true })
    }
  }, [handleDiscordSuccess, navigate])

  const activePanel: number | null = useMemo(() => {
    if (!isWalletVerified) return 0
    if (!isDiscordLinked) return 1
    return null
  }, [isWalletVerified, isDiscordLinked])

  const initializeStateFromUser = useCallback((user: User) => {
    setIsWalletVerified(true)
    setIsDiscordLinked(Boolean(user.discordAccountId))
  }, [])

  const resetUserState = useCallback(() => {
    setIsWalletVerified(false)
    setIsDiscordLinked(false)
  }, [])
  useEffect(() => {
    if (!initialized) return
    if (currentUser) {
      initializeStateFromUser(currentUser)
    } else {
      resetUserState()
    }
  }, [initialized, currentUser, initializeStateFromUser, resetUserState])

  const handleVerifyWallet = useCallback(async () => {
    setIsLoading(true)
    setErrorMessage(null)
    try {
      const user = await login(true)
      if (user) {
        initializeStateFromUser(user)
      }
    } catch (e) {
      setErrorMessage(extractErrorMessage(e))
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [login, initializeStateFromUser])

  const handleSyncDiscordRoles = useCallback(async () => {
    if (!provider || !address) return

    setIsLoading(true)
    setErrorMessage(null)
    try {
      await apiClient.syncDiscordRoles()
    } catch (e) {
      setErrorMessage(extractErrorMessage(e))
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [apiClient, provider, address])

  const handleUnlinkDiscordAccount = useCallback(async () => {
    setIsLoading(true)
    setErrorMessage(null)
    try {
      await apiClient.disconnectDiscordAccount()
      const user = await refetchCurrentUser()

      if (user) {
        initializeStateFromUser(user)
      }
      onClose()
    } catch (e) {
      setErrorMessage(extractErrorMessage(e))
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [apiClient, refetchCurrentUser, initializeStateFromUser, onClose])

  const handleConnectDiscord = useCallback(() => {
    const redirectUrl = getDiscordOAuthRedirectUrl(currentUser?.address ?? '')
    window.location.href = redirectUrl
  }, [currentUser])

  return (
    <Flex direction="column" minH="100vh" width="full" padding={4}>
      <Flex w="full" justifyContent="flex-end" alignItems="center">
        <ConnectWalletButton />
      </Flex>
      <Flex
        direction="column"
        mt={['10vh', null, '15vh']}
        w="full"
        justifyContent="center"
        alignItems="center"
      >
        {errorMessage && (
          <HackerBox
            width="500px"
            maxWidth="100%"
            marginX="auto"
            themeColor="hackerRed"
            color="white"
            mb={8}
          >
            {errorMessage.startsWith('User is not a member') ? (
              <>
                <Box fontWeight="semibold" color="hackerRed" fontSize="1rem">
                  Error
                </Box>
                We noticed you&apos;re not in our Discord.{' '}
                <Link
                  href={DISCORD_INVITE_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                  color={primaryColor}
                  textDecoration="underline"
                >
                  Please join our Discord
                </Link>{' '}
                to continue.
              </>
            ) : (
              errorMessage
            )}
          </HackerBox>
        )}
        {isConnected ? (
          <HackerBox
            width="500px"
            maxWidth="100%"
            marginX="auto"
            mb={8}
            innerContainerProps={{
              padding: 0,
            }}
          >
            <NotchedCorner size={48} position={NotchedCornerPosition.TopLeft} />
            <Flex
              height="72px"
              alignItems="center"
              justifyContent="center"
              fontWeight="bold"
              fontSize={['1rem', '1.25rem']}
              color="white"
            >
              {isDiscordLinked ? 'Account' : 'Account Registration'}
            </Flex>
            {!initialized && isAuthenticationLoading ? (
              <Flex
                flex={1}
                alignItems="center"
                justifyContent="center"
                height="275px"
                borderTopWidth="1px"
                borderTopColor={primaryColor}
              >
                <Spinner size="lg" thickness="3px" color={primaryColor} />
              </Flex>
            ) : isDiscordLinked ? (
              <Flex
                direction="column"
                borderTopColor={primaryColor}
                color={primaryColor}
                borderTopWidth="1px"
                padding={4}
                alignItems="center"
              >
                <Box mb={8} width="325px" textAlign="center" maxWidth="full">
                  Your wallet has been verified and linked to your Discord account
                </Box>

                <Box mb={8} textAlign="center">
                  <Box fontWeight="bold">Linked Account</Box>
                  <Box>{currentUser?.discordAccountName ?? ''}</Box>
                  <Box
                    fontSize="12px"
                    color="white"
                    textDecoration="underline"
                    cursor="pointer"
                    userSelect="none"
                    _hover={{
                      color: 'whiteAlpha.800',
                    }}
                    onClick={onOpen}
                  >
                    Click here to unlink
                  </Box>

                  <Modal isOpen={isOpen} onClose={onClose} isCentered>
                    <ModalOverlay />
                    <ModalContent
                      borderRadius={0}
                      backgroundColor="black"
                      border="solid"
                      borderWidth="1px"
                      borderColor={primaryColor}
                    >
                      <ModalHeader paddingX={4} paddingTop={4} paddingBottom={0}>
                        Confirm Unlink
                      </ModalHeader>
                      <ModalBody paddingX={4} paddingY={4}>
                        Are you sure you want to unlink this Discord account from your
                        wallet?
                        <Flex alignItems="center" justifyContent="center" paddingTop={4}>
                          <HackerButton
                            onClick={handleUnlinkDiscordAccount}
                            isLoading={isLoading}
                            size="sm"
                            mr={2}
                          >
                            Yes
                          </HackerButton>
                          <HackerButton
                            color="white"
                            variant="ghost"
                            size="sm"
                            fontWeight="normal"
                            onClick={onClose}
                          >
                            Cancel
                          </HackerButton>
                        </Flex>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </Box>
                <Box
                  mb={2}
                  width="325px"
                  maxWidth="full"
                  fontSize="12px"
                  textAlign="center"
                >
                  If you think you do not have the correct Discord roles click here:
                </Box>
                <HackerButton
                  onClick={handleSyncDiscordRoles}
                  isLoading={isLoading}
                  size="sm"
                >
                  Sync Roles
                </HackerButton>
              </Flex>
            ) : (
              <Box>
                <Accordion index={activePanel ?? []}>
                  <AccordionItem borderColor={primaryColor}>
                    <AccordionButton
                      fontSize="1rem"
                      _focus={{ outline: 'none' }}
                      _expanded={{
                        borderBottomWidth: '1px',
                        borderBottomColor: primaryColor,
                      }}
                      paddingY={3}
                    >
                      <Box
                        mr={2}
                        color={isWalletVerified ? primaryColor : 'whiteAlpha.500'}
                      >
                        {isWalletVerified ? (
                          <AiFillCheckCircle size={24} />
                        ) : (
                          <AiOutlineCloseCircle size={24} />
                        )}
                      </Box>
                      <Box color={primaryColor} flex="1" textAlign="left">
                        Verify your wallet
                      </Box>
                    </AccordionButton>
                    <AccordionPanel px={6} py={4}>
                      <Box
                        mb={3}
                        color="whiteAlpha.700"
                        maxWidth="300px"
                        textAlign="center"
                        marginX="auto"
                      >
                        We need to verify your wallet to start the signup process
                      </Box>
                      <Box textAlign="center">
                        <HackerButton
                          size="sm"
                          onClick={handleVerifyWallet}
                          isLoading={isLoading}
                        >
                          Verify Wallet
                        </HackerButton>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem
                    borderColor={primaryColor}
                    borderBottomColor="transparent"
                  >
                    <AccordionButton
                      fontSize="1rem"
                      _focus={{ outline: 'none' }}
                      _expanded={{
                        borderBottomWidth: '1px',
                        borderBottomColor: primaryColor,
                      }}
                      paddingY={3}
                    >
                      <Box
                        mr={2}
                        color={isDiscordLinked ? primaryColor : 'whiteAlpha.500'}
                      >
                        {isDiscordLinked ? (
                          <AiFillCheckCircle size={24} />
                        ) : (
                          <AiOutlineCloseCircle size={24} />
                        )}
                      </Box>
                      <Box color={primaryColor} flex="1" textAlign="left">
                        Connect to Discord
                      </Box>
                    </AccordionButton>
                    <AccordionPanel px={6} py={4}>
                      <Box
                        mb={3}
                        color="whiteAlpha.700"
                        maxWidth="400px"
                        textAlign="center"
                        marginX="auto"
                      >
                        Connect your Discord account to get assigned your roles in the
                        Discord
                      </Box>
                      <Box textAlign="center">
                        <HackerButton
                          size="sm"
                          onClick={handleConnectDiscord}
                          isLoading={isLoading}
                        >
                          Connect Discord
                        </HackerButton>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            )}
          </HackerBox>
        ) : (
          <Box textAlign="center">
            <Button
              display="inline-block"
              variant="link"
              color={primaryColor}
              size="sm"
              mr={2}
              fontSize="16px"
              onClick={handleConnect}
            >
              Connect your wallet
            </Button>
            <Text as="span" color="white" fontSize="16px">
              to get started
            </Text>
          </Box>
        )}
      </Flex>
      {onDone && (
        <Flex
          direction="column"
          pb="15vh"
          flex={1}
          width="full"
          justifyContent="flex-end"
        >
          <HackerButton onClick={onDone} marginX="auto" paddingX={12}>
            Done
          </HackerButton>
        </Flex>
      )}
    </Flex>
  )
}
