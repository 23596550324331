import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { Landing } from './pages/Landing'
import { LegacyLandingPage } from './pages/LegacyLandingPage'

export const Redirect: React.FC<{ to: string }> = ({ to }) => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(to, {
      replace: true,
    })
  }, [navigate, to])
  return <div/>
}

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/legacy" element={<LegacyLandingPage mountPoint="/legacy" />} />
      <Route path="/legacy/*" element={<LegacyLandingPage mountPoint="/legacy" />} />
      <Route path="/items/*" element={<Redirect to="/" />} />
      <Route path="*" element={<Landing />} />
    </Routes>
  )
}
