import { usePrimaryColor } from '@chain-runners/ui'
import { Box, Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import React from 'react'
import { NAVIGATION_APPLICATIONS } from './config'
import { HubNavigationBarItem } from './HubNavigationBarItem'
import { HubApplication, HubApplicationRoute, HubBreadcrumbItem } from './types'

export type HubNavigationBarProps = {
  applicationBreadcrumbs: Array<HubBreadcrumbItem>
  activeApplication: HubApplication
  onNavigate: (route: HubApplicationRoute) => void
}

export const HubNavigationBar: React.FC<HubNavigationBarProps> = ({
  applicationBreadcrumbs,
  activeApplication,
  onNavigate,
}) => {
  const { primaryColor } = usePrimaryColor()

  return (
    <Flex
      h="24px"
      w="full"
      bgColor="black"
      borderBottomColor={primaryColor}
      borderBottomWidth="1px"
    >
      <Menu>
        {() => (
          <>
            <MenuButton as={HubNavigationBarItem} isFirst canInteract>
              <Flex alignItems="center">
                <Box mr={2}>{React.createElement(activeApplication.icon)}</Box>
                <Box mt="-1px">{activeApplication.name}</Box>
              </Flex>
            </MenuButton>
            <MenuList
              zIndex={500}
              backgroundColor="black"
              borderRadius={0}
              borderColor={primaryColor}
              py={0}
            >
              {NAVIGATION_APPLICATIONS.map((app) => {
                return (
                  <MenuItem
                    key={app.route}
                    icon={React.createElement(app.icon)}
                    fontSize="12px"
                    size="sm"
                    onClick={() => {
                      onNavigate(app.route)
                    }}
                  >
                    {app.name}
                  </MenuItem>
                )
              })}
            </MenuList>
          </>
        )}
      </Menu>

      {applicationBreadcrumbs.map((bc) => {
        return (
          <HubNavigationBarItem key={bc.name}>
            <Flex alignItems="center" mt="-1px">
              {bc.name}
            </Flex>
          </HubNavigationBarItem>
        )
      })}
    </Flex>
  )
}
