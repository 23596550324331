import { alphaColor, usePrimaryColor } from '@chain-runners/ui'
import { formatTraitTypeLong } from '@chain-runners/utils'
import { Box, Flex } from '@chakra-ui/react'
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { IoCloseSharp } from 'react-icons/all'
import { TraitFilters } from './applications/TraitExplorer'
import { FilterItem } from './types'

export type TraitActiveFilterItemsProps = {
  filters: TraitFilters
  setFilters: Dispatch<SetStateAction<TraitFilters>>
}

export const TraitActiveFilterItems: React.FC<TraitActiveFilterItemsProps> = ({
  filters,
  setFilters,
}) => {
  const { primaryColor } = usePrimaryColor()
  const filterItems: Array<FilterItem> = useMemo(() => {
    return (
      filters.traitTypes?.map(
        (t): FilterItem => ({
          type: 'TraitType',
          label: formatTraitTypeLong(t),
          value: t,
        }),
      ) ?? []
    )
  }, [filters])

  const handleRemoveFilterItem = useCallback(
    (item: FilterItem) => {
      setFilters((filters) => {
        return {
          ...filters,
          traitTypes: filters?.traitTypes?.filter((t) => t !== item.value),
        }
      })
    },
    [setFilters],
  )

  const handleClearFilters = useCallback(() => {
    setFilters({})
  }, [setFilters])

  return (
    <Flex flexWrap="wrap" alignItems="center" paddingBottom={filterItems.length ? 2 : 0}>
      {filterItems.map((item) => {
        return (
          <Flex
            key={item.value}
            mb={2}
            px={2}
            py={1}
            bgColor="black"
            borderColor={primaryColor}
            borderWidth="1px"
            color={primaryColor}
            alignItems="center"
            cursor="pointer"
            userSelect="none"
            fontSize="14px"
            mr={2}
            _hover={{
              bgColor: alphaColor(primaryColor, 0.1),
            }}
            _active={{
              bgColor: alphaColor(primaryColor, 0.15),
            }}
            onClick={() => {
              handleRemoveFilterItem(item)
            }}
          >
            {item.label}
            <Box as="span" ml={2}>
              <IoCloseSharp />
            </Box>
          </Flex>
        )
      })}
      {filterItems.length > 0 && (
        <Box
          mb={2}
          _hover={{ textDecoration: 'underline' }}
          cursor="pointer"
          userSelect="none"
          px={1}
          fontSize="12px"
          onClick={handleClearFilters}
        >
          Clear All
        </Box>
      )}
    </Flex>
  )
}
