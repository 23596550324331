import {
  alphaColor,
  AnimatedHackerGrid,
  AnimatedHackerGridItem,
  HackerBox,
  usePrimaryColor,
} from '@chain-runners/ui'
import { AspectRatio, Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { HOME_APPLICATIONS } from '../config'
import { BaseHubApplicationProps } from '../types'

export type HubHomeProps = BaseHubApplicationProps & {}

export const HubHome: React.FC<HubHomeProps> = ({ handleNavigate }) => {
  const { primaryColor } = usePrimaryColor()
  return (
    <Box p={4} w="full">
      <Box pb={1} mb={4} w="full" borderColor={primaryColor} borderBottomWidth="1px">
        APPLICATIONS
      </Box>

      <AnimatedHackerGrid>
        {HOME_APPLICATIONS.map((app) => {
          return (
            <AnimatedHackerGridItem
              key={app.route}
              onClick={() => {
                handleNavigate(app.route)
              }}
            >
              <AspectRatio>
                <HackerBox
                  _hover={{
                    bgColor: alphaColor(primaryColor, 0.1),
                  }}
                  innerContainerProps={{
                    display: 'flex',
                    flexDir: 'column',
                  }}
                >
                  <Flex
                    flex={1}
                    justifyContent="center"
                    alignItems="center"
                    mb={3}
                    fontSize="48px"
                  >
                    {React.createElement(app.icon, { size: '55%' })}
                  </Flex>
                  <Box textAlign="center" fontWeight="semibold">
                    {app.name}
                  </Box>
                </HackerBox>
              </AspectRatio>
            </AnimatedHackerGridItem>
          )
        })}
      </AnimatedHackerGrid>
    </Box>
  )
}
