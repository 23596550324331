export type TokenMetadata = {
  name: string
  description: string
  image_data: string
  attributes: Array<{ trait_type: string; value: string }>
}

export function parseImage(imageStr: string): string {
  const svgHeader = 'data:image/svg+xml;base64,'
  if (imageStr.startsWith(svgHeader)) {
    const imageContent = imageStr.substr(svgHeader.length)
    return Buffer.from(imageContent, 'base64').toString()
  }
  return imageStr
}

export function parseMetadata(metadataStr: string, decodeImage = false): TokenMetadata {
  const metadataHeader = 'data:application/json;base64,'
  if (!metadataStr.startsWith(metadataHeader)) return JSON.parse(metadataStr)

  const metadataBase64 = metadataStr.substr(metadataHeader.length)
  const decodedMetadata = Buffer.from(metadataBase64, 'base64').toString()
  const metadata: TokenMetadata = JSON.parse(decodedMetadata)
  return {
    ...metadata,
    image_data: decodeImage
      ? metadata?.image_data && parseImage(metadata.image_data)
      : metadata.image_data,
  }
}

export function base64EncodeImageContent(content: string): string {
  return `data:image/svg+xml;base64,${Buffer.from(content).toString('base64')}`
}
