import { RunnerTrait } from '@chain-runners/types'
import {
  AnimatedHackerGrid,
  AnimatedHackerGridItem,
  HackerBox,
  HackerModal,
  HackerModalSection,
  HackerTooltip,
  NotchedCornerPosition,
  useIsMobile,
} from '@chain-runners/ui'
import { COLLAPSIBLE_SIDEBAR_CLOSED_WIDTH } from '@chain-runners/ui/dist/components/Sidebar'
import { Box, Flex, Spinner } from '@chakra-ui/react'
import '@fontsource/source-serif-pro/400.css'
import { orderBy } from 'lodash-es'
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation } from 'react-router'
import { TraitType } from 'src/clients/api/generated'
import { TraitPreviewDisplay } from '../../runners/TraitPreviewDisplay'
import { TraitDetailsDisplay } from '../components/TraitDetailsDisplay'
import { TraitActiveFilterItems } from '../TraitActiveFilterItems'
import { TraitFiltersSidebar } from '../TraitFiltersSidebar'
import { BaseHubApplicationProps } from '../types'

export type TraitFilters = {
  traitTypes?: Array<TraitType> | null
}

export enum TraitQueryParamsKey {
  TraitTypes = 'traitTypes',
}

export function loadFiltersFromQueryParams(search: string): TraitFilters {
  const params = new URLSearchParams(search)
  const traitTypes =
    (params.getAll(TraitQueryParamsKey.TraitTypes) as Array<TraitType>) ?? undefined
  return { traitTypes }
}

export function buildQueryParamsFromTraitFilters(filters: TraitFilters): URLSearchParams {
  const params = new URLSearchParams()
  filters.traitTypes?.forEach((traitType) => {
    params.append(TraitQueryParamsKey.TraitTypes, traitType)
  })
  return params
}

export type TraitExplorerProps = BaseHubApplicationProps & {}

export const TraitExplorer: React.FC<TraitExplorerProps> = (props) => {
  const isMobile = useIsMobile()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const {
    application: { route },
    traits,
    traitsById,
    traitsByType,
    handleNavigate,
  } = props

  const location = useLocation()

  const [isFiltersOpen, setIsFiltersOpen] = useState(!isMobile)
  const [visibleTraits, setVisibleTraits] = useState<Array<RunnerTrait> | null>(null)
  const popupIsShown = useRef(false)

  const lastFiltersRef = useRef<TraitFilters>({})
  const [selectedTraitId, filters]: [number | null, TraitFilters] = useMemo(() => {
    const traitId = parseInt(location.pathname.replace(`/hub${route}/`, ''))
    const parsedTraitId = Number.isNaN(traitId) ? null : traitId ?? null

    const newFilters = loadFiltersFromQueryParams(location.search)
    if (newFilters.traitTypes?.join('') !== lastFiltersRef.current.traitTypes?.join('')) {
      setTimeout(() => setVisibleTraits(null), 0)
    }
    lastFiltersRef.current = newFilters
    return [parsedTraitId, newFilters]
  }, [location, route])

  const allTraits = useMemo(() => {
    return orderBy(
      traits.filter((t) => t.tokenCount > 0),
      ['type', 'name'],
    )
  }, [traits])

  const filterCount = filters?.traitTypes?.length ?? 0
  const filteredTraits = useMemo(() => {
    return filterCount
      ? allTraits.filter((t) => filters.traitTypes?.includes(t.type))
      : allTraits
  }, [allTraits, filters, filterCount])
  const visibleTraitCount = visibleTraits?.length ?? 0

  const loadTraits = useCallback(
    async (offset?: number) => {
      const offsetNumber = offset ?? 0
      const newTraits = filteredTraits.slice(offsetNumber, offsetNumber + 25)
      if (offsetNumber) {
        setVisibleTraits((vt) => [...(vt ?? []), ...newTraits])
      } else {
        setVisibleTraits(newTraits)
      }
    },
    [filteredTraits],
  )

  const handleSelectedTrait = useCallback(
    (traitId: number | null) => {
      const queryParams = buildQueryParamsFromTraitFilters(filters)
      if (traitId === null) {
        handleNavigate(route, { queryParams })
        return
      }
      handleNavigate(route, { extraRouteSegments: `/${traitId}`, queryParams })
    },
    [handleNavigate, route, filters],
  )

  const handleSetFilters = useCallback(
    (setStateAction: SetStateAction<TraitFilters>) => {
      setVisibleTraits(null)
      const newFilters =
        typeof setStateAction === 'function' ? setStateAction(filters) : setStateAction
      const queryParams = buildQueryParamsFromTraitFilters(newFilters)
      handleNavigate(route, { queryParams })
    },
    [route, filters, handleNavigate],
  )

  const handleCloseDetails = useCallback(() => {
    handleSelectedTrait(null)
  }, [handleSelectedTrait])

  const selectedTrait: RunnerTrait | undefined = useMemo(() => {
    if (selectedTraitId === null) return

    return traitsById[selectedTraitId]
  }, [selectedTraitId, traitsById])

  const handleScroll = useCallback(async () => {
    if (visibleTraitCount >= allTraits.length) return

    const container = containerRef.current
    if (!container) return

    const isAtBottom =
      container.scrollTop >= (container.scrollHeight - container.offsetHeight) * 0.8

    if (isAtBottom) {
      await loadTraits(visibleTraitCount)
    }
  }, [loadTraits, visibleTraitCount, allTraits])

  useLayoutEffect(() => {
    const container = containerRef.current
    if (!container) return

    container.addEventListener('scroll', handleScroll)
    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    if (visibleTraits === null) {
      loadTraits()
    }
  }, [filters, loadTraits, visibleTraits])

  return (
    <Flex position="relative" width="full" height="full">
      <TraitFiltersSidebar
        filters={filters}
        setFilters={handleSetFilters}
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        traitsByType={traitsByType}
      />
      <Flex
        flex={1}
        direction="column"
        height="full"
        width="full"
        overflowY="auto"
        ref={containerRef}
        ml={isMobile ? COLLAPSIBLE_SIDEBAR_CLOSED_WIDTH : 0}
        padding={4}
      >
        <TraitActiveFilterItems filters={filters} setFilters={handleSetFilters} />
        <Box mb={4} fontSize="12px">
          {filteredTraits.length} Traits
        </Box>
        <Flex justifyContent="center" direction="column" flex={1}>
          {!visibleTraits ? (
            <Box textAlign="center">
              <Spinner size="xl" />
            </Box>
          ) : (
            <>
              <AnimatedHackerGrid isSidebarOpen={isFiltersOpen}>
                {visibleTraits.map((trait) => {
                  return (
                    <AnimatedHackerGridItem
                      key={trait.id}
                      onClick={() => {
                        handleSelectedTrait(trait.id)
                      }}
                    >
                      <HackerBox
                        notchedCorner={NotchedCornerPosition.BottomRight}
                        notchedCornerSize={32}
                      >
                        <TraitPreviewDisplay trait={trait} />

                        <Box
                          mt={2}
                          fontSize="13px"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          overflowY="hidden"
                          fontWeight="semibold"
                        >
                          {trait.displayName}
                        </Box>
                      </HackerBox>
                    </AnimatedHackerGridItem>
                  )
                })}
              </AnimatedHackerGrid>
              <Box flex={1} />
            </>
          )}
          <HackerModal
            onExitComplete={() => {
              popupIsShown.current = false
            }}
            show={selectedTraitId !== null}
            onClose={handleCloseDetails}
            containerProps={{
              maxW: '450px',
            }}
          >
            {selectedTrait && (
              <HackerModalSection
                title={selectedTrait.displayName}
                extraTitleContent={
                  <HackerTooltip
                    label={`${selectedTrait.tokenCount} Runners have this Trait`}
                    openDelay={300}
                  >
                    <Box color="white">{selectedTrait.tokenCount}</Box>
                  </HackerTooltip>
                }
                containerProps={{ width: 'full' }}
              >
                <TraitDetailsDisplay
                  trait={selectedTrait}
                  handleNavigate={handleNavigate}
                />
              </HackerModalSection>
            )}
          </HackerModal>
        </Flex>
      </Flex>
    </Flex>
  )
}
